import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ComponentBase } from '../../../../../../_common/components/_component.base';

// model imports
import { LocPrefix } from 'company-finder-common';
import {
  PrimarySectorCount,
  PrimarySubSectorCount,
} from '../../../../../../_common/utilities/summary/summary';
import { DeploymentContext } from '../../../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'sector-details',
  templateUrl: './sector-details.component.html',
  styleUrls: ['./sector-details.component.scss'],
})
export class SectorDetailsComponent extends ComponentBase implements OnChanges {
  // public properties
  @Input()
  public primarySectorCount: PrimarySectorCount;
  @Input()
  public barPercentage: number;
  public isOpen = false;
  public sortedPrimarySubSectorCounts: PrimarySubSectorCount[];
  public color: string;
  public sectorDisplayName: string;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  // public getters
  public get sector(): string {
    return this.primarySectorCount.primarySector;
  }

  // public methods
  public barStyle(): Partial<CSSStyleDeclaration> {
    return {
      backgroundColor: this.color,
      width: `${this.barPercentage}%`,
    };
  }

  public calculateSubSectorBarPercentage(
    primarySubSectorCount: PrimarySubSectorCount
  ): number {
    // Cap it at 100, just in case.
    return Math.min(
      (100 * primarySubSectorCount.count) / this.primarySectorCount.count,
      100
    );
  }

  public subSectorBarStyle(
    primarySubSectorCount: PrimarySubSectorCount
  ): Partial<CSSStyleDeclaration> {
    return {
      backgroundColor: this.color,
      width: `${this.calculateSubSectorBarPercentage(primarySubSectorCount)}%`,
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.primarySectorCount && this.primarySectorCount) {
      this.sortedPrimarySubSectorCounts =
        this.primarySectorCount.descendingPrimarySubSectorCounts;
      this.sectorDisplayName = this.LocWithPrefix(
        this.sector,
        LocPrefix.SectorShortName
      );
      const sectorIndex = this._deploymentContext.getSectorIndex(this.sector);
      this.color = this.themeSettings.sectorColors[sectorIndex];
    }
  }
}
