import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

// Component imports
import { ApproveDeclineComponent } from '../approve-decline/approve-decline.component';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import { ConferenceModalComponent } from '../conference-modal/conference-modal.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';

// vendor imports
import _ from 'lodash';

// model imports
import {
  Conference,
  Deal,
  Funding,
  ICompanyUpdate,
  IConferenceUpdate,
  LocalizedTextIds,
  TechnologyReadinessLevel,
} from 'company-finder-common';

// service/utility imports
import { ReviewEditsService } from '../../services/review-edits.service';
import {
  MilestoneLayoutInfo,
  MilestoneLayoutTemplate,
  MilestoneType,
  MilestoneValue,
  milestoneSuffixKeys,
} from '../../../../_common/utilities/blue-knight/blue-knight.util';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'blue-knight-information',
  templateUrl: './blue-knight-information.component.html',
  styleUrls: ['./blue-knight-information.component.scss'],
})
export class BlueKnightInformationComponent
  extends UpdateComponentBaseWithEditItems
  implements OnDestroy, OnInit
{
  // public properties
  public showConferenceModal = false;

  // I'd really rather have something cleaner than passing in the component,
  // but I also don't want this current refactor to grow bigger than it
  // already needs to be. I'm adding a backlog story to revisit this, and I'll
  // add the link to it here once it exists (not doing it now in case I change my approach)
  @Input()
  public notificationsComponent: NotificationsComponent;

  @ViewChildren(ApproveDeclineComponent)
  public approveDeclineComponents: QueryList<ApproveDeclineComponent>;
  @ViewChild('conferenceModalComponent', { static: true })
  public conferenceModalComponent: ConferenceModalComponent;

  protected milestoneLayoutInfoArray: MilestoneLayoutTemplate[] = [
    {
      localizedTextId:
        LocalizedTextIds.BlueKnightInformationFutureEmployeeNeeds,
      propertyRoot: 'NumEmpFutureNeeds',
      type: MilestoneType.EmployeeNeeds,
    },
    {
      localizedTextId:
        LocalizedTextIds.BlueKnightInformationTechnologyReadinessLevel,
      propertyRoot: 'Trl',
      type: MilestoneType.TRL,
    },
  ];
  public milestoneLayoutInfos;

  // public getters
  public get trlLayoutInfo(): MilestoneLayoutInfo {
    return this.milestoneLayoutInfos[1];
  }

  public get employeeLayoutInfo(): MilestoneLayoutInfo {
    return this.milestoneLayoutInfos[0];
  }

  public get conferenceToModify(): Conference {
    return this._companyUpdateService.conferenceToModify;
  }

  public set conferenceToModify(value: Conference) {
    this._companyUpdateService.conferenceToModify = value;
  }

  public get trlList(): TechnologyReadinessLevel[] {
    return this._deploymentContext.referenceValueData.technologyReadinessLevelList.map(
      (item) => item
    );
  }

  public conferenceTrackBy(_index: number, conference: Conference): string {
    return conference.conferenceId;
  }

  // Local getters to prevent having lots of code like
  // this._companyUpdateService.getCurrentUpdate().conferenceUpdates
  // which get a bit unweildy to read
  private get currentUpdate(): ICompanyUpdate {
    return this._companyUpdateService.getCurrentUpdate();
  }

  private get conferenceUpdates(): IConferenceUpdate[] {
    return this.currentUpdate.conferenceUpdates;
  }

  public constructor(
    dc: DeploymentContext,
    private _reviewEditsService: ReviewEditsService,
    _companyService: CompanyService,
    _companyUpdateService: CompanyUpdateService
  ) {
    super(dc, _companyUpdateService, _companyService);
    this.milestoneLayoutInfos =
      this._deploymentContext.getMilestoneLayoutInfoArrayCopy(
        this.milestoneLayoutInfoArray
      );
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this.initializeMilestoneLayoutInfo();
    this.setAllSubscriptions();
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
    }
  }

  private addInitialMilestoneField(
    milestoneLayoutInfo: MilestoneLayoutInfo,
    labelKey: string
  ): void {
    const propName = this.getMilestonePropertyName(
      milestoneLayoutInfo.propertyRoot
    );
    if (
      this.displayMilestone(
        MilestoneValue.Initial,
        propName,
        this.pendingUpdate
      )
    ) {
      milestoneLayoutInfo.properties.push({
        milestoneValue: MilestoneValue.Initial,
        label: this.Loc(labelKey),
        value: this.company[propName]?.toString(),
        propertyRoot: milestoneLayoutInfo.propertyRoot,
      });
    }
  }

  public initializeMilestoneLayoutInfo(): void {
    // This is one other place where the Trl and Employees set need to be augmented
    this.addInitialMilestoneField(
      this.trlLayoutInfo,
      'BlueKnightInformationTrlAtAcceptance'
    );
    this.addInitialMilestoneField(
      this.employeeLayoutInfo,
      'BlueKnightInformationEmployeesAtAcceptance'
    );

    this.milestoneLayoutInfos.forEach((milestoneLayoutInfo) => {
      // It says suffix but in the data lake Employee Needs and TRL,
      // which are the ones on this page, use a prefix.
      milestoneSuffixKeys.forEach((prefixKey, index) => {
        const prefix = this.Loc(prefixKey);
        const propName = this.getMilestonePropertyName(
          milestoneLayoutInfo.propertyRoot,
          prefix
        );
        const milestoneValue = MilestoneValue[MilestoneValue[index * 12 + 12]];
        if (
          this.displayMilestone(milestoneValue, propName, this.pendingUpdate)
        ) {
          milestoneLayoutInfo.properties.push({
            milestoneValue: milestoneValue,
            label: prefix,
            value: this.company[propName],
            propertyRoot: milestoneLayoutInfo.propertyRoot,
          });
        }
      });
    });
  }

  public isAdded(conference: Conference): boolean {
    return (
      this._companyUpdateService.isNewConferenceDealOrFunding(
        conference,
        'conferenceId'
      ) &&
      (!this.isCurrentUpdateApproved() ||
        !this.isConferenceFromPriorUpdate(conference))
    );
  }

  public isApproved(conference: Conference): boolean {
    return (
      this._companyUpdateService.isNewConferenceDealOrFunding(
        conference,
        'conferenceId'
      ) &&
      this.isCurrentUpdateApproved() &&
      this.isConferenceFromPriorUpdate(conference)
    );
  }

  public isCurrentUpdateApproved(): boolean {
    return this._companyUpdateService.isCurrentUpdateApproved(
      this.notificationsComponent
    );
  }

  public isConferenceFromPriorUpdate(obj: Conference): boolean {
    return !!_.find(
      this.conferenceUpdates,
      (update) => (update.id as unknown) === obj.conferenceId
    );
  }

  public isNewConferenceDealOrFunding(
    obj: Conference | Deal | Funding,
    typeId: string
  ): boolean {
    return this._companyUpdateService.isNewConferenceDealOrFunding(obj, typeId);
  }

  public openConference(
    conference?: Conference,
    isAttendedConference?: boolean
  ): void {
    if (conference) {
      this.conferenceToModify = conference;
    } else {
      this.conferenceToModify = new Conference();
      this.conferenceToModify.isAttendedConference = isAttendedConference;
    }
    this.showConferenceModal = true;
  }

  public setAllSubscriptions(): void {
    this.setReviewEditsSubscriptions();
    this.setConferenceSubscriptions();
  }

  public setReviewEditsSubscriptions(): void {
    if (!this._reviewEditsService) {
      this._logger.info(`No _reviewEditsService`);
      return;
    }

    this.addSubscription(
      this._reviewEditsService.showReviewModalSubject.subscribe(
        (item: Conference) => {
          this.showConferenceFromReview(item);
        }
      )
    );
  }

  public setConferenceSubscriptions(): void {
    if (!this.conferenceModalComponent) {
      this._logger.info(`No conferenceModalComponent`);
      return;
    }

    this.addSubscription(
      this.conferenceModalComponent.closingSubject.subscribe(
        async (result: { conference: Conference; isSave: boolean }) => {
          await this.closeConference(result);
        }
      )
    );
    this.addSubscription(
      this.conferenceModalComponent.approveDeclineSubject.subscribe(
        (result: { conference: Conference; isApproved: boolean }) => {
          this.showConferenceModal = false;

          const approveDeclineComponent = this.approveDeclineComponents.find(
            (comp) =>
              comp.conference &&
              comp.conference.conferenceId === result.conference.conferenceId
          );
          if (approveDeclineComponent) {
            if (result.isApproved) {
              approveDeclineComponent.approveConference(result.conference);
            } else {
              approveDeclineComponent.declineConference(result.conference);
            }
          }
        }
      )
    );
  }

  // private methods
  private async closeConference(result: {
    conference: Conference;
    isSave: boolean;
  }): Promise<void> {
    this.showConferenceModal = false;
    const conferencesListName = result.conference.isAttendedConference
      ? 'conferencesAttended'
      : 'anticipatedConferences';
    // We're not saving, so undo any changes to the deals
    if (!result.isSave && result.conference.conferenceId) {
      const originalConference = this.companyWithMostRecentEdit[
        conferencesListName
      ].find((c) => c.conferenceId === result.conference.conferenceId);
      // Existing conferences would be in the companyClone, new ones in the user's current session would not.
      const conferenceToRestore = originalConference
        ? _.cloneDeep(originalConference)
        : result.conference;
      const index = this.company[conferencesListName].findIndex(
        (c) => c.conferenceId === result.conference.conferenceId
      );
      this.company[conferencesListName].splice(index, 1, conferenceToRestore);
    }

    if (!result.isSave) {
      return;
    }

    if (
      this._companyUpdateService.isNewConferenceDealOrFunding(
        result.conference,
        'conferenceId'
      )
    ) {
      const index = this.company[conferencesListName].findIndex(
        (c) => c.conferenceId === result.conference.conferenceId
      );
      if (!result.conference.isDeleted) {
        // Don't double-save
        if (index < 0) {
          this.company[conferencesListName].push(result.conference);
        }
      } else {
        // They deleted a conference they just tried to add, so remove it from the company.
        this.company[conferencesListName].splice(index, 1);
        if (this.pendingUpdate) {
          // Clean up the DB
          const updateType = 'conference';
          const deleteCompanyUpdate =
            this._companyUpdateService.wouldBeEmptyCompanyUpdate(
              this.pendingUpdate
            );
          await this._companyService.deletePendingAddedDealOrFundingUpdateByModelId(
            this.company.opportunityIdPrimary,
            updateType,
            result.conference.conferenceId,
            deleteCompanyUpdate
          );
          // Apply the pending update, assuming there is one.
          // If there isn't, i.e., deleteCompanyUpdate is true, applySavedOrPendingUpdateData()
          // does little more than retrieve a null company update, thus setting pendingUpdate to to null.
          // NOTE: Don't call initializeUpdateData() here because:
          // * We already have any approved but unprocessed update data, and nothing we've done in the modal impacts that stuff.
          // * We want the pending update applied to the company, but don't want to clone the company into the
          //   Pending cache or we'll lose track of what's different.
          await this._companyUpdateService.applySavedOrPendingUpdateData();
          // Make sure we remove the deleted conference from the caches
          _.remove(
            this.companyClone[conferencesListName],
            (c) => c.conferenceId === result.conference.conferenceId
          );
          _.remove(
            this.companyWithPending[conferencesListName],
            (c) => c.conferenceId === result.conference.conferenceId
          );
        }
        // The DB is clean, so now make sure the companyWithMostRecentEdit reflects the current state of the company.
        this.companyWithMostRecentEdit =
          this._companyUpdateService.cloneCompany(this.company);
        await this.notificationsComponent.getNotification();
      }
    }

    _.merge(
      this.companyWithMostRecentEdit[conferencesListName],
      this.company[conferencesListName]
    );
    this._companyUpdateService.checkUpdatesForUI(this.currentUpdate);

    if (result.conference.isAttendedConference) {
      this.haveConferencesAttendedChanged = this.conferenceUpdates?.length > 0;
    } else {
      this.haveAnticipatedConferencesChanged =
        this.conferenceUpdates?.length > 0;
    }
  }

  private showConferenceFromReview(conference: Conference): void {
    this.openConference(conference);
  }
}
