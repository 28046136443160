import { Component, Input, OnInit } from '@angular/core';
import map from 'lodash/map';
import _ from 'lodash';
import {
  DisplayItem,
  IUserSearch,
  Location,
  StatusDisplayItem,
  LocationWithCountAndCanonicalString,
  SectorDisplayItem,
  SectorWithCountAndCanonicalString,
  StatusWithCount,
  Company,
} from 'company-finder-common';
import { SearchService } from '../../../../_common/services/search/search.service';
import { getCountOfCompaniesForSavedSearchUtil } from '../../../../_common/utilities/preferences/preferences.util';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { AllSectorsWithCounts } from '../../../../_common/utilities/sector/sector-utils';

export interface SectorWithCountAndCanonicalStringAndIds
  extends SectorWithCountAndCanonicalString {
  sectorId: string;
  parentSectorId?: string;
  displayName: string;
}

@Component({
  template: '',
})
export class SavedSearchBaseComponent extends ComponentBase implements OnInit {
  @Input() search: IUserSearch;
  public matchedCompaniesCount: number;

  public allStatusesWithCounts: StatusWithCount[] = [];

  protected allSectorsWithCounts: SectorWithCountAndCanonicalString[] = [];
  protected allSectorsWithCountsAndIds: SectorWithCountAndCanonicalStringAndIds[] =
    [];
  protected allLocationsWithCounts: LocationWithCountAndCanonicalString[] = [];
  protected allStatuses: StatusDisplayItem[];

  private _allLocations: Location[] = [];

  constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    protected _companyService: CompanyService
  ) {
    super(dc);
  }

  public ngOnInit(): void {
    this._searchService.getComprehensiveSummary().then((summary) => {
      this.allSectorsWithCounts = AllSectorsWithCounts(summary);
      this.allSectorsWithCountsAndIds = this.allSectorsWithCounts.map((x) => {
        const sectorWithCountAndCanonicalStringAndIds = _.merge(
          {
            sectorId: x.sector,
            parentSectorId: x.parentSector,
            displayName: x.sector,
          },
          x
        ) as SectorWithCountAndCanonicalStringAndIds;
        return sectorWithCountAndCanonicalStringAndIds;
      });
      this._allLocations = summary.locations;
      this.allLocationsWithCounts = _.sortBy(
        summary.locations.map((location) => ({
          location: location,
          count: summary.companiesByLocation(location.name).length,
          canonicalString: location.name.toLowerCase(),
        })),
        (locationEntry) => locationEntry.location.name
      );

      this._deploymentContext.statusMetadata.forEach((metadata) => {
        metadata.values.forEach((value) => {
          this.allStatusesWithCounts.push({
            statusItem: {
              status: value.value,
              displayName: value.display,
            },
            count: this.getCompaniesByStatus(value.value, summary.companies)
              .length,
          });
        });
      });

      this.getCountOfCompaniesForSavedSearch();
    });
  }

  public getCompaniesByStatus(
    searchStatus: string,
    companies: Company[]
  ): Company[] {
    return this._deploymentContext.getCompaniesWithStatus(
      searchStatus,
      companies
    );
  }

  public get summary(): DisplayItem[][] {
    return [
      this.locationSummary.map((loc) => ({ displayName: loc.name, ...loc })),
      this.sectorSummary,
      this.statusSummary,
    ];
  }

  public get locationSummary(): Location[] {
    if (this.search?.locations) {
      // Get location display name
      return this._allLocations.filter(({ name: location }) =>
        this.search.locations.some(
          (userLocation) => location === userLocation.locationId
        )
      );
    }
    return [];
  }

  public set locationSummary(locationDisplayItems: Location[]) {
    this.search.locations = map(locationDisplayItems, ({ name: location }) => ({
      locationId: location,
    }));
  }

  public get sectorSummary(): SectorDisplayItem[] {
    if (this.search?.sectors?.length > 0) {
      return map(this.search.sectors, (sector) => {
        const sectorItem = {
          sectorId: sector.sectorId,
          parentSectorId: sector.parentSectorId,
          displayName: sector.sectorId,
          // Somewhat redundant properties to bridge an impedance gap in how sectors are selected.
          // The most significant gap is that the newer visual designs require sector.subSectors
          // be populated, and the DisplayItem model used by the dropdown menus doesn't include them at all.
          // See also: ADJQ-1141 & SavedSearchSectorSelectorComponent.deselectItem()
          parentSector: null,
          sector: sector.sectorId,
          subSectors: null,
          canonicalString: sector.sectorId.toLowerCase(),
        };
        // Populate the "extra data"
        const extraInfo = this.allSectorsWithCountsAndIds.find(
          (s) => s.sectorId === sector.sectorId
        );
        if (extraInfo) {
          sectorItem.subSectors = extraInfo.subSectors;
          sectorItem.parentSector = extraInfo.parentSector;
        }
        return sectorItem;
      });
    }
    return [];
  }

  public set sectorSummary(sectorDisplayItems: SectorDisplayItem[]) {
    this.search.sectors = sectorDisplayItems;
  }

  public get statusSummary(): StatusDisplayItem[] {
    if (!(this.search?.statuses?.trim().length > 0)) {
      return [];
    }

    return this.search.statuses.split(',').map((status) => ({
      status: status,
      displayName: this._deploymentContext.getStatusDisplayName(status),
    }));
  }

  public set statusSummary(statusDisplayItems: StatusDisplayItem[]) {
    this.search.statuses = statusDisplayItems
      .map((status) => status.status)
      .toString();
  }

  public async getCountOfCompaniesForSavedSearch(): Promise<void> {
    if (this.search) {
      this.matchedCompaniesCount = await getCountOfCompaniesForSavedSearchUtil(
        this.search,
        this._companyService
      );
    }
  }
}
