import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';

import { ComponentBase } from '../../_common/components/_component.base';

// model imports
import {
  Filter,
  Location,
  Sector,
  StatusDisplayItem,
  determineSectorsAndSubsectors,
  SectorDisplayItem,
  LocalizedTextIds,
} from 'company-finder-common';
import { Summary } from '../../_common/utilities/summary/summary';

// utility/service imports
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { SearchService } from '../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';
import { MenuOptionService } from '../../_common/services/menu-option/menu-option.service';
import {
  GroupedMenuOptions,
  HierarchicalMenuOption,
  MenuOption,
} from '../../_common/services/menu-option/menu-option.interface';
import { AuthnService } from '../../_common/services/authn/authn.service';
import { BreadcrumbsService } from '../../_common/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [MenuOptionService],
})
export class FilterComponent
  extends ComponentBase
  implements OnDestroy, OnInit
{
  // public properties
  public title = this.Loc(LocalizedTextIds.FilterFilters);
  @Input()
  public isSearchResultsScreen = false;
  public summary: Summary;
  public filter: Filter;

  // private properties

  /** An explicit list of the "hidden" filter constraints (ADJQ-199).
   * Note that this is dynamically augmented with 0 or more potential tag values.
   */
  private additionalFilterConstraints: AdditionalFilterConstraint[] = [];

  public constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    private _router: Router,
    private _webAnalyticsService: WebAnalyticsService,
    private _menuOptionService: MenuOptionService,
    private _authnService: AuthnService,
    private _breadcrumbsService: BreadcrumbsService
  ) {
    super(dc);

    if (this.featureSwitches.enableSectors) {
      this.additionalFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => this.Loc(LocalizedTextIds.FilterCrossSector),
          () => this.filter.isCrossSector,
          () => (this.filter.isCrossSector = false)
        )
      );
    }
    this.additionalFilterConstraints.push(
      new AdditionalFilterConstraint(
        () => this.Loc(LocalizedTextIds.FilterLastDays),
        () => this.filter.isNewInLast90Days,
        () => (this.filter.isNewInLast90Days = false)
      )
    );
    this.additionalFilterConstraints.push(
      new AdditionalFilterConstraint(
        () => this.Loc(LocalizedTextIds.FilterLastQuarter),
        () => this.filter.isNewInLastQuarter,
        () => (this.filter.isNewInLastQuarter = false)
      )
    );
    if (this.featureSwitches.enableBlueKnight) {
      this.additionalFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => this.Loc(LocalizedTextIds.BlueKnight),
          () => this.filter.isBlueKnight,
          () => (this.filter.isBlueKnight = false)
        )
      );
    }
    if (this.featureSwitches.enableQfc) {
      this.additionalFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => this.Loc(LocalizedTextIds.QFCAwardee),
          () => this.filter.isQFCWinner,
          () => (this.filter.isQFCWinner = false)
        )
      );
    }
    this.additionalFilterConstraints.push(
      new AdditionalFilterConstraint(
        () => this.filter.currentRdStage,
        () => !!this.filter.currentRdStage,
        () => (this.filter.currentRdStage = undefined)
      )
    );
  }

  // public getters
  public get followedCompaniesOnly(): boolean {
    return this.filter && this.filter.isFollowedCompaniesOnly;
  }

  public set followedCompaniesOnly(value: boolean) {
    this.filter.isFollowedCompaniesOnly = value;
    this._searchService.filterSubject.next(this.filter);
    this.trackForAnalytics('isFollowedCompaniesOnly', value);
  }

  public get enableNavigateToSearch(): boolean {
    return this._deploymentContext.rawConfig.behavior
      .drillDownNavigatesToSearchResults;
  }

  public get hasSearchPredicate(): boolean {
    return !!this._searchService.currentSearchPredicate;
  }

  public get showClearFilterAction(): boolean {
    return this.filter && !this.filter.isShowAll();
  }

  public get isFollowEnabled(): boolean {
    return this._deploymentContext.featureSwitches.enableFollow;
  }

  public get isInternalView(): boolean {
    return this._authnService.isInternal;
  }

  public get locationOptions(): MenuOption<Location>[] {
    return this._menuOptionService.locationOptions;
  }

  public get primarySectorOptions(): HierarchicalMenuOption<SectorDisplayItem>[] {
    return this._menuOptionService.primarySectorSubSectorOptions;
  }

  public get secondarySectorOptions(): HierarchicalMenuOption<SectorDisplayItem>[] {
    return this._menuOptionService.secondarySectorSubSectorOptions;
  }

  public get statusOptions(): GroupedMenuOptions<StatusDisplayItem>[] {
    return this._menuOptionService.statusOptions;
  }

  public get hasAdditionalFilterConstraints(): boolean {
    return this.activeAdditionalFilterConstraints.length > 0;
  }

  public get optionsForSingleRow(): MenuOption<StatusDisplayItem>[] {
    return this.statusOptions[0]?.options ?? [];
  }

  /** This will augment the base set of AdditionalFilterConstraints with any selected tag values */
  public get activeAdditionalFilterConstraints(): AdditionalFilterConstraint[] {
    if (!this.filter) {
      return [];
    }

    const tagFilterConstraints = new Array<AdditionalFilterConstraint>();
    this.filter.tags?.forEach((tagValue) => {
      tagFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => tagValue,
          () => true,
          () =>
            (this.filter.tags = this.filter.tags.filter(
              (value) => value !== tagValue
            ))
        )
      );
    });

    return [
      ...this.additionalFilterConstraints,
      ...tagFilterConstraints,
    ].filter((item) => item.hasValue());
  }

  // public methods
  async ngOnInit(): Promise<void> {
    this.summary = await this._searchService.getComprehensiveSummary();
    this.filter = this._searchService.filter;

    this._menuOptionService.initMenus(this.filter);
    const uniqueLocations = this.summary.locations.map(
      (location) => location.name
    );

    this._searchService.filter =
      this._searchService.filter ??
      new Filter(
        uniqueLocations,
        this.summary.sectors,
        this.summary.subsectors,
        [],
        [],
        uniqueLocations.length,
        this.summary.sectors.length,
        this.summary.subsectors.length,
        this.summary.sectors.length,
        this.summary.subsectors.length
      );

    this.addSubscription(
      this._searchService.drilldownSubject.subscribe(() => {
        const currentlyOnSearchResultsView = this._router.isActive(
          '/search-results',
          {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
          }
        );

        // Whenever we drilldown, reset the saved position in the search results page
        this._breadcrumbsService.setSearchPageOffset(0);

        // FUTURE: The quick implementation of ADJQ-210 (and maybe partly due to ADJQ-184) resulted in this logic getting complicated!
        //  But it avoids an infinite recursion when drillDownNavigatesToSearchResults is off and filtering when on the search-results page.
        //  Seems to be due to an interaction between filterSubject and drilldownSubject subscribers,
        //  but I didn't have the time to unravel that when implementing ADJQ-210, hence the logic tree below.
        if (
          (this._authnService.isAuthenticatedUser ||
            !this._deploymentContext.featureSwitches.enablePaywall) &&
          (this.enableNavigateToSearch ||
            currentlyOnSearchResultsView ||
            this.hasSearchPredicate)
        ) {
          this._searchService.navigateToSearchResults();
        } else {
          // This seems sufficient/necessary to refresh the Explore view,
          // but it would infinitely recurse if called when already on the search results view
          this._searchService.filterSubject.next(this.filter);
        }
      })
    );

    // listen for message indicating click from parent frame
    if (this._deploymentContext?.hosted()) {
      this._deploymentContext.listenForClick();
    }
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  public updateLocationFilter(selectedLocations: Location[]): void {
    this.filter.locations = _.map(
      selectedLocations,
      ({ name: location }) => location
    );
    this._searchService.filterSubject.next(this.filter);
  }

  public updateSectorFilter(selectedSectors: Sector[]): void {
    const [sectors, subsectors] =
      determineSectorsAndSubsectors(selectedSectors);
    this.filter.primarySectors = sectors;
    this.filter.primarySubSectors = subsectors;
    this._searchService.filterSubject.next(this.filter);
  }

  public updateSecondarySectorFilter(selectedSectors: Sector[]): void {
    const [sectors, subsectors] =
      determineSectorsAndSubsectors(selectedSectors);
    this.filter.secondarySectors = sectors;
    this.filter.secondarySubSectors = subsectors;
    this._searchService.filterSubject.next(this.filter);
  }

  public updateStatusFilter(): void {
    const allOptions = [];

    this.statusOptions.forEach((group) => {
      allOptions.push(...group.options);
    });

    const selectedStatuses = _.map(
      _.filter(allOptions, (statusOption) => statusOption.value),
      (selectedOption) => selectedOption.dataModel.status as string
    );

    const statuses = this._deploymentContext.groupStatuses(selectedStatuses);

    this.filter.locationStatuses = statuses.locationStatuses.toString();
    this.filter.companyStatuses = statuses.companyStatuses.toString();
    this._searchService.filterSubject.next(this.filter);
  }

  public get useStatusDropdown(): boolean {
    return this._deploymentContext.statusMetadata?.length > 1;
  }

  public clearAdditionalFilterConstraint(
    item: AdditionalFilterConstraint
  ): void {
    item.clearValue();
    this._searchService.filterSubject.next(this.filter);
  }

  public clearFilter(): void {
    this.filter.clear();
    this._menuOptionService.initMenus(this.filter);
    this._searchService.filterSubject.next(this.filter);
    this._webAnalyticsService.trackEvent('clear-filter');
    if (this.isSearchResultsScreen) {
      this._searchService.navigateToSearchResults();
    }
  }

  public trackForAnalytics(
    trackingCriteria: string,
    value: boolean | MenuOption<unknown>
  ): void {
    this._webAnalyticsService.trackEvent('filter', {
      category: `Filter by ${trackingCriteria}`,
      label:
        typeof value === 'boolean' || !value
          ? `${value}`
          : `${(value as MenuOption<unknown>).label} - ${
              (value as MenuOption<unknown>).value
            }`,
      // FUTURE: This feels a bit fragile, but if we don't have a count yet from a previous search, assume we are working off the full set
      value:
        this._searchService.companyCountFromLastSearch ||
        this._searchService.comprehensiveSummary.numberOfCompanies,
    });
  }
}

class AdditionalFilterConstraint {
  constructor(
    public displayString: () => string,
    public hasValue: () => boolean,
    public clearValue: () => void
  ) {}
}
