import {
  Company,
  Conference,
  Contact,
  Deal,
  Funding,
  IConferenceUpdate,
  IDealUpdate,
  IFundingUpdate,
  IUpdateField,
} from 'company-finder-common';
import _ from 'lodash';

export class Diff {
  public name: IUpdateField;
  public logoBase64: IUpdateField;
  public website: IUpdateField;
  public currentRdStage: IUpdateField;
  public progressUpdate: IUpdateField;
  public keyMgmtAndAdvBm: IUpdateField;
  public description: IUpdateField;
  public problem: IUpdateField;
  public solution: IUpdateField;
  public womenLed: IUpdateField;
  public minorityLed: IUpdateField;
  public firstTimeEntrepreneur: IUpdateField;
  public countryForDeiReporting: IUpdateField;
  public leadershipDiversity: IUpdateField;
  public boardAdvisorDiversity: IUpdateField;
  public womenLedOrgLeadership: IUpdateField;
  public womenLedBoardOfDirectorsOrEquiv: IUpdateField;
  public companyContactTitle: IUpdateField;
  public tags: IUpdateField;
  public deals: IDealUpdate[];
  public funding: IFundingUpdate[];
  // Blue Knight properties
  public companyObjective: IUpdateField;
  public approachUsecase: IUpdateField;
  public entryExitStrategy: IUpdateField;
  public keyPublicationsOfScience: IUpdateField;
  public mentorship: IUpdateField;
  public engagement: IUpdateField;
  // Technology Readiness Levels
  public trl: IUpdateField;
  public twelveMonthTrl: IUpdateField;
  public twentyFourMonthTrl: IUpdateField;
  public thirtySixMonthTrl: IUpdateField;
  public fortyEightMonthTrl: IUpdateField;
  public sixtyMonthTrl: IUpdateField;
  // Future headcount needs
  public numEmpFutureNeeds: IUpdateField;
  public twelveMonthNumEmpFutureNeeds?: number;
  public twentyFourMonthNumEmpFutureNeeds: IUpdateField;
  public thirtySixMonthNumEmpFutureNeeds?: number;
  public fortyEightMonthNumEmpFutureNeeds: IUpdateField;
  public sixtyMonthNumEmpFutureNeeds?: number;
  public currencyBlueKnight: IUpdateField;
  // Conferences
  public conferencesAttended: IConferenceUpdate[];
  public anticipatedConferences: IConferenceUpdate[];
  // Milestones
  public rdMilestones12Month: IUpdateField;
  public rdMilestones24Month: IUpdateField;
  public rdMilestones36Month: IUpdateField;
  public rdMilestones48Month: IUpdateField;
  public rdMilestones60Month: IUpdateField;
  public ipMilestones12Month: IUpdateField;
  public ipMilestones24Month: IUpdateField;
  public ipMilestones36Month: IUpdateField;
  public ipMilestones48Month: IUpdateField;
  public ipMilestones60Month: IUpdateField;
  public barriersChallenges12Month: IUpdateField;
  public barriersChallenges24Month: IUpdateField;
  public barriersChallenges36Month: IUpdateField;
  public barriersChallenges48Month: IUpdateField;
  public barriersChallenges60Month: IUpdateField;
  public rdPartnerships12Month: IUpdateField;
  public rdPartnerships24Month: IUpdateField;
  public rdPartnerships36Month: IUpdateField;
  public rdPartnerships48Month: IUpdateField;
  public rdPartnerships60Month: IUpdateField;
  public fundingInvestments12Month: IUpdateField;
  public fundingInvestments24Month: IUpdateField;
  public fundingInvestments36Month: IUpdateField;
  public fundingInvestments48Month: IUpdateField;
  public fundingInvestments60Month: IUpdateField;

  public get hasChanges(): boolean {
    return (
      this.hasPropertyChanges ||
      this.hasConferenceChanges ||
      this.hasDealChanges ||
      this.hasFundingChanges
    );
  }

  public get hasPropertyChanges(): boolean {
    // NOTE: progressUpdate is handled separately.
    if (
      isUpdateAChange(this.name) ||
      isUpdateAChange(this.logoBase64) ||
      isUpdateAChange(this.website) ||
      isUpdateAChange(this?.currentRdStage) ||
      isUpdateAChange(this.keyMgmtAndAdvBm) ||
      isUpdateAChange(this.description) ||
      isUpdateAChange(this.problem) ||
      isUpdateAChange(this.solution) ||
      isUpdateAChange(this.womenLed) ||
      isUpdateAChange(this.minorityLed) ||
      isUpdateAChange(this.firstTimeEntrepreneur) ||
      isUpdateAChange(this.countryForDeiReporting) ||
      isUpdateAChange(this.leadershipDiversity) ||
      isUpdateAChange(this.boardAdvisorDiversity) ||
      isUpdateAChange(this.womenLedOrgLeadership) ||
      isUpdateAChange(this.womenLedBoardOfDirectorsOrEquiv) ||
      isUpdateAChange(this.companyContactTitle) ||
      isUpdateAChange(this.tags) ||
      // Possible Blue Knight updates
      isUpdateAChange(this.companyObjective) ||
      isUpdateAChange(this.approachUsecase) ||
      isUpdateAChange(this.entryExitStrategy) ||
      isUpdateAChange(this.keyPublicationsOfScience) ||
      isUpdateAChange(this.mentorship) ||
      isUpdateAChange(this.engagement) ||
      isUpdateAChange(this.currencyBlueKnight) ||
      // Technology Readiness Levels
      isUpdateAChange(this.trl) ||
      isUpdateAChange(this.twelveMonthTrl) ||
      isUpdateAChange(this.twentyFourMonthTrl) ||
      isUpdateAChange(this.thirtySixMonthTrl) ||
      isUpdateAChange(this.fortyEightMonthTrl) ||
      isUpdateAChange(this.sixtyMonthTrl) ||
      // Future headcount needs
      isUpdateAChange(this.numEmpFutureNeeds) ||
      isUpdateAChange(this.twelveMonthNumEmpFutureNeeds) ||
      isUpdateAChange(this.twentyFourMonthNumEmpFutureNeeds) ||
      isUpdateAChange(this.thirtySixMonthNumEmpFutureNeeds) ||
      isUpdateAChange(this.fortyEightMonthNumEmpFutureNeeds) ||
      isUpdateAChange(this.sixtyMonthNumEmpFutureNeeds) ||
      // Milestones
      isUpdateAChange(this.rdMilestones12Month) ||
      isUpdateAChange(this.rdMilestones24Month) ||
      isUpdateAChange(this.rdMilestones36Month) ||
      isUpdateAChange(this.rdMilestones48Month) ||
      isUpdateAChange(this.rdMilestones60Month) ||
      isUpdateAChange(this.ipMilestones12Month) ||
      isUpdateAChange(this.ipMilestones24Month) ||
      isUpdateAChange(this.ipMilestones36Month) ||
      isUpdateAChange(this.ipMilestones48Month) ||
      isUpdateAChange(this.ipMilestones60Month) ||
      isUpdateAChange(this.barriersChallenges12Month) ||
      isUpdateAChange(this.barriersChallenges24Month) ||
      isUpdateAChange(this.barriersChallenges36Month) ||
      isUpdateAChange(this.barriersChallenges48Month) ||
      isUpdateAChange(this.barriersChallenges60Month) ||
      isUpdateAChange(this.rdPartnerships12Month) ||
      isUpdateAChange(this.rdPartnerships24Month) ||
      isUpdateAChange(this.rdPartnerships36Month) ||
      isUpdateAChange(this.rdPartnerships48Month) ||
      isUpdateAChange(this.rdPartnerships60Month) ||
      isUpdateAChange(this.fundingInvestments12Month) ||
      isUpdateAChange(this.fundingInvestments24Month) ||
      isUpdateAChange(this.fundingInvestments36Month) ||
      isUpdateAChange(this.fundingInvestments48Month) ||
      isUpdateAChange(this.fundingInvestments60Month)
    ) {
      return true;
    }

    return false;
  }

  public get hasConferenceChanges(): boolean {
    return (
      this.conferencesAttended?.length > 0 ||
      this.anticipatedConferences?.length > 0
    );
  }

  public get hasDealChanges(): boolean {
    return this.deals?.length > 0;
  }

  public get hasFundingChanges(): boolean {
    return this.funding?.length > 0;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUpdateAChange(field?: any): boolean {
  if (!field) {
    return false;
  }
  return areObjectsDifferent(field.oldValue, field.newValue);
}

export function areObjectsDifferent(obj1: unknown, obj2: unknown): boolean {
  if (areBothEmptyNullOrUndefined(obj1, obj2)) {
    return false;
  }

  if (areObjectsEquivalentBooleans(obj1, obj2)) {
    return false;
  }

  return obj1 !== obj2;
}

export function areBothEmptyNullOrUndefined(obj1: any, obj2: any): boolean {
  return (
    (obj1 === null || obj1 === undefined || obj1 === '') &&
    (obj2 === null || obj2 === undefined || obj2 === '')
  );
}

export const TAG_SEPARATOR = '_#_';

export type ComplexUpdateObject = Conference | Deal | Funding;
export type ComplexUpdateKey =
  | 'conferencesAttended'
  | 'anticipatedConferences'
  | 'deals'
  | 'funding';
export function getObjectAndType(key: ComplexUpdateKey): {
  typeId: string;
  newItem: ComplexUpdateObject;
} {
  switch (key) {
    case 'conferencesAttended':
    case 'anticipatedConferences':
      return { typeId: 'conferenceId', newItem: new Conference() };
    case 'deals':
      return { typeId: 'dealId', newItem: new Deal() };
    case 'funding':
      return { typeId: 'fundingId', newItem: new Funding() };
  }
}

export function isComplexUpdateObject(key: string): boolean {
  return (
    key === 'conferencesAttended' ||
    key === 'anticipatedConferences' ||
    key === 'deals' ||
    key === 'funding'
  );
}

// NOTE: Passing ordinary any types here for object & base is causing lodash to match the wrong _.transform() call signature.
//       Using lodash's Dictionary parameterized type instead finds the desired _.transform() method, keeping the compiler happy.
export function findChanges(
  object: _.Dictionary<any>,
  base: _.Dictionary<any>
): any {
  // Extended from original technique found here https://gist.github.com/Yimiprod/7ee176597fef230d1451
  const fullChanges = _.transform(object, (result, value, key: string) => {
    if (isComplexUpdateObject(key)) {
      // value & base[key] are arrays of Conference | Deal | Funding
      findComplexUpdateChanges(
        key as ComplexUpdateKey,
        value as ComplexUpdateObject[],
        base[key] as ComplexUpdateObject[],
        result
      );
    } else {
      // NOTE: Dates need special handling because they are modeled as Date objects,
      //       but they are stored as strings, and users provide them as strings.
      if (key === 'announcementDate' || key === 'dateRaised') {
        if (value instanceof Date) {
          value = value.toISOString();
        }
        if (base[key] instanceof Date) {
          base[key] = base[key].toISOString();
        }
      }
      if (!_.isEqual(value, base[key])) {
        if (_.isObject(value) && _.isObject(base[key])) {
          // NOTE: A company has a companyContact Contact object, which reports as different
          //       if the user changes the title (the only thing they are allowed to change).
          //       If we're attempting to compare the companyContact differences here, skip
          //       directly to the title.
          if (key === 'companyContact') {
            // We considered trying to enhance _.isEqual to treat null & undefined (and empty string)
            // as equivalent for properties of objects being compared, but for now, we just did this targeted fix
            if (
              areObjectsDifferent((value as Contact).title, base[key].title)
            ) {
              result['companyContactTitle'] = {
                newValue: (value as Contact).title || '',
                oldValue: base[key].title || '',
                modelId: base.opportunityIdPrimary,
              };
            }
          } else if (key === 'tags') {
            if (!areBothEmptyNullOrUndefined(value, base[key])) {
              const newValue = _.cloneDeep(value as string[]);
              const oldValue = _.cloneDeep(base[key]);
              result[key] = {
                newValue: newValue.sort().join(TAG_SEPARATOR),
                oldValue: oldValue.sort().join(TAG_SEPARATOR),
                modelId: base.opportunityIdPrimary,
              };
            }
          } else {
            result[key] = findChanges(value, base[key]);
          }
        } else {
          if (
            !areBothEmptyNullOrUndefined(value, base[key]) &&
            !areObjectsEquivalentBooleans(value, base[key])
          ) {
            result[key] = {
              newValue: value,
              oldValue: base[key],
              modelId:
                (base as Conference).conferenceId ||
                (base as Deal).dealId ||
                (base as Funding).fundingId ||
                base.opportunityIdPrimary,
            };
          }
        }
      }
    }
  });

  if (Object.keys(fullChanges).length !== 0) {
    JSON.stringify(fullChanges);
  }

  return fullChanges;
}

export function findComplexUpdateChanges(
  complexKey: ComplexUpdateKey,
  complexValue: ComplexUpdateObject[],
  complexBase: ComplexUpdateObject[],
  result: unknown
): void {
  const { typeId, newItem } = getObjectAndType(complexKey);
  const addedComplexUpdateObject = complexValue?.filter(
    (item: ComplexUpdateObject) =>
      !_.some(
        complexBase,
        (baseItem: ComplexUpdateObject) => baseItem[typeId] === item[typeId]
      )
  );
  const deletedComplexUpdateObject = complexBase?.filter(
    (baseItem: ComplexUpdateObject) =>
      !_.some(
        complexValue,
        (item: ComplexUpdateObject) => item[typeId] === baseItem[typeId]
      )
  );
  const possiblyEditedComplexUpdateObject = [];
  complexValue?.filter((item) => {
    const match = complexBase?.find(
      (baseItem) => baseItem[typeId] === item[typeId]
    );
    if (match) {
      possiblyEditedComplexUpdateObject.push({
        updated: item,
        base: match,
      });
    }
  });

  addedComplexUpdateObject?.forEach((addedThing) => {
    result[complexKey] = result[complexKey] || [];
    newItem[typeId] = addedThing[typeId];
    result[complexKey].push(findChanges(addedThing, newItem));
  });

  deletedComplexUpdateObject?.forEach((deletedThing) => {
    result[complexKey] = result[complexKey] || [];
    const deletion = {
      isDeleted: true,
    } as ComplexUpdateObject;
    deletion[typeId] = deletedThing[typeId];
    result[complexKey].push(findChanges(deletion, deletedThing));
  });

  possiblyEditedComplexUpdateObject?.forEach((possiblyEditedThing) => {
    const changes = findChanges(
      possiblyEditedThing.updated,
      possiblyEditedThing.base
    );
    if (!_.isEmpty(changes)) {
      result[complexKey] = result[complexKey] || [];
      result[complexKey].push(changes);
    }
  });
}

export function compareCompanies(object: Company, base: Company): Diff {
  const newDiff = new Diff();
  const diff = findChanges(object, base);
  const merged = _.merge(newDiff, diff);
  return merged;
}

export function areObjectsEquivalentBooleans(
  obj1: unknown,
  obj2: unknown
): boolean {
  return (
    (valIsTruthy(obj1) && valIsTruthy(obj2)) ||
    (valIsFalsy(obj1) && valIsFalsy(obj2))
  );
}

export function valIsTruthy(value: unknown): boolean {
  return value === '1' || value === 1 || value === true;
}

export function valIsFalsy(value: unknown): boolean {
  return value === '0' || value === 0 || value === false;
}
