import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// component imports
import { ActionResult } from '../../../../_common/components/action-modal/action-modal.component';

// service/utility imports
import { NewConferenceDealOrFundingPrefix } from '../../../../_common/utilities/application-context/application-context';
import { CompanyUpdateService } from '../../services/company-update.service';
import { ReviewEditsService } from '../../services/review-edits.service';
import { RequireMoreThanJustSpaces } from '../../../../_common/utilities/forms/form-util';

// model imports
import { Conference, UpdateStatus } from 'company-finder-common';
import {
  ReviewableEntity,
  ReviewableUpdate,
  SelfUpdateMode,
} from '../../company-update.interface';

// vendor imports
import { Subject, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'conference-modal',
  templateUrl: './conference-modal.component.html',
  styleUrls: ['./conference-modal.component.scss'],
})
export class ConferenceModalComponent
  extends ComponentBase
  implements OnDestroy
{
  // public properties
  public conferenceForm: FormGroup;
  public conferenceFormSubscription: Subscription;

  public closingSubject: Subject<{ conference: Conference; isSave: boolean }> =
    new Subject<{ conference: Conference; isSave: boolean }>();
  public approveDeclineSubject: Subject<{
    conference: Conference;
    isApproved: boolean;
  }> = new Subject<{ conference: Conference; isApproved: boolean }>();

  public SelfUpdateModes = SelfUpdateMode;

  // private properties
  private _conferenceToRestore: Conference;
  private _showModal: boolean;

  constructor(
    deploymentContext: DeploymentContext,
    private _reviewEditsService: ReviewEditsService,
    private _companyUpdateService: CompanyUpdateService
  ) {
    super(deploymentContext);
  }

  // public getters/setters
  public get conference(): Conference {
    return this._companyUpdateService.conferenceToModify;
  }

  public set conference(value: Conference) {
    this._companyUpdateService.conferenceToModify = value;
  }

  public get conferenceUpdateStatus(): UpdateStatus {
    return this._reviewEditsService.getModelStatus(
      this.conference.conferenceId
    );
  }

  public get isSaveEnabled(): boolean {
    if (!this.conferenceForm) {
      return false;
    }
    return this.conferenceForm.dirty && this.conferenceForm.valid;
  }

  public get selfUpdateMode(): SelfUpdateMode {
    return this._companyUpdateService.selfUpdateMode;
  }

  public get showModal(): boolean {
    return this._showModal;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('showModal')
  public set showModal(value: boolean) {
    this._showModal = value;

    if (!this.showModal && this.conferenceForm) {
      return;
    }

    this._conferenceToRestore = _.cloneDeep(this.conference);
    this.initializeConferenceForm();
    if (this.conference.isDeleted) {
      this.conferenceForm.disable();
    } else {
      this.conferenceForm.enable();
    }
  }

  public get updateForReview(): ReviewableUpdate {
    return this._companyUpdateService.updateForReview;
  }

  public set updateForReview(update: ReviewableUpdate) {
    this._companyUpdateService.updateForReview = update;
  }

  public get conferenceUpdateForReview(): ReviewableEntity {
    return this.updateForReview && this.updateForReview.conferenceUpdates
      ? this.updateForReview.conferenceUpdates[this.conference.conferenceId]
      : undefined;
  }

  // public methods
  public hasPropertyBeenModified(propertyName: string): boolean {
    return (
      this.conferenceUpdateForReview &&
      this.conferenceUpdateForReview.updateFields[propertyName] &&
      this.conferenceUpdateForReview.updateFields[propertyName].isSet
    );
  }

  public initializeConferenceForm(): void {
    if (this.conferenceFormSubscription) {
      this.conferenceFormSubscription.unsubscribe();
    }
    this.conferenceForm = new FormGroup({
      conferenceName: new FormControl(
        this.conference.conferenceName,
        RequireMoreThanJustSpaces.required
      ),
      conferencePerceivedAddedValue: new FormControl(
        this.conference.conferencePerceivedAddedValue,
        RequireMoreThanJustSpaces.required
      ),
    });

    this.conferenceFormSubscription =
      this.conferenceForm.valueChanges.subscribe((formValues) => {
        this.conference.conferenceName =
          formValues.conferenceName && formValues.conferenceName.trim();
        this.conference.conferencePerceivedAddedValue =
          formValues.conferencePerceivedAddedValue &&
          formValues.conferencePerceivedAddedValue.trim();
      });
  }

  public ngOnDestroy(): void {
    if (this.conferenceFormSubscription) {
      this.conferenceFormSubscription.unsubscribe();
    }
  }

  public handleAction(action: ActionResult): void {
    switch (action) {
      case ActionResult.Approve:
        this.approveDeclineSubject.next({
          conference: this.conference,
          isApproved: true,
        });
        // NOTE: The company-update.component's approveDeclineSubject handler will hide the modal.
        break;
      case ActionResult.Decline:
        this.approveDeclineSubject.next({
          conference: this.conference,
          isApproved: false,
        });
        // NOTE: The company-update.component's approveDeclineSubject handler will hide the modal.
        break;
      case ActionResult.Delete:
        _.merge(this.conference, this._conferenceToRestore);
        this.conference.isDeleted = true;
        this.close(true);
        break;
      case ActionResult.Restore:
        this.conference.isDeleted = false;
        this.close(true);
        break;
      case ActionResult.Submit:
        this.close(true);
        break;
      case ActionResult.Cancel:
      case ActionResult.Close:
        this.close(false);
        break;
    }
  }

  // private methods
  private close(save: boolean) {
    if (save || this.conference.isDeleted) {
      if (!this.conference.conferenceId) {
        this.conference.conferenceId = `${NewConferenceDealOrFundingPrefix}${uuidv4()}`;
      }
      this.closingSubject.next({ conference: this.conference, isSave: true });
    } else {
      this.closingSubject.next({
        conference: this._conferenceToRestore,
        isSave: false,
      });
    }
  }
}
