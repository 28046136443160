import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LogoSize } from '../../../_common/constants/LogoSize';
import _ from 'lodash';
import {
  Company,
  Filter,
  SelectionEventData,
  SortType,
} from 'company-finder-common';
import { CompanyService } from '../../../_common/services/company/company.service';
import { PickerModalBaseComponent } from '../../../_common/components/picker-modal/picker-modal-base.component';
import { UserService } from '../../../_common/services/user/user.service';
import { addCompaniesToUser } from '../../../_common/utilities/preferences/preferences.util';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'companies-picker-modal',
  templateUrl: './companies-picker-modal.component.html',
  styleUrls: ['./companies-picker-modal.component.scss'],
  // We want to allow the template to have access to styling we put on the outer component (like .selected),
  // so turn off encapsulation.  We'll be careful not to cause style conflicts.
  encapsulation: ViewEncapsulation.None,
})
export class CompaniesPickerModalComponent extends PickerModalBaseComponent<Company> {
  @Input()
  public isOnBoardingWizard: boolean;
  @Input()
  public totalMatchedCompanies: number;

  public constructor(
    dc: DeploymentContext,
    userService: UserService,
    private _companyService: CompanyService,
  ) {
    super(dc, userService);
  }

  public get logoSize(): LogoSize {
    return LogoSize.Small;
  }

  public compareCompanies(company1: Company, company2: Company): boolean {
    return company1.id === company2.id;
  }

  public async doSearch(newSearchText: string): Promise<Company[]> {
    if (newSearchText.length < 2) {
      return [];
    }

    const result = await this._companyService.searchAndFilter(
      false,
      newSearchText + '*',
      0,
      0,
      Filter.emptyAlphaSortFilter,
      'name'
    );

    return result.companies;
  }

  public async doSelect(selectionEventData: SelectionEventData): Promise<void> {
    // This logic is to update the "live" count for the wizard,
    // no need to make API calls otherwise
    if (!this.isOnBoardingWizard) {
      return;
    }
    // NOTE: This pattern is a little different than locations & sectors because the preferences.component
    //       caches companies, which we don't really need to do here.
    const companyIdsToAdd = selectionEventData.selectedItems.map(
      (company) => company.id
    );

    const wizardUser = _.cloneDeep(this.user);
    addCompaniesToUser(companyIdsToAdd, wizardUser);
    this.updateCompanyCountForWizard(wizardUser);
  }
}
