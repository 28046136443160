import { Component, OnInit } from '@angular/core';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';

import { CurrentRdStage } from 'company-finder-common';
@Component({
  selector: 'public-information',
  templateUrl: './public-information.component.html',
  styleUrls: ['./public-information.component.scss'],
})
export class PublicInformationComponent
  extends UpdateComponentBaseWithEditItems
  implements OnInit
{
  private existingTags: string[];
  // A normalized (lowercase) table of existing tags.
  // For example: this.normalizedExistingTagsTable['ai'] = true;
  public normalizedExistingTagsTable: [];

  public async ngOnInit(): Promise<void> {
    this.existingTags = await this._companyService.getTags();
    this.normalizedExistingTagsTable = [];
    this.existingTags.forEach(
      (tag) => (this.normalizedExistingTagsTable[tag.toLowerCase()] = true)
    );
  }

  public get currentRdStageList(): CurrentRdStage[] {
    return this._deploymentContext.referenceValueData.rdStagesBySector[
      this.company.primarySector
    ];
  }

  public get hasContact(): boolean {
    return !!this.company.companyContact;
  }
}
