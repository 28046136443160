import {
  ContentChildren,
  Directive,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompanyService } from '../../_common/services/company/company.service';
import { UpdateComponentBase } from './UpdateComponentBase';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { CompanyUpdateService } from './services/company-update.service';
import {
  MilestoneLayoutInfo,
  MilestoneProperty,
  MilestoneType,
  MilestoneValue,
} from '../../_common/utilities/blue-knight/blue-knight.util';
import { displayMilestone } from '../../_common/utilities/blue-knight/blue-knight.util';
import {
  Deal,
  Funding,
  ICompanyUpdate,
  LocalizedTextIds,
  extractNumber,
  isNilOrWhitespace,
} from 'company-finder-common';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';

@Directive()
export abstract class UpdateComponentBaseWithEditItems extends UpdateComponentBase {
  // The edit components are subscribed to in the main update component, both for
  // any it has and for each tab component. This is a separate base so that
  // the EditItemComponent itself can have all the company items, the current mode, etc
  @ViewChildren(EditItemComponent)
  public editItemComponents: QueryList<EditItemComponent>;
  @ContentChildren(EditItemComponent)
  public editItemContent: QueryList<EditItemComponent>;

  public get editItemArray(): EditItemComponent[] {
    return this.editItemComponents?.toArray() || [];
  }

  @Input()
  public companyEditForm: FormGroup;

  // public methods
  public displayMilestone(
    milestoneValue: MilestoneValue,
    propName: string,
    pendingUpdate: ICompanyUpdate
  ): boolean {
    return displayMilestone(
      this.company,
      milestoneValue,
      propName,
      pendingUpdate
    );
  }

  public getMilestoneLabelText(
    milestoneProperty: MilestoneProperty,
    milestoneLabel: string
  ): string {
    if (milestoneProperty.milestoneValue === MilestoneValue.Initial) {
      return `${milestoneProperty.label}`;
    } else {
      return this.Loc(
        LocalizedTextIds.UpdateComponentBaseAnticipated,
        milestoneLabel,
        milestoneProperty.label
      );
    }
  }

  public getMilestonePropertyName(
    milestonePropertyRoot: string,
    milestoneLabel: string = null
  ): string {
    const months = this.getMonthsFromYearsLabel(milestoneLabel);

    // When not prefixed, the first letter needs to be lowercased
    const revisedPropertyRoot =
      months > 0
        ? milestonePropertyRoot
        : milestonePropertyRoot.charAt(0).toLowerCase() +
          milestonePropertyRoot.slice(1);

    return this.isPropertyInverted(milestonePropertyRoot)
      ? `${this.getMonthNumberPrefixAsString(months)}${revisedPropertyRoot}`
      : `${revisedPropertyRoot}${months}Month`;
  }

  public getMonthsFromYearsLabel(label: string): number {
    if (isNilOrWhitespace(label)) {
      return 0;
    }

    return extractNumber(label) * 12;
  }

  public isPropertyInverted(property: string): boolean {
    return property === 'Trl' || property === 'NumEmpFutureNeeds';
  }

  public getMonthNumberPrefixAsString(months: number): string {
    if (months > 0) {
      return `${this.getMonthNumberAsString(months)}Month`;
    } else {
      return '';
    }
  }

  public getMonthNumberAsString(months: number): string {
    switch (months) {
      case 12:
        return 'twelve';
      case 24:
        return 'twentyFour';
      case 36:
        return 'thirtySix';
      case 48:
        return 'fortyEight';
      case 60:
        return 'sixty';
    }
  }

  public get MilestoneType(): typeof MilestoneType {
    return MilestoneType;
  }

  // If an *ngFor loop does not have a unique identifier for each object in the array,
  // it may lose the binding between the DOM element and the object during changes
  // to the DOM. To avoid this, we provide a custom TrackBy function for the objects
  // to ensure a stable unique identifier for each one.
  public milestonePropertyTrackBy(
    _index: number,
    property: MilestoneProperty
  ): string {
    return `${property.propertyRoot}:${property.milestoneValue}`;
  }

  public milestoneInfoTrackBy(
    _index: number,
    info: MilestoneLayoutInfo
  ): string {
    return `${info.label}:${info.type}`;
  }

  public dealTrackBy(_index: number, deal: Deal): string {
    return deal.dealId;
  }

  public fundingTrackBy(_index: number, funding: Funding): string {
    return funding.fundingId;
  }

  public constructor(
    dc: DeploymentContext,
    _companyUpdateService: CompanyUpdateService,
    _companyService: CompanyService
  ) {
    super(dc, _companyUpdateService, _companyService);
  }
}
