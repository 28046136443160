import { Location } from '../model/location';
import { StatusDisplayItem } from './displayItem';
import _ from 'lodash';

export interface ObjectWithCount {
    count: number;
}

export interface ObjectWithCanonicalString {
    canonicalString: string;
}

export interface TagWithCount extends ObjectWithCount {
    tag: string;
}

export interface TagWithCountAndCanonicalString
    extends TagWithCount,
        ObjectWithCanonicalString {}

export interface LocationWithCount extends ObjectWithCount {
    location: Location;
}
export interface LocationWithCountAndCanonicalString
    extends LocationWithCount,
        ObjectWithCanonicalString {}

export interface SectorWithCount extends ObjectWithCount {
    sector: string;
    parentSector?: string;
    subSectors?: string[];
}

export interface SectorWithCountAndCanonicalString
    extends SectorWithCount,
        ObjectWithCanonicalString {}

export interface StatusWithCount extends ObjectWithCount {
    statusItem: StatusDisplayItem;
}

export function compareItemsWithCanonicalString(
    l1: ObjectWithCanonicalString | string,
    l2: ObjectWithCanonicalString | string
): boolean {
    if (_.isNil(l1)) {
        return _.isNil(l2);
    }
    if (_.isNil(l2)) {
        return false;
    }

    const loc1 = typeof l1 === 'string' ? l1 : l1.canonicalString;
    const loc2 = typeof l2 === 'string' ? l2 : l2.canonicalString;
    return loc1.toLowerCase() === loc2.toLowerCase();
}
