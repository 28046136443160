import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { Funding, UpdateStatus } from 'company-finder-common';
import {
  ReviewableEntity,
  ReviewableUpdate,
  SelfUpdateMode,
} from '../../company-update.interface';

import { NewConferenceDealOrFundingPrefix } from '../../../../_common/utilities/application-context/application-context';
import {
  isEmptyInputValue,
  MoneyRequiresCurrency,
  RequireMoreThanJustSpaces,
} from '../../../../_common/utilities/forms/form-util';
import { ReviewEditsService } from '../../services/review-edits.service';
import {
  constructDateString,
} from '../../utils';

import { ActionResult } from '../../../../_common/components/action-modal/action-modal.component';
import { CompanyUpdateService } from '../../services/company-update.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { ComponentBase } from '../../../../_common/components/_component.base';

@Component({
  selector: 'funding-modal',
  templateUrl: './funding-modal.component.html',
  styleUrls: ['./funding-modal.component.scss'],
})
export class FundingModalComponent extends ComponentBase implements OnDestroy {
  // public properties
  public fundingForm: FormGroup;
  public fundingFormSubscription: Subscription;
  public isShowingTooltip = {
    publiclyOffered: false,
    strategicRelationships: false,
  };
  public closingSubject: Subject<{ funding: Funding; isSave: boolean }> =
    new Subject<{ funding: Funding; isSave: boolean }>();
  public approveDeclineSubject: Subject<{
    funding: Funding;
    isApproved: boolean;
  }> = new Subject<{ funding: Funding; isApproved: boolean }>();
  @Input()
  public selfUpdateMode: SelfUpdateMode;
  public SelfUpdateModes = SelfUpdateMode;

  // private properties
  private _originalFundingDateString: string;
  private _fundingToRestore: Funding;
  private _previousDateEntry: Date;
  private _showModal: boolean;

  constructor(
    deploymentContext: DeploymentContext,
    private _datePipe: DatePipe,
    private _reviewEditsService: ReviewEditsService,
    private _companyUpdateService: CompanyUpdateService
  ) {
    super(deploymentContext);
  }

  // public getters/setters
  public get funding(): Funding {
    return this._companyUpdateService.fundingToModify;
  }

  public set funding(value: Funding) {
    this._companyUpdateService.fundingToModify = value;
  }

  public get fundingUpdateStatus(): UpdateStatus {
    return this._reviewEditsService.getModelStatus(this.funding.fundingId);
  }

  public get isSaveEnabled(): boolean {
    if (!this.fundingForm) {
      return false;
    }
    return this.fundingForm.dirty && this.fundingForm.valid;
  }

  public get showModal(): boolean {
    return this._showModal;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('showModal')
  public set showModal(value: boolean) {
    this._showModal = value;
    this._fundingToRestore = _.cloneDeep(this.funding);
    this._originalFundingDateString = this._datePipe.transform(
      this._fundingToRestore.dateRaised,
      'yyyy-MM-dd'
    );
    this.initializeFundingForm();
    if (this.funding.isDeleted) {
      this.fundingForm.disable();
    } else {
      this.fundingForm.enable();
    }
  }

  @Input()
  public get updateForReview(): ReviewableUpdate {
    return this._companyUpdateService.updateForReview;
  }

  public set updateForReview(update: ReviewableUpdate) {
    this._companyUpdateService.updateForReview = update;
  }

  public get fundingUpdateForReview(): ReviewableEntity {
    return this.updateForReview && this.updateForReview.fundingUpdates
      ? this.updateForReview.fundingUpdates[this.funding.fundingId]
      : undefined;
  }

  public get fundingRoundTypes(): string[] {
    return this._deploymentContext.referenceValueData.fundingRoundList.map(
      (item) => item.value
    );
  }

  public get currencyTypes(): string[] {
    return this._deploymentContext.referenceValueData.securedFundingCurrencyList.map(
      (item) => item.value
    );
  }

  public getReferenceValueDisplayString(value: string): string {
    return value;
  }

  // public methods
  public hasPropertyBeenModified(propertyName: string): boolean {
    return (
      this.fundingUpdateForReview &&
      this.fundingUpdateForReview.updateFields[propertyName] &&
      this.fundingUpdateForReview.updateFields[propertyName].isSet
    );
  }

  public showTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = true;
  }

  public hideTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = false;
  }

  public initializeFundingForm(): void {
    if (this.fundingFormSubscription) {
      this.fundingFormSubscription.unsubscribe();
    }
    const dateRaisedValidators = [Validators.required];
    this.fundingForm = new FormGroup(
      {
        fundingRound: new FormControl(
          this.funding.fundingRound,
          Validators.required
        ),
        fundingParty: new FormControl(
          this.funding.fundingParty,
          RequireMoreThanJustSpaces.required
        ),
        raised: new FormControl(
          this.funding.raised,
          Validators.pattern('^[0-9]+(.[0-9]{2})?$')
        ),
        raisedCurrency: new FormControl(this.funding.raisedCurrency),
        dateRaised: new FormControl(
          this._datePipe.transform(this.funding.dateRaised, 'yyyy-MM-dd', 'UTC'),
          dateRaisedValidators
        ),
        comments: new FormControl(this.funding.comments),
        isConfidential: new FormControl(this.funding.isConfidential || 0),
      },
      MoneyRequiresCurrency.required({
        valueFields: ['raised'],
        currencyField: 'raisedCurrency',
      })
    );

    const dateRaisedControl = this.fundingForm.get('dateRaised');

    this.fundingFormSubscription = this.fundingForm.valueChanges.subscribe(
      (formValues) => {
        this.funding.fundingRound = formValues.fundingRound;
        this.funding.fundingParty =
          formValues.fundingParty && formValues.fundingParty.trim();
        this.funding.raised = formValues.raised;
        this.funding.raisedCurrency = formValues.raisedCurrency;
        this.funding.dateRaised =
          dateRaisedControl.dirty &&
            this._originalFundingDateString !== formValues.dateRaised
            ? this.getLocaleTime(formValues.dateRaised)
            : this.funding.dateRaised;
        // The form wants to send back null, which would diff undesirably with an undefined value, so handle that here.
        this.funding.comments = !isEmptyInputValue(formValues.comments)
          ? formValues.comments && formValues.comments.trim()
          : undefined;
        this.funding.isConfidential = formValues.isConfidential || false;
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.fundingFormSubscription) {
      this.fundingFormSubscription.unsubscribe();
    }
  }

  public handleAction(action: ActionResult): void {
    switch (action) {
      case ActionResult.Approve:
        this.approveDeclineSubject.next({
          funding: this.funding,
          isApproved: true,
        });
        // NOTE: The company-update.component's approveDeclineSubject handler will hide the modal.
        break;
      case ActionResult.Decline:
        this.approveDeclineSubject.next({
          funding: this.funding,
          isApproved: false,
        });
        // NOTE: The company-update.component's approveDeclineSubject handler will hide the modal.
        break;
      case ActionResult.Delete:
        _.merge(this.funding, this._fundingToRestore);
        this.funding.isDeleted = true;
        this.close(true);
        break;
      case ActionResult.Restore:
        this.funding.isDeleted = false;
        this.close(true);
        break;
      case ActionResult.Submit:
        this.close(true);
        break;
      case ActionResult.Cancel:
      case ActionResult.Close:
        this.close(false);
        break;
    }
  }

  /**
   * Browsers are handling the date input differently with regard to the yyyy field. Some were failing to handle
   * huge years, e.g., 30002, (ADJQ-936); while Chrome seemed tolerant, Firefox was failing. IE 11 doesn't support the
   * date input at all. Firefox was creating "Invalid Date" objects, on which Angular's date pipe was choking. Adding
   * a max date value to the input field, and handling the ngModelChanges here gives us a chance to reset the date before
   * Angular gags.
   * The behavior still varies a bit. Chrome cycles through 4-digit years, clipping the oldest value.
   *    For example, if the user types 12345 the date becomes 2345
   * Firefox, on the other hand, cycles through 6-digits before resetting and appending the newest digit to 000.
   *    For example, if the user types 1234567 the date becomes 0007
   * in the meantime, the 5- and 6-digit dates are causing "Invalid Dates" in Firefox. For some reason the date is in a
   * state here where we're able to access it, check its year, and adjust on the fly. In Save it's too late, perhaps
   * because we're trying to apply the value to the form. So here we check the date's yyyy value, and if it isNan() reset
   * the date to the most recent valid date the user entered. Note that we never encounter the isNaN() logic in Chrome.
   * The net result of all this is that the user experiences vary depending on the browser they're using, but we're in a
   * more stable situation that should avoid the issues we saw in ADJQ-936.
   */
  public handleDateChange(): void {
    if (!this.funding.dateRaised) {
      return;
    }
    const yyyy = this.funding.dateRaised.getFullYear();
    if (isNaN(yyyy)) {
      this.funding.dateRaised = this._previousDateEntry;
      if (this._previousDateEntry) {
        const dateRaisedControl = this.fundingForm.get('dateRaised');
        const dateStr = constructDateString(this.funding.dateRaised);
        dateRaisedControl.setValue(dateStr);
      }
    }
    this._previousDateEntry = this.funding.dateRaised;
  }

  // private methods
  private close(save: boolean) {
    if (save || this.funding.isDeleted) {
      if (!this.funding.fundingId) {
        this.funding.fundingId = `${NewConferenceDealOrFundingPrefix}${uuidv4()}`;
      }
      this.closingSubject.next({ funding: this.funding, isSave: true });
    } else {
      this.closingSubject.next({
        funding: this._fundingToRestore,
        isSave: false,
      });
    }
  }

  private getLocaleTime(dateString: string): Date {
    // FUTURE: Superclass for the deal/funding modals
    const requestedDate = new Date(dateString);
    const timezoneOffsetInMilliseconds =
      requestedDate.getTimezoneOffset() * 60 * 1000;
    return new Date(requestedDate.getTime() + timezoneOffsetInMilliseconds);
  }
}
