/* eslint-disable prettier/prettier */
import { Component, Input } from '@angular/core';
import { BaseSelectorComponent } from '../BaseSelectorComponent';


@Component({
    selector: 'simple-selector',
    templateUrl: './simple-selector.component.html',
    styleUrls: ['../base-selector.scss']
})
export class SimpleSelectorComponent<T> extends BaseSelectorComponent<T> {

    @Input() label = 'Select';
    @Input() class = 'dropdown';
    @Input() contentPosition = ['bottom', 'left'];
}


