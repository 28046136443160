import { Component } from '@angular/core';
import {
  compareItemsWithCanonicalString,
  SectorDisplayItem,
  SectorWithCountAndCanonicalString,
  SelectionEventData,
} from 'company-finder-common';
import _ from 'lodash';
import { SavedSearchCriteriaBaseComponent } from '../saved-search-criteria-base/saved-search-criteria-base.component';

@Component({
  selector: 'saved-search-sector-selector',
  templateUrl: './saved-search-sector-selector.component.html',
  styleUrls: ['./saved-search-sector-selector.component.scss'],
})
export class SavedSearchSectorSelectorComponent<
  T extends SectorDisplayItem
> extends SavedSearchCriteriaBaseComponent<T> {
  // public properties
  // Keep track of collapsed state of children by the parent
  // The default state is collapsed (not open)
  public openChildOptions: { [id: string]: boolean } = {};

  // public methods
  // We override this here because sector deselection might need to deselect a parent sector,
  // and the saved search modal's sector data model is different enough from location, status,
  // & the other sector selection modes in the app that we needed to override this method to
  // account for an impedance mismatch, since the other sector *dropdown* [de]selections & this
  // [de]selection both need to feed the filter, which was originally developed with the
  // *dropdown* approach in mind. ADJQ-1141 is backlogged to resolve the impedance mismatch.
  // See also: The SavedSearchBaseComponent.sectorSummary getter adds some properties to help.
  public deselectItem(selected: T): void {
    if (selected) {
      _.remove(
        this._selectedItems,
        (item) => item.sectorId === selected.sectorId
      );
    }
  }

  public isVisible(item: SectorWithCountAndCanonicalString): boolean {
    return !item.parentSector || this.openChildOptions[item.parentSector];
  }

  public toggleOpenChildOptions(sectorName: string): void {
    this.openChildOptions[sectorName] = !this.openChildOptions[sectorName];
  }

  public updateSelection(item: T): void {
    const selected = this.findSelected(item);

    if (selected == null) {
      this.selectItem(item);
    } else {
      this.deselectItem(selected);
    }
    this.cascadeSelect({
      selectedItems: this._selectedItems,
      select: selected ? null : item,
      deselect: selected,
    });
    this.handleSelectionUpdate.emit(this._selectedItems);
  }

  // private methods
  private cascadeSelect(selectionEventData: SelectionEventData): void {
    const toSelect: SectorWithCountAndCanonicalString =
      selectionEventData.select;
    const toDeselect: SectorWithCountAndCanonicalString =
      selectionEventData.deselect;

    if (toSelect) {
      if (toSelect.subSectors) {
        // Selecting Parent Sector should select all child Sub-Sectors
        toSelect.subSectors.forEach((subsector) => {
          this.selectItem(this.findItem(subsector, toSelect.sector));
        });
      } else {
        // Selecting last remaining unselected child Sub-Sectors should select parent Sector
        const parentSector = this.findItem(toSelect.parentSector);
        if (parentSector) {
          if (
            _.every(parentSector.subSectors, (item) => this.isSelected(item))
          ) {
            this.selectItem(parentSector);
          }
        }
      }
    }

    if (toDeselect) {
      if (toDeselect.subSectors) {
        // Deselecting Parent Sector should deselect all child Sub-Sectors
        toDeselect.subSectors.forEach((subsector) => {
          this.deselectItem(this.findItem(subsector, toDeselect.sector));
        });
      } else {
        // Deselecting Child Sub-Sector should deselect Parent Sector if selected
        this.deselectItem(this.findItem(toDeselect.parentSector));
      }
    }
  }

  private findItem(item: string, itemParent?: string) {
    return this.optionData.find(
      (swc) =>
        compareItemsWithCanonicalString(swc, item) &&
        compareItemsWithCanonicalString(swc.parentSector, itemParent)
    );
  }
}
