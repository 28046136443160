import { Injectable } from '@angular/core';

import { AuthnStrings } from '../../constants/AuthnStrings';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { VisualizationType } from '../../constants/VisualizationType';

export const NewConferenceDealOrFundingPrefix = 'navtemp-';

@Injectable()
export class ApplicationContext {
  // private properties
  private _activeVisualizationType: VisualizationType;
  private _activeRdStageSector: string;
  private _isReviewModalDismissed: boolean;
  private _lastViewedMyUpdatesTimestamp: Date;
  private _unseenEventCounts: number[];

  public constructor(private _deploymentContext: DeploymentContext) {}

  // public getters/setters
  public get activeVisualizationType(): VisualizationType {
    return this._activeVisualizationType;
  }

  public set activeVisualizationType(type: VisualizationType) {
    this._activeVisualizationType = type;
  }

  public get activeRdStageSector(): string {
    return this._activeRdStageSector;
  }

  public set activeRdStageSector(selection: string) {
    this._activeRdStageSector = selection;
  }

  public get isReviewModalDismissed(): boolean {
    return this._isReviewModalDismissed;
  }

  public set isReviewModalDismissed(b: boolean) {
    this._isReviewModalDismissed = b;
  }

  public get jnjEverLoggedIn(): boolean {
    return (
      JSON.parse(localStorage?.getItem(AuthnStrings.KEY_JNJ_EVER_LOGGED_IN)) ===
      true
    );
  }

  public get jnjWasLoggedIn(): boolean {
    return (
      JSON.parse(localStorage?.getItem(AuthnStrings.KEY_JNJ_WAS_LOGGED_IN)) ===
      true
    );
  }

  public get jnjLoggedOut(): boolean {
    return (
      JSON.parse(localStorage?.getItem(AuthnStrings.KEY_JNJ_LOGGED_OUT)) ===
      true
    );
  }

  public get lastViewedMyUpdatesTimestamp(): Date {
    return (
      this._lastViewedMyUpdatesTimestamp ||
      this._deploymentContext.lastViewedMyUpdatesTimestampDefault
    );
  }

  public set lastViewedMyUpdatesTimestamp(d: Date) {
    this._lastViewedMyUpdatesTimestamp = d;
  }

  public get unseenEventCounts(): number[] {
    return this._unseenEventCounts;
  }

  public set unseenEventCounts(counts: number[]) {
    this._unseenEventCounts = counts;
  }

  // FUTURE: There is likely a better place for this method
  public saveDocument(
    fileName: string,
    data: ArrayBuffer,
    ext: string,
    append?: string | undefined,
    timestampOverride?: number
  ): void {
    fileName = this._deploymentContext.documentGenerationConfig
      .timestampFilenames
      ? `${fileName} ${timestampOverride || Date.now()}`
      : fileName;

    if (append) {
      fileName = fileName.concat(append);
    }

    const blob = new Blob([data], { type: 'octet/stream' });
    const filename = `${this.validateFilename(fileName)}.${ext}`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  // private methods
  private validateFilename(str: string): string {
    // Replace spaces with underscores, and any other illegal filename characters with hyphens.
    // Informed by https://stackoverflow.com/questions/42210199/remove-illegal-characters-from-a-file-name-but-leave-spaces
    // and https://en.wikipedia.org/wiki/Filename#Reserved_characters_and_words
    return str.replace(/ /g, '_').replace(/[/\\?%*:|"<>]/g, '-');
  }
}
