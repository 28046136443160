import { Component, OnInit } from '@angular/core';

// Component imports
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';

// vendor imports

// service/utility imports
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import {
  hasMilestonesOfType,
  milestoneLayoutInfoArray,
  milestoneSuffixKeys,
  MilestoneType,
  MilestoneValue,
} from '../../../../_common/utilities/blue-knight/blue-knight.util';
import { FundingCurrency } from 'company-finder-common';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'blue-knight-milestones',
  templateUrl: './blue-knight-milestones.component.html',
  styleUrls: ['./blue-knight-milestones.component.scss'],
})
export class BlueKnightMilestonesComponent
  extends UpdateComponentBaseWithEditItems
  implements OnInit
{
  // public properties
  // Clone, because we initialize the properties, and don't want others to overwrite or add to our collection.
  public milestoneLayoutInfos;
  public MilestoneValues = MilestoneValue;
  public showConferenceModal = false;

  public constructor(
    dc: DeploymentContext,
    _companyService: CompanyService,
    _companyUpdateService: CompanyUpdateService
  ) {
    super(dc, _companyUpdateService, _companyService);
    this.milestoneLayoutInfos =
      this._deploymentContext.getMilestoneLayoutInfoArrayCopy(
        milestoneLayoutInfoArray
      );
  }

  public get currencyTypes(): FundingCurrency[] {
    return this._deploymentContext.referenceValueData.fundingCurrencyList.map(
      (item) => item
    );
  }

  public hasMilestonesOfType(milestoneType: MilestoneType): boolean {
    return hasMilestonesOfType(this.milestoneLayoutInfos, milestoneType);
  }

  public synchronizeMilestoneLayoutInfo(): void {
    this.milestoneLayoutInfos.forEach((milestoneLayoutInfo) => {
      milestoneSuffixKeys.forEach((suffixKey, index) => {
        const suffix = this.Loc(suffixKey);
        const milestoneValue = MilestoneValue[MilestoneValue[index * 12 + 12]];
        const propName = this.getMilestonePropertyName(
          milestoneLayoutInfo.propertyRoot,
          suffix
        );
        const shouldShow = this.displayMilestone(
          milestoneValue,
          propName,
          this.pendingUpdate
        );
        const foundPropertyIndex = milestoneLayoutInfo.properties.findIndex(
          (mp) => mp.milestoneValue === milestoneValue
        );
        if (shouldShow) {
          if (foundPropertyIndex < 0) {
            milestoneLayoutInfo.properties.push({
              milestoneValue: milestoneValue,
              label: suffix,
              value: this.company[propName],
              propertyRoot: milestoneLayoutInfo.propertyRoot,
            });

            // Make sure they present in chronological order
            milestoneLayoutInfo.properties.sort(
              (mp1, mp2) => mp1.milestoneValue - mp2.milestoneValue
            );
          }
        } else if (foundPropertyIndex >= 0) {
          milestoneLayoutInfo.properties.splice(foundPropertyIndex, 1);
        }
      });
    });
  }

  public ngOnInit(): void {
    this.synchronizeMilestoneLayoutInfo();
  }
}
