import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import _ from 'lodash';
import { ComponentBase } from '../../../../../_common/components/_component.base';

@Component({
  template: '',
})
export class SavedSearchCriteriaBaseComponent<T>
  extends ComponentBase
  implements OnChanges
{
  @Input() compareFunc: (item1: any, item2: any, property: string) => boolean;
  @Input() compareProperty: string;
  @Input() criteria: T[];
  @Input() optionData: any;

  @Output() handleSelectionUpdate = new EventEmitter<T[]>();

  protected _selectedItems: T[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.criteria) {
      if (this.compareFunc) {
        this._selectedItems = [];
        if (changes.criteria.currentValue) {
          changes.criteria.currentValue.forEach((item) =>
            this.selectItem(item)
          );
        }
      } else {
        this._selectedItems = [];
      }
    }
  }

  public findSelected(item: T): T {
    return this._selectedItems.find((anItem) => {
      if (!anItem) {
        return !item;
      }
      if (!item) {
        return false;
      }
      return this.compareFunc(anItem, item, this.compareProperty);
    });
  }

  public isSelected(item: T): boolean {
    return this.findSelected(item) != null;
  }

  public selectItem(item: T): void {
    if (!this.isSelected(item)) {
      this._selectedItems.push(item);
    }
  }

  public deselectItem(selected: T): void {
    if (selected) {
      _.pull(this._selectedItems, selected);
    }
  }
}
