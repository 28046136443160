// Review interfaces
export interface ReviewableEntity {
  updateFields: ReviewableUpdateField[];
}
export interface ReviewableUpdate {
  updateFields: ReviewableUpdateField[];
  conferenceUpdates: ReviewableEntity[];
  dealUpdates: ReviewableEntity[];
  fundingUpdates: ReviewableEntity[];
}
export interface ReviewableUpdateField {
  displayName: string;
  isSet: boolean;
  newValue: string;
  oldValue: string;
}
interface ReviewedItem {
  isApproved: boolean;
}
export interface ReviewedProperty extends ReviewedItem {
  name: string;
}
export interface ReviewedModel extends ReviewedItem {
  modelId: string;
}
export enum ReviewConfirmationStatus {
  None,
  All,
  Partial,
}
// Submission interfaces
export interface SimpleUpdate {
  content: string;
  displayName: string;
  isLogo?: boolean;
  propertyName: string;
  title?: string;
}
export interface DiversityUpdate extends SimpleUpdate {
  booleanValue: boolean;
}
export interface AddDeleteUpdate {
  added?: string[];
  deleted?: string[];
  displayName: string;
  propertyName: string;
}
export interface AddDeleteEditUpdate extends AddDeleteUpdate {
  edited?: EditedContent[];
  modelId: string;
}
export interface EditedContent {
  title: string;
  updates: SimpleUpdate[];
}
export interface SubmittedUpdate {
  // Ordinary properties + logo
  simpleUpdates: SimpleUpdate[];
  // Community & Diversity properties
  diversityUpdates: DiversityUpdate[];
  // Tag Updates
  addDeleteUpdates: AddDeleteUpdate[];
  // Deals & Funding Updates
  addDeleteEditUpdates: AddDeleteEditUpdate[];
}
// SelfUpdate modes
export enum SelfUpdateMode {
  Edit = 'edit',
  Review = 'review',
}
// Edit items
export enum EditItemType {
  Default,
  Logo,
  MultiBoolean,
  MultiChoice,
  Multiline,
  ConstrainedValue,
  Numeric,
  Tags,
}
export interface EditItemChoice {
  label: string;
  value: string;
}
export interface EditItemMultiBool {
  label: string;
  propertyName: string;
  tooltip: string;
}
// Tag Reviews
export enum TagReviewModificationType {
  Exclude,
  Preserve,
}
export interface TagReviewModification {
  tag: string;
  modificationType: TagReviewModificationType;
  value: boolean;
}
// MultiChoice
export interface MultiChoiceItemChange {
  isAdded: boolean;
  propertyName: string;
  value: string;
}
// Grouped properties
export interface GroupedProperty {
  groupName: string;
  propertyNames: string[];
}
// FUTURE: Move this to config somewhere?
export const groupedProperties = [
  {
    groupName: 'firstTimeEntrepreneur',
    propertyNames: ['firstTimeEntrepreneur'],
  },
];
