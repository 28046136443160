import _ from 'lodash';
import { Summary } from '../summary/summary';
import { SectorWithCountAndCanonicalString } from 'company-finder-common';

export const NewSectorItem = (
  summary: Summary,
  sector: string,
  parentSector: string,
  subSectors: string[]
): SectorWithCountAndCanonicalString => ({
  sector: sector,
  parentSector: parentSector,
  subSectors: subSectors,
  count: summary.companyCountBySector(sector),
  canonicalString: sector.toLowerCase(),
});

export function AllSectorsWithCounts(
  summary: Summary
): SectorWithCountAndCanonicalString[] {
  return _.flatMap(summary.sectors, (sector) => {
    const subSectors = summary.subSectorsBySector(sector);
    const sectors = [NewSectorItem(summary, sector, null, subSectors)];
    subSectors.forEach((subSector) =>
      sectors.push(NewSectorItem(summary, subSector, sector, null))
    );
    return sectors;
  });
}
