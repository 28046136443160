import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ApplicationContext } from '../../../../_common/utilities/application-context/application-context';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { ReviewEditsService } from '../../services/review-edits.service';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';
import { NotificationsComponent } from '../notifications/notifications.component';
import {
  CompanyUpdatePropertyRelationship,
  Conference,
  CountryForDeiReporting,
  Deal,
  DeiConfig,
  DiversityOption,
  Funding,
  getRelationshipByPropertyName,
  ICompanyUpdate,
  IDealUpdate,
  IFundingUpdate,
  IModelEntity,
  LocalizedTextIds,
} from 'company-finder-common';
import _ from 'lodash';
import { DealModalComponent } from '../deal-modal/deal-modal.component';
import { FundingModalComponent } from '../funding-modal/funding-modal.component';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import { ApproveDeclineComponent } from '../approve-decline/approve-decline.component';
import {
  EditItemChoice,
  MultiChoiceItemChange,
} from '../../company-update.interface';
import { EditItemComponent } from '../edit-item/edit-item.component';
@Component({
  selector: 'jnj-information',
  templateUrl: './jnj-information.component.html',
  styleUrls: ['./jnj-information.component.scss'],
})
export class JnJInformationComponent
  extends UpdateComponentBaseWithEditItems
  implements OnDestroy, OnInit
{
  // I'd really rather have something cleaner than passing in the component,
  // but I also don't want this current refactor to grow bigger than it
  // already needs to be. I'm adding a backlog story to revisit this, and I'll
  // add the link to it here once it exists (not doing it now in case I change my approach)
  @Input()
  public notificationsComponent: NotificationsComponent;

  @ViewChild('countryForDeiReportingEditItem', { static: true })
  public countryForDeiReportingEditItem: EditItemComponent;
  @ViewChild('leadershipDiversityEditItem', { static: true })
  public leadershipDiversityEditItem: EditItemComponent;
  @ViewChild('boardAdvisorDiversityEditItem', { static: true })
  public boardAdvisorDiversityEditItem: EditItemComponent;
  @ViewChild('fundingModalComponent', { static: true })
  public fundingModalComponent: FundingModalComponent;
  @ViewChild('dealModalComponent', { static: true })
  public dealModalComponent: DealModalComponent;
  public firstTimeEntrepreneurEditItemData = [
    {
      label: this.Loc(LocalizedTextIds.JnjInformationFirstTime),
      propertyName: 'firstTimeEntrepreneur',
    },
  ];
  public deiCountriesAndOptions: DeiConfig[];

  @ViewChildren(ApproveDeclineComponent)
  public approveDeclineComponents: QueryList<ApproveDeclineComponent>;

  public companyUpdatePropertyRelationships: CompanyUpdatePropertyRelationship[];

  public showFundingModal = false;
  public showDealModal = false;

  public get dealToModify(): Deal {
    return this._companyUpdateService.dealToModify;
  }

  public set dealToModify(value: Deal) {
    this._companyUpdateService.dealToModify = value;
  }

  public get fundingToModify(): Funding {
    return this._companyUpdateService.fundingToModify;
  }

  public set fundingToModify(value: Funding) {
    this._companyUpdateService.fundingToModify = value;
  }

  // Local getters to prevent having lots of code like
  // this._companyUpdateService.getCurrentUpdate().dealUpdate
  // which get a bit unweildy to read
  private get currentUpdate(): ICompanyUpdate {
    return this._companyUpdateService.getCurrentUpdate();
  }

  private get dealUpdates(): IDealUpdate[] {
    return this.currentUpdate.dealUpdates;
  }

  private get fundingUpdates(): IFundingUpdate[] {
    return this.currentUpdate.fundingUpdates;
  }

  public get haveFundingsChanged(): boolean {
    return this._companyUpdateService.haveFundingsChanged;
  }

  public set haveFundingsChanged(value: boolean) {
    this._companyUpdateService.haveFundingsChanged = value;
  }

  public get womenLeadershipOptionList(): { label: string; value: string }[] {
    return [
      { label: '', value: '' },
      { label: this.Loc(LocalizedTextIds.Yes), value: 'Yes' },
      { label: this.Loc(LocalizedTextIds.No), value: 'No' },
    ];
  }

  public get countryForDeiReporting(): string {
    const formControl = this.companyEditForm.get('countryForDeiReporting');
    return formControl?.value;
  }

  public get countryForDeiReportingList(): CountryForDeiReporting[] {
    return this._deploymentContext.referenceValueData.countryForDeiReportingList.map(
      (item) => item
    );
  }

  public get enableDiversityQuestions(): boolean {
    return this._deploymentContext.featureSwitches.enableDiversityQuestions;
  }

  public get leadershipDiversity(): string {
    const formControl = this.companyEditForm.get('leadershipDiversity');
    return formControl?.value;
  }

  public set leadershipDiversity(value: string) {
    const formControl = this.companyEditForm.get('leadershipDiversity');
    formControl?.setValue(value);
  }

  public get boardAdvisorDiversity(): string {
    const formControl = this.companyEditForm.get('boardAdvisorDiversity');
    return formControl?.value;
  }

  public set boardAdvisorDiversity(value: string) {
    const formControl = this.companyEditForm.get('boardAdvisorDiversity');
    formControl?.setValue(value);
  }

  public get deiLeadershipDiversityChoices(): EditItemChoice[] {
    return this.getDeiDiversityChoices(false);
  }

  public get deiBoardAdvisorDiversityChoices(): EditItemChoice[] {
    return this.getDeiDiversityChoices(true);
  }

  private getDeiDiversityChoices(forBoardAdvisor: boolean): EditItemChoice[] {
    const options = this.getDiversityOptionsForCountry(
      this.countryForDeiReporting
    );

    if (!options) {
      return;
    }

    // This set will be treated like EditItemChoice objects and hence we need
    // copies to avoid unexpected shared state.
    const choices = _.clone(options);

    // Exclude choices that are specific to Board of Advisor, unless this is for the board question
    return choices.filter(
      (choice) => !choice.isOnlyForBoardAdvisor || forBoardAdvisor
    );
  }

  // public methods
  public getRelatedPropertyNamesFor(propertyName: string): string[] {
    const relationship = getRelationshipByPropertyName(
      this.companyUpdatePropertyRelationships,
      propertyName
    );
    const relatedProperties = [
      relationship.key,
      ...relationship.relatedProperties,
    ];
    _.remove(relatedProperties, (p) => p === propertyName);
    return relatedProperties;
  }

  public isNewConferenceDealOrFunding(
    obj: Conference | Deal | Funding,
    typeId: string
  ): boolean {
    return this._companyUpdateService.isNewConferenceDealOrFunding(obj, typeId);
  }

  public isAdded(obj: Deal | Funding, typeId: string): boolean {
    return (
      this._companyUpdateService.isNewConferenceDealOrFunding(obj, typeId) &&
      (!this.isCurrentUpdateApproved() ||
        !this.isDealOrFundingFromPriorUpdate(obj, typeId))
    );
  }

  public isApproved(obj: Deal | Funding, typeId: string): boolean {
    return (
      this._companyUpdateService.isNewConferenceDealOrFunding(obj, typeId) &&
      this.isCurrentUpdateApproved() &&
      this.isDealOrFundingFromPriorUpdate(obj, typeId)
    );
  }

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _reviewEditsService: ReviewEditsService,
    _companyService: CompanyService,
    _companyUpdateService: CompanyUpdateService
  ) {
    super(dc, _companyUpdateService, _companyService);
  }

  public async ngOnInit(): Promise<void> {
    this.companyUpdatePropertyRelationships =
      this._deploymentContext.companyUpdatePropertyRelationships;
    this.deiCountriesAndOptions =
      this._deploymentContext.referenceValueData.deiCountriesAndOptionsList;
    this.setAllSubscriptions();
    return;
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
    }
  }

  public parseMultipleChoices(
    separatedValueString: string,
    separator = ';'
  ): string[] {
    if (!separatedValueString) {
      return [];
    }
    const separatedValues = separatedValueString.split(separator);
    const unprefixedValues = separatedValues.map((value) => {
      const key = ' - ';
      const index = value.indexOf(key);
      if (index >= 0) {
        return value.slice(index + key.length);
      } else {
        return value;
      }
    });
    // Make sure they are sorted, so the diffs can reliably match sets with the same options.
    return unprefixedValues.sort();
  }

  public showFootnote(countryToFootnote: string): boolean {
    return this.countryForDeiReporting === countryToFootnote;
  }

  public setAllSubscriptions(): void {
    this.setEditItemDependencySubscriptions();
    this.setReviewEditsSubscriptions();
    this.setFundingSubscriptions();
    this.setDealSubscriptions();
  }

  public setEditItemDependencySubscriptions(): void {
    if (
      !this.countryForDeiReportingEditItem ||
      !this.leadershipDiversityEditItem ||
      !this.boardAdvisorDiversityEditItem
    ) {
      this._logger.info(`No DEI EditItems`);
      return;
    }

    this.addSubscription(
      this.countryForDeiReportingEditItem.constrainedValueChangeSubject.subscribe(
        async () => {
          this.leadershipDiversity = '';
          this.boardAdvisorDiversity = '';
        }
      )
    );
    this.addSubscription(
      this.leadershipDiversityEditItem.multiChoiceValueChangedSubject.subscribe(
        async (data: MultiChoiceItemChange) => {
          const selections = this.parseMultipleChoices(
            this.leadershipDiversity
          );
          if (data.isAdded) {
            // Handle mutually exclusive selections
            const mutuallyExclusiveOptions = this.getMutuallyExclusiveOptions(
              this.countryForDeiReporting
            );
            const isMutuallyExclusiveOption = mutuallyExclusiveOptions?.find(
              (meo) => meo.value === data.value
            );
            if (isMutuallyExclusiveOption) {
              selections.splice(0, selections.length);
            } else {
              mutuallyExclusiveOptions?.forEach((meo) =>
                _.remove(selections, (s) => s === meo.value)
              );
            }

            selections.push(data.value);
          } else {
            _.remove(selections, (option) => option === data.value);
          }
          const prefixValues = this.applyDeiCountryPrefixes(selections);
          this.leadershipDiversity = prefixValues;
        }
      )
    );
    this.addSubscription(
      this.boardAdvisorDiversityEditItem.multiChoiceValueChangedSubject.subscribe(
        async (data: MultiChoiceItemChange) => {
          const selections = this.parseMultipleChoices(
            this.boardAdvisorDiversity
          );
          if (data.isAdded) {
            // Handle mutually exclusive selections
            const mutuallyExclusiveOptions = this.getMutuallyExclusiveOptions(
              this.countryForDeiReporting
            );
            const isMutuallyExclusiveOption = mutuallyExclusiveOptions?.find(
              (meo) => meo.value === data.value
            );
            if (isMutuallyExclusiveOption) {
              selections.splice(0, selections.length);
            } else {
              mutuallyExclusiveOptions?.forEach((meo) =>
                _.remove(selections, (s) => s === meo.value)
              );
            }

            selections.push(data.value);
          } else {
            _.remove(selections, (option) => option === data.value);
          }
          const prefixValues = this.applyDeiCountryPrefixes(selections);
          this.boardAdvisorDiversity = prefixValues;
        }
      )
    );
  }

  public setReviewEditsSubscriptions(): void {
    if (!this._reviewEditsService) {
      this._logger.info(`No _reviewEditsService`);
      return;
    }

    this.addSubscription(
      this._reviewEditsService.showReviewModalSubject.subscribe(
        (item: unknown) => {
          this.showDealOrFundingFromReview(item);
        }
      )
    );
  }

  public setFundingSubscriptions(): void {
    if (!this.fundingModalComponent) {
      this._logger.info(`No fundingModalComponent`);
      return;
    }

    this.addSubscription(
      this.fundingModalComponent.closingSubject.subscribe(
        async (result: { funding: Funding; isSave: boolean }) => {
          await this.closeFunding(result);
        }
      )
    );

    this.addSubscription(
      this.fundingModalComponent.approveDeclineSubject.subscribe(
        (result: { funding: Funding; isApproved: boolean }) => {
          this.showFundingModal = false;

          const approveDeclineComponent = this.approveDeclineComponents.find(
            (comp) =>
              comp.funding &&
              comp.funding.fundingId === result.funding.fundingId
          );
          if (approveDeclineComponent) {
            if (result.isApproved) {
              approveDeclineComponent.approveFunding(result.funding);
            } else {
              approveDeclineComponent.declineFunding(result.funding);
            }
          }
        }
      )
    );
  }

  public setDealSubscriptions(): void {
    if (!this.dealModalComponent) {
      this._logger.info(`No dealModalComponent`);
      return;
    }

    this.addSubscription(
      this.dealModalComponent.closingSubject.subscribe(
        async (result: { deal: Deal; isSave: boolean }) => {
          await this.closeDeal(result);
        }
      )
    );
    this.addSubscription(
      this.dealModalComponent.approveDeclineSubject.subscribe(
        (result: { deal: Deal; isApproved: boolean }) => {
          this.showDealModal = false;

          const approveDeclineComponent = this.approveDeclineComponents.find(
            (comp) => comp.deal && comp.deal.dealId === result.deal.dealId
          );
          if (approveDeclineComponent) {
            if (result.isApproved) {
              approveDeclineComponent.approveDeal(result.deal);
            } else {
              approveDeclineComponent.declineDeal(result.deal);
            }
          }
        }
      )
    );
  }

  private applyDeiCountryPrefixes(values: string[]): string {
    const countryForDeiReportingControl = this.companyEditForm.get(
      'countryForDeiReporting'
    );
    const countryForDeiReporting = countryForDeiReportingControl?.value;
    const deiCountriesAndOptions =
      this._deploymentContext.referenceValueData.deiCountriesAndOptionsList;
    const distinguishedDeiCountry = deiCountriesAndOptions.find(
      (item) => item.label === countryForDeiReporting
    );
    const prefix = distinguishedDeiCountry
      ? distinguishedDeiCountry.value
      : this.Loc(LocalizedTextIds.JnjInformationOtherCountry);
    values = values.map((value) =>
      this.useCountryPrefixForValue(value, countryForDeiReporting)
        ? `${prefix} - ${value}`
        : value
    );
    // Make sure they are sorted, so the diffs can reliably match sets with the same options.
    return values.sort().join(';');
  }

  private useCountryPrefixForValue(
    value: string,
    countryForDeiReporting: string
  ): boolean {
    const options = this.getDiversityOptionsForCountry(countryForDeiReporting);
    const option = options?.find((o) => o.value === value);
    return !option?.omitCountryPrefix;
  }

  private getMutuallyExclusiveOptions(
    countryForDeiReporting: string
  ): DiversityOption[] {
    const options = this.getDiversityOptionsForCountry(countryForDeiReporting);
    return options?.filter((o) => o.isMutuallyExclusive);
  }

  private getDiversityOptionsForCountry(
    countryForDeiReporting: string
  ): DiversityOption[] {
    if (!countryForDeiReporting) {
      return undefined;
    }

    let deiConfig = this.deiCountriesAndOptions.find(
      (item) => item.label === countryForDeiReporting
    );

    if (!deiConfig) {
      // Couldn't find a set for the specific country, so fall back on the "Other Country" set
      deiConfig = this.deiCountriesAndOptions.find(
        (item) => item.label === this.OTHER_COUNTRY_STRING // See comment below regarding OTHER_COUNTRY_STRING
      );
    }

    // Get the options that should be included for all countries
    const allCountriesDeiConfig = this.deiCountriesAndOptions.find(
      (item) => item.label === this.ALL_COUNTRIES_STRING // See comment below regarding ALL_COUNTRIES_STRING
    );

    // Return the combination of the two sets
    return deiConfig?.diversityOptions.concat(
      allCountriesDeiConfig?.diversityOptions
    );
  }

  // Note: These values need to be kept in sync with the configuration data. If it changes,
  // for example, to just 'Other' then our code breaks. We could, instead, rely on
  // assumed positioning (second to last and last), but there's no simple way to add
  // a comment to the JSON file reminding maintainers to honor that contract.
  private OTHER_COUNTRY_STRING = 'Other Country';
  private ALL_COUNTRIES_STRING = 'All Countries';

  private showDealOrFundingFromReview(item: {
    dealId?: string;
    fundingId?: string;
  }): void {
    if (item.dealId) {
      this.openDeal(item as Deal);
    }
    if (item.fundingId) {
      this.openFunding(item as Funding);
    }
  }

  private async closeDeal(result: {
    deal: Deal;
    isSave: boolean;
  }): Promise<void> {
    this.showDealModal = false;
    // We're not saving, so undo any changes to the deals
    if (!result.isSave && result.deal.dealId) {
      const originalDeal = this.companyWithMostRecentEdit.deals.find(
        (d) => d.dealId === result.deal.dealId
      );
      // Existing deals would be in the companyClone, new ones in the user's current session would not.
      const dealToRestore = originalDeal
        ? _.cloneDeep(originalDeal)
        : result.deal;
      const index = this.company.deals.findIndex(
        (d) => d.dealId === result.deal.dealId
      );
      this.company.deals.splice(index, 1, dealToRestore);
    }

    if (!result.isSave) {
      return;
    }

    if (
      this._companyUpdateService.isNewConferenceDealOrFunding(
        result.deal,
        'dealId'
      )
    ) {
      const index = this.company.deals.findIndex(
        (d) => d.dealId === result.deal.dealId
      );
      if (!result.deal.isDeleted) {
        // Don't double-save
        if (index < 0) {
          this.company.deals.push(result.deal);
        }
      } else {
        // They deleted a deal they just tried to add, so remove it from the company.
        this.company.deals.splice(index, 1);
        if (this.pendingUpdate) {
          // Clean up the DB
          const updateType = 'deal';
          const deleteCompanyUpdate =
            this._companyUpdateService.wouldBeEmptyCompanyUpdate(
              this.pendingUpdate
            );
          await this._companyService.deletePendingAddedDealOrFundingUpdateByModelId(
            this.company.opportunityIdPrimary,
            updateType,
            result.deal.dealId,
            deleteCompanyUpdate
          );
          // Apply the pending update, assuming there is one.
          // If there isn't, i.e., deleteCompanyUpdate is true, applySavedOrPendingUpdateData()
          // does little more than retrieve a null company update, thus setting pendingUpdate to to null.
          // NOTE: Don't call initializeUpdateData() here because:
          // * We already have any approved but unprocessed update data, and nothing we've done in the modal impacts that stuff.
          // * We want the pending update applied to the company, but don't want to clone the company into the
          //   Pending cache or we'll lose track of what's different.
          await this._companyUpdateService.applySavedOrPendingUpdateData();
          // Make sure we remove the deleted deal from the caches
          _.remove(
            this.companyClone.deals,
            (d) => d.dealId === result.deal.dealId
          );
          _.remove(
            this.companyWithPending.deals,
            (d) => d.dealId === result.deal.dealId
          );
        }
        // The DB is clean, so now make sure the companyWithMostRecentEdit reflects the current state of the company.
        this.companyWithMostRecentEdit =
          this._companyUpdateService.cloneCompany(this.company);
        await this.notificationsComponent.getNotification();
      }
    }

    _.merge(this.companyWithMostRecentEdit.deals, this.company.deals);
    this._companyUpdateService.checkUpdatesForUI(this.currentUpdate);

    this.haveDealsChanged = this.dealUpdates?.length > 0;
  }

  private async closeFunding(result: {
    funding: Funding;
    isSave: boolean;
  }): Promise<void> {
    this.showFundingModal = false;

    // We're not saving, so undo any changes to the funding
    if (!result.isSave && result.funding.fundingId) {
      const originalFunding = this.companyWithMostRecentEdit.funding.find(
        (f) => f.fundingId === result.funding.fundingId
      );
      // Existing fundings would be in the companyClone, new ones in the user's current session would not.
      const fundingToRestore = originalFunding
        ? _.cloneDeep(originalFunding)
        : result.funding;
      const index = this.company.funding.findIndex(
        (f) => f.fundingId === result.funding.fundingId
      );
      this.company.funding.splice(index, 1, fundingToRestore);
    }

    if (!result.isSave) {
      return;
    }

    if (
      this._companyUpdateService.isNewConferenceDealOrFunding(
        result.funding,
        'fundingId'
      )
    ) {
      const index = this.company.funding.findIndex(
        (f) => f.fundingId === result.funding.fundingId
      );
      if (!result.funding.isDeleted) {
        // Don't double-save
        if (index < 0) {
          this.company.funding.push(result.funding);
        }
      } else {
        // They deleted a funding they just tried to add, so remove it from the company.
        this.company.funding.splice(index, 1);
        if (this.pendingUpdate) {
          // Clean up the DB
          const updateType = 'funding';
          const deleteCompanyUpdate =
            this._companyUpdateService.wouldBeEmptyCompanyUpdate(
              this.pendingUpdate
            );
          await this._companyService.deletePendingAddedDealOrFundingUpdateByModelId(
            this.company.opportunityIdPrimary,
            updateType,
            result.funding.fundingId,
            deleteCompanyUpdate
          );
          // Apply the pending update, assuming there is one.
          // If there isn't, i.e., deleteCompanyUpdate is true, applySavedOrPendingUpdateData()
          // does little more than retrieve a null company update, thus setting pendingUpdate to to null.
          // NOTE: Don't call initializeUpdateData() here because:
          // * We already have any approved but unprocessed update data, and nothing we've done in the modal impacts that stuff.
          // * We want the pending update applied to the company, but don't want to clone the company into the
          //   Pending cache or we'll lose track of what's different.
          await this._companyUpdateService.applySavedOrPendingUpdateData();
          // Make sure we remove the deleted deal from the caches
          _.remove(
            this.companyClone.funding,
            (f) => f.fundingId === result.funding.fundingId
          );
          _.remove(
            this.companyWithPending.funding,
            (f) => f.fundingId === result.funding.fundingId
          );
        }
        // The DB is clean, so now make sure the companyWithMostRecentEdit reflects the current state of the company.
        this.companyWithMostRecentEdit =
          this._companyUpdateService.cloneCompany(this.company);
        await this.notificationsComponent.getNotification();
      }
    }

    _.merge(this.companyWithMostRecentEdit.funding, this.company.funding);
    this._companyUpdateService.checkUpdatesForUI(this.currentUpdate);

    this.haveFundingsChanged = this.fundingUpdates?.length > 0;
  }

  public IsNumberOrNumberString(value: unknown): boolean {
    return !isNaN(parseFloat(value as string)) && isFinite(value as number);
  }

  public isCurrentUpdateApproved(): boolean {
    return this._companyUpdateService.isCurrentUpdateApproved(
      this.notificationsComponent
    );
  }

  public isDealOrFundingFromPriorUpdate(
    obj: Deal | Funding,
    typeId: string
  ): boolean {
    const updates: IModelEntity[] =
      typeId === 'dealId' ? this.dealUpdates : this.fundingUpdates;

    return !!_.find(updates, (update) => update.id === obj[typeId]);
  }

  public openFunding(funding?: Funding): void {
    this.fundingToModify = funding ?? new Funding();
    this.showFundingModal = true;
  }

  public openDeal(deal?: Deal): void {
    this.dealToModify = deal ?? new Deal();
    this.showDealModal = true;
  }
}
