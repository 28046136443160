import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DisplayItem } from 'company-finder-common';
import _ from 'lodash';
import { ComponentBase } from '../../../../../_common/components/_component.base';

@Component({
  selector: 'saved-search-criteria',
  templateUrl: './saved-search-criteria.component.html',
  styleUrls: ['./saved-search-criteria.component.scss'],
})
export class SavedSearchCriteriaComponent<T extends DisplayItem>
  extends ComponentBase
  implements OnChanges
{
  // public properties
  public isOpen = false;

  @Input() criteria: T[];
  @Input() selectionType: 'location' | 'sector' | 'status';
  @Input() label: string;
  @Input() optionData: any;
  @Output() criteriaChange = new EventEmitter<T[]>();

  // private properties
  private _selectedItems: T[] = [];

  // public getters
  public get numberSelected(): number {
    return this._selectedItems.length;
  }

  // public methods
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.criteria) {
      if (changes.criteria.currentValue) {
        this._selectedItems = changes.criteria.currentValue;
      } else {
        this._selectedItems = [];
      }
    }
  }

  public compareItemsByProperty(
    item1: unknown | string,
    item2: unknown | string,
    compareProperty: string
  ): boolean {
    if (_.isNil(item1)) {
      return _.isNil(item2);
    }
    if (_.isNil(item2)) {
      return false;
    }

    const comparator1 =
      typeof item1 === 'string' ? item1 : item1[compareProperty];
    const comparator2 =
      typeof item2 === 'string' ? item2 : item2[compareProperty];
    if (!comparator1 || !comparator2) {
      return false;
    }
    return typeof comparator1 === 'string'
      ? comparator1.toLowerCase() === comparator2.toLowerCase()
      : comparator1 === comparator2;
  }

  public handleSelectionUpdate(selectedItems: T[]): void {
    this._selectedItems = selectedItems;
    this.criteriaChange.emit(selectedItems);
  }

  public toggleSelector(): void {
    this.isOpen = !this.isOpen;
  }
}
