import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Input,
  isDevMode,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { Deal, UpdateStatus } from 'company-finder-common';
import {
  ReviewableEntity,
  ReviewableUpdate,
  SelfUpdateMode,
} from '../../company-update.interface';

import { NewConferenceDealOrFundingPrefix } from '../../../../_common/utilities/application-context/application-context';
import {
  isEmptyInputValue,
  MoneyRequiresCurrency,
  RequireMoreThanJustSpaces,
} from '../../../../_common/utilities/forms/form-util';
import { ReviewEditsService } from '../../services/review-edits.service';
import {
  constructDateString,
} from '../../utils';

import { ActionResult } from '../../../../_common/components/action-modal/action-modal.component';
import { CompanyUpdateService } from '../../services/company-update.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { ComponentBase } from '../../../../_common/components/_component.base';

@Component({
  selector: 'deal-modal',
  templateUrl: './deal-modal.component.html',
  styleUrls: ['./deal-modal.component.scss'],
})
export class DealModalComponent
  extends ComponentBase
  implements AfterViewChecked, OnDestroy
{
  // public properties
  public dealForm: FormGroup;
  public dealFormSubscription: Subscription;
  public amountControlSubscription: Subscription;
  public contingentAmountControlSubscription: Subscription;
  public get deal(): Deal {
    return this._companyUpdateService.dealToModify;
  }

  public set deal(value: Deal) {
    this._companyUpdateService.dealToModify = value;
  }

  public isShowingTooltip = {
    type: false,
    date: false,
    secured: false,
    contingent: false,
  };
  public closingSubject: Subject<{ deal: Deal; isSave: boolean }> =
    new Subject<{ deal: Deal; isSave: boolean }>();
  public approveDeclineSubject: Subject<{ deal: Deal; isApproved: boolean }> =
    new Subject<{ deal: Deal; isApproved: boolean }>();

  public get selfUpdateMode(): SelfUpdateMode {
    return this._companyUpdateService.selfUpdateMode;
  }
  public SelfUpdateModes = SelfUpdateMode;
  public modalHeightPercent: number;
  public modalMaxHeight: number;

  // private properties
  private _amountPattern = '^[0-9]+(.[0-9]{2})?$';
  private _originalDealDateString: string;
  private _dealToRestore: Deal;
  private _previousDateEntry: Date;
  private _showModal: boolean;

  constructor(
    deploymentContext: DeploymentContext,
    private _datePipe: DatePipe,
    private _cdRef: ChangeDetectorRef,
    private _reviewEditsService: ReviewEditsService,
    private _companyUpdateService: CompanyUpdateService
  ) {
    super(deploymentContext);
  }

  // public getters/setters
  public get dealUpdateStatus(): UpdateStatus {
    return this._reviewEditsService.getModelStatus(this.deal.dealId);
  }

  public get isSaveEnabled(): boolean {
    if (!this.dealForm) {
      return false;
    }
    return this.dealForm.dirty && this.dealForm.valid;
  }

  public get showModal(): boolean {
    return this._showModal;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('showModal')
  public set showModal(value: boolean) {
    this._showModal = value;

    if (!this.showModal && this.dealForm) {
      return;
    }

    this._dealToRestore = _.cloneDeep(this.deal);
    this._originalDealDateString = this._datePipe.transform(
      this._dealToRestore.announcementDate,
      'yyyy-MM-dd'
    );
    this.initializeDealForm();
    if (this.deal.isDeleted) {
      this.dealForm.disable();
    } else {
      this.dealForm.enable();
    }
    this.modalHeightPercent =
      this.isApprovedDeal && !this.deal.isJnjDeal ? 90 : 85;
    if (this.isApprovedDeal) {
      this.modalMaxHeight = this.deal.isJnjDeal ? 713 : 745;
    } else {
      this.modalMaxHeight = 710;
    }
  }

  public get updateForReview(): ReviewableUpdate {
    return this._companyUpdateService.updateForReview;
  }

  public set updateForReview(update: ReviewableUpdate) {
    this._companyUpdateService.updateForReview = update;
  }

  public get dealUpdateForReview(): ReviewableEntity {
    return this.updateForReview && this.updateForReview.dealUpdates
      ? this.updateForReview.dealUpdates[this.deal.dealId]
      : undefined;
  }

  public isApprovedDeal(): boolean {
    return (
      this.deal.dealId &&
      !this.deal.dealId.startsWith(NewConferenceDealOrFundingPrefix)
    );
  }

  public get dealTypes(): string[] {
    return this._deploymentContext.referenceValueData.dealTypeList.map(
      (item) => item.value
    );
  }

  public get currencyTypes(): string[] {
    return this._deploymentContext.referenceValueData.securedFundingCurrencyList.map(
      (item) => item.value
    );
  }

  public getReferenceValueDisplayString(value: string): string {
    return value;
  }

  // public methods
  public hasPropertyBeenModified(propertyName: string): boolean {
    return (
      this.dealUpdateForReview &&
      this.dealUpdateForReview.updateFields[propertyName] &&
      this.dealUpdateForReview.updateFields[propertyName].isSet
    );
  }

  public showTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = true;
  }

  public hideTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = false;
  }

  public initializeDealForm(): void {
    this.dealFormSubscription?.unsubscribe();
    this.amountControlSubscription?.unsubscribe()
    this.contingentAmountControlSubscription?.unsubscribe();
    
    const announcementDateValidators = [Validators.required];
    this.dealForm = new FormGroup(
      {
        isJnjDeal: new FormControl(this.deal.isJnjDeal || 0),
        dealType: new FormControl(this.deal.dealType, Validators.required),
        dealParty: new FormControl(
          {
            value: this.deal.dealParty,
            disabled: this.deal.isJnjDeal,
          },
          RequireMoreThanJustSpaces.required
        ),
        amount: new FormControl(
          this.deal.amount,
          Validators.pattern(this._amountPattern)
        ),
        contingentAmount: new FormControl(
          this.deal.contingentAmount,
          Validators.pattern(this._amountPattern)
        ),
        amountCurrency: new FormControl(this.deal.amountCurrency),
        announcementDate: new FormControl(
          this._datePipe.transform(this.deal.announcementDate, 'yyyy-MM-dd', 'UTC'),
          announcementDateValidators
        ),
        comments: new FormControl(this.deal.comments),
        isConfidential: new FormControl(this.deal.isConfidential || 0),
      },
      MoneyRequiresCurrency.required(
        {
          valueFields: ['amount', 'contingentAmount'],
          currencyField: 'amountCurrency',
        },
        this.deal.isJnjDeal
      )
    );

    // For JnJ Deals, we hide the amount & currency fields in the view
    //  and in some cases the amount may be "N/A" which violates the pattern validator.
    //  Since we are hiding these fields in the UI, we should just remove any validators on them.
    if (this.deal.isJnjDeal) {
      this.dealForm.controls['amount'].validator = null;
      this.dealForm.controls['contingentAmount'].validator = null;
      this.dealForm.controls['amountCurrency'].validator = null;
    }

    const announcementDateControl = this.dealForm.get('announcementDate');

    this.dealFormSubscription = this.dealForm.valueChanges.subscribe(
      (formValues) => {
        this.deal.isJnjDeal = formValues.isJnjDeal || false;
        this.deal.dealType = formValues.dealType;
        this.deal.dealParty =
          formValues.dealParty && formValues.dealParty.trim();
        this.deal.amount = formValues.amount;
        this.deal.contingentAmount = formValues.contingentAmount;
        this.deal.amountCurrency = formValues.amountCurrency;
        this.deal.announcementDate =
          announcementDateControl.dirty &&
          this._originalDealDateString !== formValues.announcementDate
            ? this.getLocaleTime(formValues.announcementDate)
            : this.deal.announcementDate;
        // The form wants to send back null, which would diff undesirably with an undefined value, so handle that here.
        this.deal.comments = !isEmptyInputValue(formValues.comments)
          ? formValues.comments && formValues.comments.trim()
          : undefined;
        this.deal.isConfidential = formValues.isConfidential || false;
      }
    );
  }

  public ngAfterViewChecked(): void {
    if (isDevMode()) {
      this._cdRef.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    if (this.dealFormSubscription) {
      this.dealFormSubscription.unsubscribe();
    }
    if (this.amountControlSubscription) {
      this.amountControlSubscription.unsubscribe();
    }
    if (this.contingentAmountControlSubscription) {
      this.contingentAmountControlSubscription.unsubscribe();
    }
  }

  public setIsJnj(state: boolean): void {
    this.deal.isJnjDeal = state;
    this.dealForm.controls['isJnjDeal'].setValue(this.deal.isJnjDeal);
    this.dealForm.markAsDirty();
  }

  public handleAction(action: ActionResult): void {
    switch (action) {
      case ActionResult.Approve:
        this.approveDeclineSubject.next({
          deal: this.deal,
          isApproved: true,
        });
        // NOTE: The company-update.component's approveDeclineSubject handler will hide the modal.
        break;
      case ActionResult.Decline:
        this.approveDeclineSubject.next({
          deal: this.deal,
          isApproved: false,
        });
        // NOTE: The company-update.component's approveDeclineSubject handler will hide the modal.
        break;
      case ActionResult.Delete:
        _.merge(this.deal, this._dealToRestore);
        this.deal.isDeleted = true;
        this.close(true);
        break;
      case ActionResult.Restore:
        this.deal.isDeleted = false;
        this.close(true);
        break;
      case ActionResult.Submit:
        this.close(true);
        break;
      case ActionResult.Cancel:
      case ActionResult.Close:
        this.close(false);
        break;
    }
  }

  /**
   * Browsers are handling the date input differently with regard to the yyyy field. Some were failing to handle
   * huge years, e.g., 30002, (ADJQ-936); while Chrome seemed tolerant, Firefox was failing. IE 11 doesn't support the
   * date input at all. Firefox was creating "Invalid Date" objects, on which Angular's date pipe was choking. Adding
   * a max date value to the input field, and handling the ngModelChanges here gives us a chance to reset the date before
   * Angular gags.
   * The behavior still varies a bit. Chrome cycles through 4-digit years, clipping the oldest value.
   *    For example, if the user types 12345 the date becomes 2345
   * Firefox, on the other hand, cycles through 6-digits before resetting and appending the newest digit to 000.
   *    For example, if the user types 1234567 the date becomes 0007
   * in the meantime, the 5- and 6-digit dates are causing "Invalid Dates" in Firefox. For some reason the date is in a
   * state here where we're able to access it, check its year, and adjust on the fly. In Save it's too late, perhaps
   * because we're trying to apply the value to the form. So here we check the date's yyyy value, and if it isNan() reset
   * the date to the most recent valid date the user entered. Note that we never encounter the isNaN() logic in Chrome.
   * The net result of all this is that the user experiences vary depending on the browser they're using, but we're in a
   * more stable situation that should avoid the issues we saw in ADJQ-936.
   */
  public handleDateChange(): void {    
    if (!this.deal.announcementDate) {
      return;
    }
    const yyyy = this.deal.announcementDate.getFullYear();
    if (isNaN(yyyy)) {
      this.deal.announcementDate = this._previousDateEntry;
      if (this._previousDateEntry) {
        const announcementDateControl = this.dealForm.get('announcementDate');
        const dateStr = constructDateString(this.deal.announcementDate);
        announcementDateControl.setValue(dateStr);
      }
    }
    this._previousDateEntry = this.deal.announcementDate;
  }

  // private methods
  private close(save: boolean) {
    if (this.deal.isJnjDeal) {
      // Clear the amounts
      this.deal.amount = undefined;
      this.deal.contingentAmount = undefined;
    }
    if (save || this.deal.isDeleted) {
      if (!this.deal.dealId) {
        this.deal.dealId = `${NewConferenceDealOrFundingPrefix}${uuidv4()}`;
      }
      this.closingSubject.next({ deal: this.deal, isSave: true });
    } else {
      this.closingSubject.next({
        deal: this._dealToRestore,
        isSave: false,
      });
    }
  }

  private getLocaleTime(dateString: string): Date {
    // FUTURE: Superclass for the deal/funding modals
    const requestedDate = new Date(dateString);
    const timezoneOffsetInMilliseconds =
      requestedDate.getTimezoneOffset() * 60 * 1000;
    return new Date(requestedDate.getTime() + timezoneOffsetInMilliseconds);
  }
}
