import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ComponentBase } from '../../../../../../_common/components/_component.base';
import { GeoVisualizerComponent } from '../geo-visualizer/geo-visualizer.component';
import _ from 'lodash';

// model imports
import { LocPrefix } from 'company-finder-common';

// utility/service imports
import { SearchService } from '../../../../../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../../../../../_common/services/web-analytics/web.analytics';
import { DeploymentContext } from '../../../../../../_common/utilities/deployment-context/deployment-context';
import { TooltipHandler } from '../../models/tooltip-handler';
import { LocationGroup } from '../../../../../../_common/models/location-group';
import { Summary } from '../../../../../../_common/utilities/summary/summary';

export interface Style {
  'font-size'?: string;
  height?: string;
  left?: string;
  top?: string;
  bottom?: string;
  width?: string;
  color?: string;
}

export interface Segment {
  pt1: string;
  pt2: string;
  pt3: string;
  pt4: string;
  color: string;
  largeArc: number;
}

@Component({
  selector: 'location-indicator',
  templateUrl: './location-indicator.component.html',
  styleUrls: ['./location-indicator.component.scss'],
})
export class LocationIndicatorComponent
  extends ComponentBase
  implements OnChanges
{
  // public properties
  @Input()
  public locationMarker: LocationGroup;
  @Input()
  public summary: Summary;
  @Input()
  public invertLabel = false;
  @Input()
  public tooltipHandler: TooltipHandler;

  public totalCount: number;
  public sizeClass: string;
  public ngStyle: Style;
  public labelStyle: Style;
  public fontSize: number;
  public labelSize: string;
  public labelString: string;
  public clipPath: string;
  public isShowingTooltip: boolean;
  public coefficients: number[];
  public radius: number;
  public diameter: number;
  public innerRadius: number;
  public segments: Segment[];
  public textColor: string;
  public viewBox: string;
  public sectors = this._deploymentContext.sectors.map((sector) =>
    this._deploymentContext
      .LocWithPrefix(sector, LocPrefix.SectorShortName)
      .toLocaleUpperCase()
  );
  public colors = this._deploymentContext.themeSettings.sectorColors;
  public background = 'rgb(193, 187, 179)'; // neutral-05
  public opacity = 0.7;

  // private properties
  private spacerAngle = (5 / 360) * 2 * Math.PI;
  private thickness = 3;
  // TODO: Would be nice if these magic constants could be derived from the same source of truth that drives the css
  private tooltipArrowLeftOffset = 66;
  private tooltipArrowBottomOffset = 12;

  // public properties
  public constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
    this.computeSegments();
  }

  // public methods
  public async ngOnChanges(_changes: SimpleChanges): Promise<void> {
    this.computeSegments();
  }

  public async handleClick(): Promise<void> {
    const filter = this._searchService.filter;

    filter.locations = this.locationMarker.map(
      (marker) => marker.location.name
    );

    this._searchService.drilldownSubject.next(filter);

    this._webAnalyticsService.trackEvent('geo-drilldown', {
      label: this.locationMarker.name,
      value: this.totalCount,
    });
  }

  private setCoefficientsAndDerivedValues() {
    this.coefficients = this.locationMarker?.counts;
    this.totalCount = _.sum(this.coefficients);
    this.radius = GeoVisualizerComponent.quantizeRadius(
      this.totalCount,
      this.themeSettings.map
    );
    this.diameter = 2 * this.radius;
    this.innerRadius = this.radius - this.thickness;
    this.textColor = this.invertLabel ? 'black' : 'white';
    this.labelString = Math.round(this.totalCount).toString();
    this.fontSize = this.diameter / 3;
    this.viewBox = `-${this.radius} -${this.radius} ${this.diameter} ${this.diameter}`;
  }

  private computeSegments() {
    this.setCoefficientsAndDerivedValues();

    this.segments = [];
    let angle = 0;
    const pt = (r: number, a: number) =>
      `${r * Math.sin(a)} ${-r * Math.cos(a)}`;

    if (this.coefficients) {
      const spacer = this.coefficients.length > 1 ? this.spacerAngle : 0;

      this.coefficients.forEach((coefficient, index) => {
        const color = this.colors[index];
        // An arc starting and ending at same point doesn't get drawn, so ensure it is not full circle
        const fraction = Math.min(
          this.totalCount === 0 ? 1 : coefficient / this.totalCount,
          0.9999
        );
        const newAngle: number = angle + fraction * 2 * Math.PI;
        const extent = newAngle - spacer;
        const pt1 = pt(this.radius, angle);
        const pt2 = pt(this.radius, extent);
        const pt3 = pt(this.innerRadius, extent);
        const pt4 = pt(this.innerRadius, angle);
        const largeArc = fraction > 0.5 && fraction !== 1 ? 1 : 0;

        this.segments.push({ pt1, pt2, pt3, pt4, color, largeArc });
        angle = newAngle;
      });
    }
  }

  public positionAndSizeClasses(): string {
    const point = this.locationMarker.point;
    this.ngStyle = {
      left: `calc(${point?.x}px - ${this.radius}px)`,
      top: `calc(${point?.y}px - ${this.radius}px)`,
    };
    return this.sizeClass;
  }

  public showTooltip(ev: MouseEvent): void {
    const tth = this.tooltipHandler;

    const marker = this.findFirstParentMatch(
      ev.target as HTMLElement,
      '.cf_location-indicator'
    );

    tth.markerElement = marker;

    this.positionTooltip();
    this.tooltipHandler.show(this);
  }

  public positionTooltip(): void {
    const tth = this.tooltipHandler;
    const marker = tth.markerElement;

    if (marker) {
      const parent = this.findFirstParentMatch(
        marker,
        '.cf_overview_explore_content'
      );

      if (parent) {
        const markerRect = marker.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();
        const offsetX = markerRect.left - parentRect.left;
        const offsetY = parentRect.bottom - markerRect.top;

        tth.left = offsetX + this.radius - this.tooltipArrowLeftOffset;
        tth.bottom = offsetY + this.tooltipArrowBottomOffset;
      }
    }
  }

  public hideTooltip(): void {
    this.tooltipHandler.hide();
    this.tooltipHandler.markerElement = null;
  }

  public redirectWheelEvent(e: WheelEvent): boolean {
    const geoVisualizer = this.findFirstParentMatch(
      e.target as HTMLElement,
      '.cf_geo-visualizer'
    );
    if (geoVisualizer) {
      e.preventDefault();
      const canvas = geoVisualizer.querySelector('.mapboxgl-canvas-container');
      canvas.dispatchEvent(new WheelEvent(e.type, e));
      return false;
    }
    return true;
  }

  private findFirstParentMatch(el: HTMLElement, selector: string) {
    while ((el = el.parentElement)) {
      if (el.matches(selector)) {
        return el;
      }
    }
    return null;
  }
}
