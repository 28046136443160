import * as moment from 'moment';
import * as _ from 'lodash';

import {
  Company,
  ICompanyUpdate,
  LocalizedTextIds,
} from 'company-finder-common';

const MILESTONE_ROLLING_WINDOW_SIZE = 12;

export interface MilestoneLayoutInfo {
  label: string;
  properties: MilestoneProperty[];
  propertyRoot: string;
  type: MilestoneType;
}

export interface MilestoneLayoutTemplate {
  localizedTextId: LocalizedTextIds;
  propertyRoot: string;
  type: MilestoneType;
}

export interface MilestoneProperty {
  milestoneValue: MilestoneValue;
  label: string;
  value: string;
  propertyRoot: string;
}

export enum MilestoneType {
  RdMilestones,
  IpMilestones,
  BarriersChallenges,
  RdPartnerships,
  FundingInvestments,
  EmployeeNeeds,
  TRL,
}

export enum MilestoneValue {
  Initial = 0,
  TwelveMonth = 12,
  TwentyFourMonth = 24,
  ThirtySixMonth = 36,
  FortyEightMonth = 48,
  SixtyMonth = 60,
}

export const milestoneLayoutInfoArray: MilestoneLayoutTemplate[] = [
  {
    localizedTextId: LocalizedTextIds.BlueKnightUtilRDPlans,
    propertyRoot: 'rdMilestones',
    type: MilestoneType.RdMilestones,
  },
  {
    localizedTextId: LocalizedTextIds.BlueKnightUtilIPMilestones,
    propertyRoot: 'ipMilestones',
    type: MilestoneType.IpMilestones,
  },
  {
    localizedTextId: LocalizedTextIds.BlueKnightUtilKeyBarriers,
    propertyRoot: 'barriersChallenges',
    type: MilestoneType.BarriersChallenges,
  },
  {
    localizedTextId: LocalizedTextIds.BlueKnightUtilRDPartnerships,
    propertyRoot: 'rdPartnerships',
    type: MilestoneType.RdPartnerships,
  },
  {
    localizedTextId: LocalizedTextIds.BlueKnightUtilFunding,
    propertyRoot: 'fundingInvestments',
    type: MilestoneType.FundingInvestments,
  },
];

export const milestoneSuffixKeys: LocalizedTextIds[] = [
  LocalizedTextIds.BlueKnightUtil1Yr,
  LocalizedTextIds.BlueKnightUtil2Yr,
  LocalizedTextIds.BlueKnightUtil3Yr,
  LocalizedTextIds.BlueKnightUtil4Yr,
  LocalizedTextIds.BlueKnightUtil5Yr,
];

export function displayMilestone(
  company: Company,
  milestoneValue: MilestoneValue,
  propName: string = null,
  pendingUpdate: ICompanyUpdate = null
): boolean {
  const baseDate = company.blueKnightCommencementDate
    ? company.blueKnightCommencementDate
    : company.commencementDate;
  const blueKnightCommencementMoment = moment.default(baseDate);
  const months = moment
    .default(new Date())
    .diff(blueKnightCommencementMoment, 'months', true);
  // this assumes we want: a 1 period lookback, the current period, and 1 future period
  const windowStart = months - MILESTONE_ROLLING_WINDOW_SIZE;
  const windowEnd = months + 2 * MILESTONE_ROLLING_WINDOW_SIZE;
  if (milestoneValue >= windowStart && milestoneValue < windowEnd) {
    return true;
  }

  const updateFields = pendingUpdate?.updateFields;
  if (updateFields?.find((field) => field.name === propName)) {
    return true;
  }

  return false;
}

export function hasMilestonesOfType(
  milestoneLayoutInfos: MilestoneLayoutInfo[],
  milestoneType: MilestoneType
): boolean {
  return _.some(
    milestoneLayoutInfos,
    (milestoneLayoutInfo) =>
      milestoneLayoutInfo.type === milestoneType &&
      _.some(
        milestoneLayoutInfo.properties,
        (milestoneProperty) => !!milestoneProperty.value
      )
  );
}
