import {
  Component,
  OnChanges,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ActionModalComponent,
  ActionOptions,
  ActionResult,
} from '../../../_common/components/action-modal/action-modal.component';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import { UserService } from '../../../_common/services/user/user.service';

import {
  EmailFrequency,
  EventFilterType,
  emailFrequencyToDisplayString,
  IUser,
  IUserNewsUpdatePreferences,
  LocalizedTextIds,
} from 'company-finder-common';
import { ComponentBase } from '../../../_common/components/_component.base';

@Component({
  selector: 'notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent
  extends ComponentBase
  implements OnChanges, OnInit
{
  @Input()
  showModal: boolean;

  @Input()
  userObj: IUser;

  @Output()
  close = new EventEmitter();

  @Output()
  start = new EventEmitter();

  @ViewChild(ActionModalComponent, { static: true })
  modal: ActionModalComponent;

  public toDisplayString = emailFrequencyToDisplayString;

  private userNewsUpdatePreferences: IUserNewsUpdatePreferences;
  private readonly _emailFrequencyOptions: EmailFrequency[] = [
    EmailFrequency.Daily,
    EmailFrequency.Weekly,
    EmailFrequency.BiWeekly,
    EmailFrequency.Never,
  ];
  private _sendNewsletterEmailsAt: string;

  private fields = {
    SECTORS: {
      name: this.Loc(LocalizedTextIds.Sectors),
      type: EventFilterType.SectorsSubSectors,
    },
    TAGS: { name: this.Loc(LocalizedTextIds.Tags), type: EventFilterType.Tags },
    COMPANIES: {
      name: this.Loc(LocalizedTextIds.Companies),
      type: EventFilterType.Companies,
    },
    LOCATIONS: {
      name: this.Loc(LocalizedTextIds.Locations),
      type: EventFilterType.JLABSLocation,
    },
    SEARCHES: {
      name: this.Loc(LocalizedTextIds.Searches),
      type: EventFilterType.SavedSearches,
    },
  };

  public get ActionOptions(): ActionOptions[] {
    return [
      {
        actionResult: ActionResult.Continue,
        displayText: this.Loc(LocalizedTextIds.NotificationsModalSave),
        disabled: false,
      },
    ];
  }

  public constructor(dc: DeploymentContext, private userService: UserService) {
    super(dc);
  }

  public get emailFrequencyOptions(): EmailFrequency[] {
    return this._emailFrequencyOptions;
  }

  public get userEmailFrequency(): EmailFrequency {
    return this.userObj.emailNewsletterFrequency;
  }

  public set userEmailFrequency(val: EmailFrequency) {
    this.userObj.emailNewsletterFrequency = val;
  }

  public async ngOnInit(): Promise<void> {
    this.start.emit(this.fields);

    // if userNewsUpdatePreferences Data doesn't exist
    this.setCurrentField();

    const hourOfDay = this._deploymentContext.sendNewsletterEmailsHourOfDay;
    const amPm = hourOfDay > 12 ? 'pm' : 'am';
    this._sendNewsletterEmailsAt = `${hourOfDay}:00${amPm}`;
  }

  public ngOnChanges(): void {
    if (this.showModal) {
      this.setCurrentField();
      this.modal.open();
    }
  }

  public async onClose(): Promise<void> {
    if (this.userEmailFrequency === EmailFrequency.Never) {
      this.setPreferencesFalse();
    }
    this.userService.save(this.userObj);
    this.close.emit(null);
  }

  public setCurrentField(): void {
    this.userNewsUpdatePreferences = this.userObj.userNewsUpdatePreferences;
  }

  public get frequencyLongDescription(): string {
    switch (this.userEmailFrequency) {
      case EmailFrequency.Daily:
        return this.Loc(
          LocalizedTextIds.NotificationsModalEveryday,
          this._sendNewsletterEmailsAt
        );
      case EmailFrequency.Weekly:
        return this.Loc(
          LocalizedTextIds.NotificationsModalEveryMonday,
          this._sendNewsletterEmailsAt
        );
      case EmailFrequency.BiWeekly:
        return this.Loc(
          LocalizedTextIds.NotificationsModalEveryOtherMonday,
          this._sendNewsletterEmailsAt
        );
      case EmailFrequency.Monthly:
        return this.Loc(
          LocalizedTextIds.NotificationsModalFirstMondayMonth,
          this._sendNewsletterEmailsAt
        );
      case EmailFrequency.Quarterly:
        return this.Loc(
          LocalizedTextIds.NotificationsModalFirstMondayQuarter,
          this._sendNewsletterEmailsAt
        );
      case EmailFrequency.Yearly:
        return this.Loc(
          LocalizedTextIds.NotificationsModalFirstMondayYear,
          this._sendNewsletterEmailsAt
        );
      default:
        return this.Loc(LocalizedTextIds.NotificationsModalNoAlerts);
    }
  }

  // fields methods
  public setPreferencesFalse(): void {
    this.userObj.userNewsUpdatePreferences.emailUpdates = false;
    this.userObj.userNewsUpdatePreferences.emailNews = false;
    this.userObj.userNewsUpdatePreferences.emailNewCompanies = false;
  }

  public get userNewsUpdatePreferenceData(): IUserNewsUpdatePreferences {
    return this.userNewsUpdatePreferences;
  }
}
