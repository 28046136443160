import { Company, ICompanyUpdate } from 'company-finder-common';

// Our ORM package isn't persisting boolean properties with a value of 1 as true (or in MySQL, 1),
// but if we explicitly set it to true instead of 1, persistence works as we'd like.
// FUTURE: When we reconsile the full scope of boolean impedance issues, we shouldn't need this anymore.
export function ensureMappingForBooleans(companyUpdate: ICompanyUpdate): void {
  if (
    companyUpdate.conferenceUpdates &&
    companyUpdate.conferenceUpdates.length > 0
  ) {
    companyUpdate.conferenceUpdates.forEach((conferenceUpdate) => {
      if (conferenceUpdate.isNewConference) {
        conferenceUpdate.isNewConference = true;
      }
      if (conferenceUpdate.isDeletedConference) {
        conferenceUpdate.isDeletedConference = true;
      }
      if (conferenceUpdate.isAttendedConference) {
        conferenceUpdate.isAttendedConference = true;
      }
    });
  }
  if (companyUpdate.dealUpdates && companyUpdate.dealUpdates.length > 0) {
    companyUpdate.dealUpdates.forEach((dealUpdate) => {
      if (dealUpdate.isNewDeal) {
        dealUpdate.isNewDeal = true;
      }
      if (dealUpdate.isDeletedDeal) {
        dealUpdate.isDeletedDeal = true;
      }
    });
  }
  if (companyUpdate.fundingUpdates && companyUpdate.fundingUpdates.length > 0) {
    companyUpdate.fundingUpdates.forEach((fundingUpdate) => {
      if (fundingUpdate.isNewFunding) {
        fundingUpdate.isNewFunding = true;
      }
      if (fundingUpdate.isDeletedFunding) {
        fundingUpdate.isDeletedFunding = true;
      }
    });
  }
}

export function isTruthy(value: string): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return value === '1' || (value as any) === 1 || (value as any) === true;
}

export function getBooleanAsNumber(value: string): number {
  return isTruthy(value) ? 1 : 0;
}

export function updateCompanyFromFormChanges(
  currentCompany: Company,
  formValues: any,
  contactName?: string
): Company {
  const modifiedCompany = currentCompany;
  // FUTURE: Is there a way to pre-trim the string values? That way we wouldn't have to:
  //         * trim each one individually here
  //         * worry about leaving the spaces behind in the form
  // If we reset the form values to the trimmed values, we wouldn't be able to add a trailing space,
  // so we couldn't change a company name from 'XYZ' to 'XYZ Corp.' because that space would keep getting
  // trimmed. If we had an explicit gesture that sets the value (like the Done button back in the old
  // designs), we could trim it then. Or we could trim when we focus out, but we decided against
  // investing the time in polishing such a solution for now. This would be true of the deal & funding
  // modals as well.
  modifiedCompany.name = formValues.name?.trim();
  modifiedCompany.logoBase64 = formValues.logoBase64?.trim();
  modifiedCompany.website = formValues.website?.trim();
  modifiedCompany.description = formValues.description?.trim();
  modifiedCompany.problem = formValues.problem?.trim();
  modifiedCompany.solution = formValues.solution?.trim();
  // Contact Name is disabled (read-only), so get the value directly from the control.
  if (modifiedCompany.companyContact) {
    modifiedCompany.companyContact.name = contactName?.trim();
    modifiedCompany.companyContact.title =
      formValues.companyContactTitle?.trim();
  }
  modifiedCompany.currentRdStage = formValues?.currentRdStage;
  modifiedCompany.firstTimeEntrepreneur = formValues.firstTimeEntrepreneur;
  modifiedCompany.womenLed = formValues.womenLed;
  modifiedCompany.minorityLed = formValues.minorityLed;
  modifiedCompany.countryForDeiReporting = formValues.countryForDeiReporting;
  modifiedCompany.leadershipDiversity = formValues.leadershipDiversity;
  modifiedCompany.boardAdvisorDiversity = formValues.boardAdvisorDiversity;
  modifiedCompany.womenLedOrgLeadership = formValues.womenLedOrgLeadership;
  modifiedCompany.womenLedBoardOfDirectorsOrEquiv =
    formValues.womenLedBoardOfDirectorsOrEquiv;
  modifiedCompany.keyMgmtAndAdvBm = formValues.keyMgmtAndAdvBm?.trim();
  // Blue Knight properties
  modifiedCompany.companyObjective = formValues.companyObjective?.trim();
  modifiedCompany.approachUsecase = formValues.approachUsecase?.trim();
  modifiedCompany.entryExitStrategy = formValues.entryExitStrategy?.trim();
  modifiedCompany.keyPublicationsOfScience =
    formValues.keyPublicationsOfScience?.trim();
  modifiedCompany.mentorship = formValues.mentorship?.trim();
  modifiedCompany.engagement = formValues.engagement?.trim();
  modifiedCompany.currencyBlueKnight = formValues.currencyBlueKnight;
  modifiedCompany.trl = formValues.trl?.trim();
  modifiedCompany.twelveMonthTrl = formValues.twelveMonthTrl?.trim();
  modifiedCompany.twentyFourMonthTrl = formValues.twentyFourMonthTrl?.trim();

  modifiedCompany.thirtySixMonthTrl = formValues.thirtySixMonthTrl?.trim();
  modifiedCompany.fortyEightMonthTrl = formValues.fortyEightMonthTrl?.trim();
  modifiedCompany.sixtyMonthTrl = formValues.sixtyMonthTrl?.trim();

  modifiedCompany.numEmpFutureNeeds = formValues.numEmpFutureNeeds;
  modifiedCompany.twelveMonthNumEmpFutureNeeds =
    formValues.twelveMonthNumEmpFutureNeeds;
  modifiedCompany.twentyFourMonthNumEmpFutureNeeds =
    formValues.twentyFourMonthNumEmpFutureNeeds;

  modifiedCompany.thirtySixMonthNumEmpFutureNeeds =
    formValues.thirtySixMonthNumEmpFutureNeeds?.trim();
  modifiedCompany.fortyEightMonthNumEmpFutureNeeds =
    formValues.fortyEightMonthNumEmpFutureNeeds?.trim();
  modifiedCompany.sixtyMonthNumEmpFutureNeeds =
    formValues.sixtyMonthNumEmpFutureNeeds?.trim();

  modifiedCompany.rdMilestones12Month = formValues.rdMilestones12Month;
  modifiedCompany.rdMilestones24Month = formValues.rdMilestones24Month;
  modifiedCompany.rdMilestones36Month = formValues.rdMilestones36Month;
  modifiedCompany.rdMilestones48Month = formValues.rdMilestones48Month;
  modifiedCompany.rdMilestones60Month = formValues.rdMilestones60Month;

  modifiedCompany.ipMilestones12Month = formValues.ipMilestones12Month;
  modifiedCompany.ipMilestones24Month = formValues.ipMilestones24Month;
  modifiedCompany.ipMilestones36Month = formValues.ipMilestones36Month;
  modifiedCompany.ipMilestones48Month = formValues.ipMilestones48Month;
  modifiedCompany.ipMilestones60Month = formValues.ipMilestones60Month;

  modifiedCompany.barriersChallenges12Month =
    formValues.barriersChallenges12Month;
  modifiedCompany.barriersChallenges24Month =
    formValues.barriersChallenges24Month;
  modifiedCompany.barriersChallenges36Month =
    formValues.barriersChallenges36Month;
  modifiedCompany.barriersChallenges48Month =
    formValues.barriersChallenges48Month;
  modifiedCompany.barriersChallenges60Month =
    formValues.barriersChallenges60Month;

  modifiedCompany.rdPartnerships12Month = formValues.rdPartnerships12Month;
  modifiedCompany.rdPartnerships24Month = formValues.rdPartnerships24Month;
  modifiedCompany.rdPartnerships36Month = formValues.rdPartnerships36Month;
  modifiedCompany.rdPartnerships48Month = formValues.rdPartnerships48Month;
  modifiedCompany.rdPartnerships60Month = formValues.rdPartnerships60Month;

  modifiedCompany.fundingInvestments12Month =
    formValues.fundingInvestments12Month;
  modifiedCompany.fundingInvestments24Month =
    formValues.fundingInvestments24Month;
  modifiedCompany.fundingInvestments36Month =
    formValues.fundingInvestments36Month;
  modifiedCompany.fundingInvestments48Month =
    formValues.fundingInvestments48Month;
  modifiedCompany.fundingInvestments60Month =
    formValues.fundingInvestments60Month;

  return modifiedCompany;
}

export function formatSubmissionContent(
  propertyName: string,
  propertyValue: any,
  currency?: string
): string {
  const numericValue = parseInt(propertyValue, 10);
  switch (propertyName) {
    case 'isConfidential':
    case 'isJnjDeal':
      return numericValue ? 'Yes' : 'No';
    case 'amount':
    case 'raised':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
      }).format(numericValue);
    case 'contingentFundingAmountUsd':
    case 'securedFundingAmountUsd':
    case 'amountRaisedUsd':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(numericValue);
    case 'announcementDate':
    case 'dateRaised':
      return propertyValue instanceof Date
        ? (propertyValue as any).toLocaleString('en-US', {
            dateStyle: 'medium',
          })
        : (new Date(propertyValue) as any).toLocaleString('en-US', {
            dateStyle: 'medium',
          });
    default:
      return propertyValue;
  }
}
