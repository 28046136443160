export enum SortType {
    Ranking = 'Relevancy',
    Date = 'Newest',
    Alpha = 'Alphabetic',
}

export class Filter {
    // public properties
    public static equals(someFilter: Filter, otherFilter: Filter): boolean {
        // Stringified JSON is an efficient and scalable way to compare complex objects
        // provided that the order of properties is static. See https://stackoverflow.com/a/1144249
        // and https://www.mattzeunert.com/2016/01/28/javascript-deep-equal.html
        return JSON.stringify(someFilter) === JSON.stringify(otherFilter);
    }

    public locationStatuses: string;
    public companyStatuses: string;
    public totalLocations: number;
    public totalPrimarySectors: number;
    public totalPrimarySubSectors: number;
    public totalSecondarySectors: number;
    public totalSecondarySubSectors: number;
    public locations: string[];
    public primarySectors: string[];
    public primarySubSectors: string[];
    public secondarySectors: string[];
    public secondarySubSectors: string[];
    public tags: string[];
    public isBlueKnight: boolean;
    public isCrossSector: boolean;
    public isQFCWinner: boolean;
    public isNewInLastQuarter: boolean;
    public currentRdStage: string;
    public isNewInLast90Days: boolean;
    public isFollowedCompaniesOnly: boolean;
    public isMyJPALCompaniesOnly: boolean;

    public sort: SortType;

    public constructor(
        locations: string[],
        primarySectors: string[],
        primarySubSectors: string[],
        secondarSectors: string[],
        secondarySubSectors: string[],
        totalLocations: number,
        totalPrimarySectors: number,
        totalPrimarySubSectors: number,
        totalSecondarySectors: number,
        totalSecondarySubSectors: number,
        locationStatuses?: string,
        tags?: string[],
        isBlueKnight?: boolean,
        isCrossSector?: boolean,
        isQFCWinner?: boolean,
        isNewInLastQuarter?: boolean,
        currentRdStage?: string,
        isNewInLast90Days?: boolean,
        isFollowedCompaniesOnly?: boolean,
        isMyJPALCompaniesOnly?: boolean,
        companyStatuses?: string
    ) {
        this.locations = locations;
        this.primarySectors = primarySectors;
        this.primarySubSectors = primarySubSectors;
        this.secondarySectors = secondarSectors;
        this.secondarySubSectors = secondarySubSectors;
        this.totalLocations = totalLocations;
        this.totalPrimarySectors = totalPrimarySectors;
        this.totalPrimarySubSectors = totalPrimarySubSectors;
        this.totalSecondarySectors = totalSecondarySectors;
        this.totalSecondarySubSectors = totalSecondarySubSectors;
        this.locationStatuses = locationStatuses ?? '';
        this.companyStatuses = companyStatuses ?? '';
        this.tags = tags;
        this.isBlueKnight = isBlueKnight;
        this.isCrossSector = isCrossSector;
        this.isQFCWinner = isQFCWinner;
        this.isNewInLastQuarter = isNewInLastQuarter;
        this.currentRdStage = currentRdStage;
        this.isNewInLast90Days = isNewInLast90Days;
        this.isFollowedCompaniesOnly = isFollowedCompaniesOnly;
        this.isMyJPALCompaniesOnly = isMyJPALCompaniesOnly;
    }

    public static get emptyFilter(): Filter {
        return new Filter([], [], [], [], [], 0, 0, 0, 0, 0);
    }

    public static get emptyAlphaSortFilter(): Filter {
       const empty = this.emptyFilter;
       empty.sort = SortType.Alpha;
       return empty;
    }

    // public methods
    public isShowAllLocations(): boolean {
        return (
            this.locations.length === this.totalLocations ||
            this.locations.length === 0
        );
    }

    public isShowAllSectors(): boolean {
        return (
            this.primarySectors?.length === this.totalPrimarySectors ||
            this.primarySectors?.length === 0
        );
    }

    public isShowAllSubSectors(): boolean {
        return (
            this.primarySubSectors?.length === this.totalPrimarySubSectors ||
            this.primarySubSectors?.length === 0
        );
    }

    public isShowAllSecondarySectors(): boolean {
        return (
            this.secondarySectors?.length === this.totalSecondarySectors ||
            this.secondarySectors?.length === 0
        );
    }

    public isShowAllSecondarySubSectors(): boolean {
        return (
            this.secondarySubSectors?.length ===
                this.totalSecondarySubSectors ||
            this.secondarySubSectors?.length === 0
        );
    }

    public isShowAll(): boolean {
        // NOTE: The various filters available in the UI treat NONE or empty as ALL.
        return (
            (!this.locationStatuses || this.locationStatuses === '') &&
            (!this.companyStatuses || this.companyStatuses === '') &&
            this.isShowAllLocations() &&
            this.isShowAllSectors() &&
            this.isShowAllSubSectors() &&
            this.isShowAllSecondarySectors() &&
            this.isShowAllSecondarySubSectors() &&
            (!this.tags || this.tags.length === 0) &&
            !this.isBlueKnight &&
            !this.isCrossSector &&
            !this.isQFCWinner &&
            !this.isNewInLastQuarter &&
            !this.currentRdStage &&
            !this.isNewInLast90Days &&
            !this.isFollowedCompaniesOnly &&
            !this.isMyJPALCompaniesOnly
        );
    }

    public clear(): void {
        this.locationStatuses = '';
        this.companyStatuses = '';
        this.locations = [];
        this.primarySectors = [];
        this.primarySubSectors = [];
        this.secondarySectors = [];
        this.secondarySubSectors = [];
        this.tags = undefined;
        this.isBlueKnight = undefined;
        this.isCrossSector = undefined;
        this.isQFCWinner = undefined;
        this.isNewInLastQuarter = undefined;
        this.currentRdStage = undefined;
        this.isNewInLast90Days = undefined;
        this.isFollowedCompaniesOnly = undefined;
        this.isMyJPALCompaniesOnly = undefined;

        this.sort = undefined;
    }

    /** Returns a deep copy of this filter */
    public clone(): Filter {
        const copy = Filter.emptyFilter;
        Object.assign(copy, this);
        copy.locations = [...this.locations];
        copy.primarySectors = [...this.primarySectors];
        copy.primarySubSectors = [...this.primarySubSectors];
        copy.tags = this.tags ? [...this.tags] : undefined;
        return copy;
    }

    public serializeToKeyValuePairs(): string[] {
        const keyValuePairs = [];
        const defaultFilter = Filter.emptyFilter;
        for (const [key, value] of Object.entries(this)) {
            const isArray = Array.isArray(value);
            if (
                (value !== undefined &&
                    !isArray &&
                    value !== defaultFilter[key]) ||
                (isArray && value.length !== 0)
            ) {
                if (typeof value === 'boolean' && !value) {
                    continue;
                }
                keyValuePairs[key] = {
                    value: value,
                    isArray: isArray,
                };
            }
        }
        return keyValuePairs;
    }
}
