/* eslint-disable prettier/prettier */
import { Component, Input } from '@angular/core';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

import { SectorDisplayItem } from 'company-finder-common';
import { BaseSelectorComponent } from '../BaseSelectorComponent';

@Component({
    selector: 'sector-selector',
    templateUrl: './sector-selector.component.html',
    styleUrls: ['../base-selector.scss']
})
export class SectorSelectorComponent extends BaseSelectorComponent<SectorDisplayItem> {
    @Input()
    label = 'Sector & Sub-Sector';

    public constructor(
        deploymentContext: DeploymentContext
      ) {
        super(deploymentContext);
    }

    public get subsectorsIndependentOnDropdown(): boolean {
        return this._deploymentContext.subsectorsIndependentOnDropdown;
    }

    public get subsectorsPerColumn(): number {
        return this._deploymentContext.subsectorsPerColumn;
    }
}
