import _ from 'lodash';

// model imports
import { Location } from 'company-finder-common';
import { Company, Opportunity } from 'company-finder-common';
import {
  hasDealsWithJnJ,
  contractExecutedOpportunities,
} from 'company-finder-common';
import {
  canonicalizeSectorSubsectorName,
  sectorSubsectorMatches,
  sortSubsectors,
} from 'company-finder-common';
import { CurrentRdStage } from 'company-finder-common';
import { DeploymentContext } from '../deployment-context/deployment-context';

export class Summary {
  // private properties
  private _locationCounts: number[];
  private _maxLocationCount = 0;
  private _companyCountsBySector: number[];

  public allSectors: string[] = [];

  private _dealsAndPartnershipsSummary = new DealsAndPartnershipsSummary();

  public constructor(
    public companies: Company[],
    private deploymentContext: DeploymentContext
  ) {
    this.allSectors = this.deploymentContext.sectors;
    this._locationCounts = [];
    this._companyCountsBySector = [];

    const incrementSectorCount = (sector: string) => {
      if (_.isString(sector)) {
        const currentCount = this._companyCountsBySector[sector] || 0;
        this._companyCountsBySector[sector] = currentCount + 1;
      }
    };

    // Capture the location counts to inform the sizing of the location indicators
    // Go through all the locations, and count the number of companies in each one.
    // Also capture sector counts
    this.companies.forEach((company) => {
      // aggregator for Geo visualization
      company.locations.forEach((location) => {
        if (
          !!location.name ||
          location.name === 'Virtual' ||
          location.name.toLocaleLowerCase().includes('other')
        ) {
          return;
        }

        if (
          !this._locationCounts[location.name] &&
          this._locationCounts[location.name] !== 0
        ) {
          this._locationCounts[location.name] = 0;
        }
        this._locationCounts[location.name]++;
        const value = this._locationCounts[location.name];
        this._maxLocationCount =
          value > this._maxLocationCount ? value : this._maxLocationCount;
      });

      // aggregator for sector counts.
      // Assumes that company won't have dup values between primary/secondar sector/subsector
      incrementSectorCount(company.primarySector);
      incrementSectorCount(company.primarySubSector);
      // FUTURE: Can include these if desired
      // incrementSectorCount(company.secondarySector);
      // incrementSectorCount(company.secondarySubSector);

      // aggregator for deals & partnerships tile
      // Increment unique company count, separating out the limitedDealOpportunities quality, since
      //  we don't want that in hasDealsWithJnJ, since that is reused on the Company details page.
      if (
        hasDealsWithJnJ(company) ||
        (company.limitedDealOpportunities &&
          company.limitedDealOpportunities.length > 0)
      ) {
        // See note above
        this._dealsAndPartnershipsSummary.totalUniqueCompanies += 1;
      }
      const accumulate = (sector: string, subSector: string) => {
        if (sector) {
          const rootEntry =
            this._dealsAndPartnershipsSummary.getPrimarySectorCount(sector);
          rootEntry.count += 1;
          if (subSector) {
            const leafEntry = rootEntry.getPrimarySubSectorCount(
              company.primarySubSector
            );
            leafEntry.count += 1;
          }
        }
      };
      if (company.isQfcWinner) {
        accumulate(company.primarySector, company.primarySubSector);
      }
      contractExecutedOpportunities(company).forEach((opp: Opportunity) => {
        accumulate(opp.primarySector, opp.primarySubSector);
      });

      if (company.limitedDealOpportunities) {
        company.limitedDealOpportunities.forEach((item) =>
          accumulate(item.primarySector, item.primarySubSector)
        );
      }
    });
  }

  // public getters
  public get numberOfAcquiredCompanies(): number {
    return this.companies.filter((company) => company.wasAcquired).length;
  }

  public get numberOfPubliclyOfferedCompanies(): number {
    return this.companies.filter((company) => company.isPubliclyOffered).length;
  }

  public get numberOfCompanies(): number {
    return this.companies.length;
  }

  public get numberOfCrossSectorCompanies(): number {
    const companies = this.companies.filter(
      (company) =>
        company.secondarySector &&
        company.secondarySector !== company.primarySector
    );
    return companies.length;
  }

  public get numberOfNewCompanies(): number {
    const companies = this.companies.filter((company) =>
      this.isNewLastFullQuarter(company)
    );
    return companies.length;
  }

  public get numberOfBlueKnights(): number {
    const companies = this.companies.filter((company) => company.isBlueKnight);
    return companies.length;
  }

  public get numberOfQfcWinners(): number {
    const companies = this.companies.filter((company) => company.isQfcWinner);
    return companies.length;
  }

  public get companyCountsByLocationName(): number[] {
    return this._locationCounts;
  }

  public get maxCompanyCount(): number {
    // Would have been nice to use a reducer on _locationCounts, but there was no easy way that was IE11 compatible
    // to get the values of the properties (note that the typing of _locationCounts as number[] is misleading)
    return this._maxLocationCount;
  }

  /** Unique set of locations represented in this summary set */
  public get locations(): Location[] {
    return this.companies
      .flatMap((company) => company.locations)
      .filter(
        (loc, i, locs) =>
          locs.findIndex((otherLoc) => loc.name === otherLoc.name) === i
      );
  }

  /** Unique set of sectors (primary sectors) represented in this summary set */
  public get sectors(): string[] {
    return this.allSectors?.filter((sector) =>
      this.companies.find((company) => company.primarySector === sector)
    );
  }

  /** Unique set of subsectors (primary sub sectors) represented in this summary set */
  public get subsectors(): string[] {
    // FUTURE: Leverage lodash uniqBy() or something like it here.
    const subSectors = this.companies.reduce((result, company) => {
      if (company.primarySubSector) {
        result.add(company.primarySubSector);
      }
      return result;
    }, new Set<string>());
    return sortSubsectors(Array.from(subSectors));
  }

  public get sumTotalSecuredAndContingentAmount(): number {
    return this.companies.reduce(
      (amount, company) => amount + company.totalSecuredAndContingentAmount,
      0
    );
  }

  // public methods
  public companiesByLocation(locationName: string): Company[] {
    return this.companies.filter((company) =>
      company.locations.find((location) => location.name === locationName)
    );
  }

  public companyCountBySector(sector: string): number {
    return this._companyCountsBySector[sector];
  }

  public numberOfCompaniesInSector(sector: string): number {
    const companyCount = this.companies.reduce((count, company) => {
      if (sectorSubsectorMatches(sector, company.primarySector)) {
        ++count;
      }
      return count;
    }, 0);
    return companyCount;
  }

  public numberOfCompaniesAtLocationBySector(
    companiesAtLocation: Company[],
    sector: string
  ): number {
    return companiesAtLocation.filter((company) =>
      sectorSubsectorMatches(sector, company.primarySector)
    ).length;
  }

  /**
   * This only holds a complete representation of this data for internal users.
   * https://jira.jnj.com/browse/ADJQ-52
   */
  public get dealsAndPartnershipsSummary(): DealsAndPartnershipsSummary {
    return this._dealsAndPartnershipsSummary;
  }

  /** Unique set of sub-sectors (primary sub sector) for a given primary sector represented in this summary set */
  public subSectorsBySector(sector: string): string[] {
    // FUTURE: Leverage lodash uniqBy() or something like it here.
    const subsectors = this.companies.reduce((result, company) => {
      if (company.primarySector === sector && company.primarySubSector?.length > 0) {
        result.add(company.primarySubSector);
      }
      return result;
    }, new Set<string>());
    return sortSubsectors(Array.from(subsectors));
  }

  public stagesBySectorInit(
    sector: string,
    rdStagesBySector: { [sector: string]: CurrentRdStage[] }
  ): StageCounts {
    sector = canonicalizeSectorSubsectorName(sector);
    const stages = rdStagesBySector[sector];
    const stageCounts = new StageCounts(sector, []);
    stageCounts.initialize(stages);
    return stageCounts;
  }

  // private methods
  private isNewLastFullQuarter(company: Company): boolean {
    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3);
    const startCurrentFullQuarter = new Date(
      today.getFullYear(),
      quarter * 3,
      1
    );
    const startPreviousFullQuarter = new Date(
      today.getFullYear(),
      quarter * 3 - 3,
      1
    );
    const commencementDate = new Date(company.commencementDate);
    return (
      startPreviousFullQuarter <= commencementDate &&
      commencementDate < startCurrentFullQuarter
    );
  }
}

export class DealsAndPartnershipsSummary {
  public totalUniqueCompanies = 0;
  private _primarySectorCounts: PrimarySectorCount[];

  constructor() {
    this._primarySectorCounts = new Array<PrimarySectorCount>();
  }

  public get primarySectorCounts(): PrimarySectorCount[] {
    return this._primarySectorCounts;
  }

  public get descendingPrimarySectorCounts(): PrimarySectorCount[] {
    return this.primarySectorCounts.sort((a, b) => b.count - a.count);
  }

  public get maxPrimarySectorCount(): number {
    // eslint-disable-next-line prefer-spread
    return Math.max.apply(
      Math,
      this.primarySectorCounts.map((o) => o.count)
    );
  }

  public getPrimarySectorCount(sector: string): PrimarySectorCount {
    let theEntry = this._primarySectorCounts.find(
      (entry) => entry.primarySector === sector
    );
    if (!theEntry) {
      theEntry = new PrimarySectorCount(sector);
      this._primarySectorCounts.push(theEntry);
    }
    return theEntry;
  }
}

export class PrimarySectorCount {
  public primarySector: string;
  public count = 0;
  private _primarySubSectorCounts: PrimarySubSectorCount[];

  constructor(sector: string) {
    this.primarySector = sector;
    this._primarySubSectorCounts = new Array<PrimarySubSectorCount>();
  }

  public get primarySubSectorCounts(): PrimarySubSectorCount[] {
    return this._primarySubSectorCounts;
  }

  public get descendingPrimarySubSectorCounts(): PrimarySubSectorCount[] {
    return this.primarySubSectorCounts.sort((a, b) => b.count - a.count);
  }

  public get maxPrimarySubSectorCount(): number {
    // eslint-disable-next-line prefer-spread
    return Math.max.apply(
      Math,
      this.primarySubSectorCounts.map((o) => o.count)
    );
  }

  public getPrimarySubSectorCount(subSector: string): PrimarySubSectorCount {
    let theEntry = this._primarySubSectorCounts.find(
      (entry) => entry.primarySubSector === subSector
    );

    if (!theEntry) {
      theEntry = new PrimarySubSectorCount(subSector);
      this.primarySubSectorCounts.push(theEntry);
    }
    return theEntry;
  }
}

export class PrimarySubSectorCount {
  public primarySubSector: string;
  public count = 0;

  constructor(primarySubSector: string) {
    this.primarySubSector = primarySubSector;
  }
}

export class StageCount {
  public stage: string;
  public count: number;
}

export class StageCounts {
  public sector: string;
  public stageCounts: StageCount[];

  constructor(sector: string, stageCounts: StageCount[]) {
    this.sector = sector;
    this.stageCounts = stageCounts;
  }

  public initialize(stageList: CurrentRdStage[]): void {
    stageList?.forEach((currentRdStage) => {
      this.stageCounts.push({
        stage: currentRdStage.value,
        count: 0,
      } as StageCount);
    });
  }
}
