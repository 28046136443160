import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import mapboxgl, { MapboxOptions } from 'mapbox-gl';
import _ from 'lodash';

import { ComponentBase } from '../../../../../../_common/components/_component.base';

// utility/service imports
import { DeploymentContext } from '../../../../../../_common/utilities/deployment-context/deployment-context';
import { TooltipHandler } from '../../models/tooltip-handler';
import { extractNumber, LocPrefix, MapSettings } from 'company-finder-common';
import {
  LocationGroup,
  LocationInfo,
} from '../../../../../../_common/models/location-group';

// FUTURE: Maybe put these into themeSettings?

@Component({
  selector: 'geo-visualizer',
  templateUrl: './geo-visualizer.component.html',
  styleUrls: ['./geo-visualizer.component.scss'],
})
export class GeoVisualizerComponent
  extends ComponentBase
  implements AfterViewInit, OnChanges, OnDestroy
{
  // public properties
  @Input()
  public locationInfos: LocationInfo[] = [];

  @Input()
  public tooltipHandler: TooltipHandler;

  @ViewChild('map')
  private _mapElement: ElementRef;

  public get locationsToShow(): LocationGroup[] {
    return Array.from(this.mergedLocationGroups.values());
  }

  public constructor(dc: DeploymentContext, private ngZone: NgZone) {
    super(dc);
  }

  private onRenderFunc: () => void;

  ngOnDestroy(): void {
    if (this.Map && this.onRenderFunc) {
      this.Map.off('render', this.onRenderFunc);
    }
  }

  public ID = `map-${Math.floor(Math.random() * 10000000)}`;

  public get hasSectors(): boolean {
    return this.sectors?.length > 1;
  }

  private Map: mapboxgl.Map;
  private getMap = () => this.Map;

  private sectors = this._deploymentContext.sectors.map((sector) =>
    this._deploymentContext
      .LocWithPrefix(sector, LocPrefix.SectorShortName)
      .toLocaleUpperCase()
  );

  private allLocationGroups: Array<LocationGroup> = [];
  private mergedLocationGroups: Map<string, LocationGroup> = new Map<
    string,
    LocationGroup
  >();

  public ngAfterViewInit(): void {
    this.initMap();
  }

  public ngOnChanges(): void {
    this.setMarkers();
    this.onRender();
  }

  public static quantizeRadius(size: number, mapSettings: MapSettings): number {
    if (size <= 0) {
      return mapSettings.emptyRadius;
    }

    // Linear scaling grows too big too fast:
    // return size / 4;

    // Try matching size to area.  To get fancier, could scale factor based on range of data values,
    // with a minSize and maxSize and a defined curve (sqrt, log, etc).
    // Still grows too large when the whole world gets merged
    // return Math.sqrt(100 * size) / Math.PI;

    return Math.min(
      mapSettings.maxRadius,
      Math.max(
        mapSettings.minRadius,
        Math.log(size) * mapSettings.radiusScaleFactor
      )
    );
  }

  public total(locationGroup: LocationGroup): number {
    return _.sum(locationGroup.counts);
  }

  public radius(locationGroup: LocationGroup): number {
    return GeoVisualizerComponent.quantizeRadius(
      this.total(locationGroup),
      this.themeSettings.map
    );
  }

  private mergeCloseLocations() {
    if (!this.Map) {
      return;
    }

    const locations: Array<LocationGroup> = this.allLocationGroups.map(
      (location) => location
    );
    while (true) {
      // Calculate distance pairs
      const distMatrix: Array<Array<number>> = [];
      for (let i = 0; i < locations.length; ++i) {
        const m: Array<number> = (distMatrix[i] = []);
        for (let j = i + 1; j < locations.length; ++j) {
          const l1 = locations[i].latLng;
          const l2 = locations[j].latLng;
          const p1 = this.Map.project(l1);
          const p2 = this.Map.project(l2);
          const dist = p1 && p2 && Math.hypot(p1.x - p2.x, p1.y - p2.y);
          if (dist) {
            m[j] = dist;
          }
        }
      }

      // Find the pair with the most overlap
      let worstOverlap = 0;
      let worstA = 0,
        worstB = 0;
      for (let i = 0; i < locations.length; ++i) {
        const m: Array<number> = distMatrix[i];
        for (let j = i + 1; j < locations.length; ++j) {
          const dist = m[j];
          const overlap =
            this.radius(locations[i]) + this.radius(locations[j]) - dist;
          if (overlap <= 0) {
            continue;
          }
          if (overlap > worstOverlap) {
            worstA = i;
            worstB = j;
            worstOverlap = overlap;
          }
        }
      }

      // If no overlap, we're done
      if (worstOverlap <= 0) {
        break;
      }

      // Merge
      const merged: LocationGroup = new LocationGroup(this.getMap);
      merged.push(...locations[worstA]);
      merged.push(...locations[worstB]);
      locations.splice(worstB, 1);
      locations.splice(worstA, 1);
      locations.push(merged);
    }

    // Update visible markers
    // Remove any no longer visible
    for (const locationName of this.mergedLocationGroups.keys()) {
      if (
        !locations.find((locationGroup) => locationGroup.name === locationName)
      ) {
        this.mergedLocationGroups.delete(locationName);
      }
    }

    // Add / update any now visible
    locations.forEach((location) => {
      this.mergedLocationGroups.set(location.name, location);
    });
  }

  // Initialize and add the map
  private async initMap(): Promise<void> {
    // If run normally (within the Angular zone), the mapbox-gl library will cause every mouse event (including moves)
    // to refresh everything, and will rebuild things like the Lead Product Stage sector dropdown, causing much churn
    // and breaking click events.  So we run the Map outside of Angular, but note that we need to do our onRender()
    // handling *withing* the ngZone, so we explicitly use ngZone.run() to do that below.
    this.ngZone.runOutsideAngular(() => {
      const mapSettings = this.themeSettings.map;

      const options: MapboxOptions = {
        container: this.ID,
        style: mapSettings.styleKey,
        attributionControl: false,
        projection: { name: 'mercator' },
        renderWorldCopies: true,
        accessToken: mapSettings.apiKey,
      };

      if (mapSettings.initialBounds) {
        options.bounds = mapSettings.initialBounds;
      }

      if (mapSettings.maxBounds) {
        options.maxBounds = mapSettings.maxBounds;
      }

      if (mapSettings.centerLngLat) {
        options.center = mapSettings.centerLngLat;
      }

      const { minZoom, initialZoom } = this.calculateZoom();

      options.minZoom = minZoom;
      options.zoom = initialZoom;

      this.Map = new mapboxgl.Map(options);

      // When rotatated more than a certain amount, our location circles disappear from the map.
      // Since rotation is not a particularly useful thing, we just disable it, but if the feature
      // is requested in the future, we should figure out and fix whatever causes the circles to vanish.
      // Setting touchZoomRotate to false disables both rotate AND zoom, so we can't use that in options
      this.Map.touchZoomRotate.disableRotation();
      this.Map.keyboard.disableRotation();
      this.Map.dragRotate.disable();

      // Can comment out if they don't want the zomm/rotate widget to appear in upper right
      // Note: we don't show the rotate compass since we've disabled that feature and it
      // just takes up real estate on a small screen:
      this.Map.addControl(
        new mapboxgl.NavigationControl({ showCompass: false })
      );

      // after the GeoJSON data is loaded, update markers on the screen on every frame
      this.onRenderFunc = () => {
        // See comments above as to why ngZone.run()
        this.ngZone.run(() => this.onRender());
      };
      this.Map.on('render', this.onRenderFunc);
    });
  }

  // Assuming our initial/min zoom are for the maxWidth,
  // calculate change to zoom if the map is smaller than that due to small screen
  // Scale assuming zoom factors are base-2 logarithmic in size.
  private calculateZoom() {
    const mapSettings = this.themeSettings.map;
    const mapActualWidth = this._mapElement.nativeElement.clientWidth;
    const maxWidth = extractNumber(mapSettings.maxWidth) || 900;
    const zoomAdjust = Math.log2(mapActualWidth / maxWidth);
    const zoomDelta = zoomAdjust - this.lastZoomAdjust;

    let minZoom =
      mapSettings.minZoomFactor !== undefined ? mapSettings.minZoomFactor : 0.8;
    minZoom += zoomAdjust;

    // Ensure minZoom allows initialZoom
    // Note: if minZoom == initialZoom, mapbox sets initial zoom level to something else (not good)
    let initialZoom = mapSettings.initialZoomFactor || -10;
    if (initialZoom <= minZoom) {
      initialZoom = minZoom + 0.001;
    }
    initialZoom += zoomAdjust;

    this.lastZoomAdjust = zoomAdjust;

    return { minZoom, initialZoom, zoomDelta };
  }

  // Compute hash string of all inputs that might determine what is shown, for display optimization purposes.
  // Must include a hash of the (possibly filtered) company count data from summary.
  // We'll assume that the data per named location doesn't get updated.
  private compputeRenderStateHash(): string {
    let hash = '';
    if (this.locationInfos) {
      hash = this.locationInfos
        .map(
          (locationInfo) =>
            `${locationInfo.location.name}=${locationInfo.sectorCounts}`
        )
        .join(';');
    }
    if (this.Map) {
      const mapCenter = this.Map.getCenter();
      hash += mapCenter.lat + mapCenter.lng + this.Map.getZoom();
    }

    return hash;
  }

  private lastStateHash = '';
  private lastZoomAdjust = -100;

  private onRender() {
    if (this.Map) {
      const { minZoom, zoomDelta } = this.calculateZoom();
      if (zoomDelta !== 0) {
        this.Map.setMinZoom(minZoom);
        this.Map.setZoom(this.Map.getZoom() + zoomDelta);
      }

      const newStateHash = this.compputeRenderStateHash();
      if (newStateHash !== this.lastStateHash) {
        this.mergeCloseLocations();
        this.tooltipHandler.reposition();
        this.lastStateHash = newStateHash;
      }
    }
  }

  private setMarkers(): void {
    this.allLocationGroups = this.locationInfos.map((loc) => {
      if (this.sectors.length === 0 && loc.sectorCounts.length > 1) {
        // When sectors are disabled, just sum into one 'sector'.
        loc.sectorCounts = [_.sum(loc.sectorCounts)];
      }
      if (loc.location.name === 'JLABS @ BE') {
        // For testing to see what one company at a location displays as, uncomment and set desired count to render:
        // loc.sectorCounts = [0];
      }

      const locationGroup = new LocationGroup(this.getMap);
      locationGroup.push(loc);

      return locationGroup;
    });
  }
}
