import { ModelBase } from './_model.base';

export class Conference extends ModelBase {
    public conferenceId: string;
    public conferenceName: string;
    public conferencePerceivedAddedValue: string;
    public opportunityId: string;
    public isAttendedConference: boolean;
    public isDeleted: boolean;
}
