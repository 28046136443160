import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { ComponentBase } from '../_component.base';
import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'create-custom-tag',
  templateUrl: './create-custom-tag.component.html',
  styleUrls: ['./create-custom-tag.component.scss'],
})
export class CreateCustomTagComponent extends ComponentBase {
  @Input() tagName: string;
  @Input() actionButtonText: string;
  @Output() tagCreated = new EventEmitter<string>();

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  public createCustomTag(): void {
    this.tagCreated.next(this.tagName);
  }
}
