import { Injectable } from '@angular/core';
import sortBy from 'lodash/sortBy';
import {
  Filter,
  Location,
  StatusDisplayItem,
  SectorDisplayItem,
  TagDisplayItem,
  MetaDataValue,
  StatusMetaData,
} from 'company-finder-common';
import {
  MenuOption,
  HierarchicalMenuOption,
  GroupedMenuOptions,
} from './menu-option.interface';
import { SearchService } from '../search/search.service';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { Summary } from '../../utilities/summary/summary';

// By design, this service is not a singleton so every consuming component must register
// it as a provider
@Injectable()
export class MenuOptionService {
  public locationOptions: MenuOption<Location>[] = [];
  public primarySectorSubSectorOptions: HierarchicalMenuOption<SectorDisplayItem>[] =
    [];
  public secondarySectorSubSectorOptions: HierarchicalMenuOption<SectorDisplayItem>[] =
    [];
  public tagOptions: MenuOption<TagDisplayItem>[] = [];
  public statusOptions: GroupedMenuOptions<StatusDisplayItem>[] = [];

  constructor(
    private _deploymentContext: DeploymentContext,
    private _searchService: SearchService
  ) {}

  public async initMenus(filter: Filter): Promise<void> {
    const summary = await this._searchService.getComprehensiveSummary();

    // locations
    this.locationOptions = sortBy(
      summary.locations.map((location) => {
        let value = false;
        if (filter && !filter.isShowAllLocations()) {
          value = !!filter.locations.find(
            (filterLocation) => filterLocation === location.name
          );
        }
        return {
          id: location.name,
          label: location.name,
          value: value,
          dataModel: location,
        };
      }),
      (locationOption) => locationOption.label
    );

    // sectors & subsectors
    // These lists are functionally identical. However, we need both otherwise
    // including, for example, a dropdown for primary and secondary sectors
    // on the same screen will result in ID collisions in the DOM and all the
    // unpredicible behavior that comes with that
    this.primarySectorSubSectorOptions = summary.sectors.map((sector) =>
      this.generateSectorOptions(filter, summary, sector, '-primary')
    );
    this.secondarySectorSubSectorOptions = summary.sectors.map((sector) =>
      this.generateSectorOptions(filter, summary, sector, '-secondary')
    );

    // tags
    const tags = await this._searchService.getTagCounts();
    this.tagOptions = sortBy(
      tags.map(({ tag }) => {
        let value = false;
        if (filter && filter.tags) {
          value = filter.tags.includes(tag);
        }
        return {
          id: tag,
          label: tag,
          value,
          dataModel: { tag, displayName: tag },
        };
      }),
      (tag) => tag
    );

    this.statusOptions = this.usedStatusGroups.map((meta) =>
      this.metaToMenuOption(meta, filter)
    );
  }

  private metaToMenuOption(
    meta: StatusMetaData,
    filter: Filter
  ): GroupedMenuOptions<StatusDisplayItem> {
    return {
      groupName: meta.groupName,
      category: meta.statusType,
      options: meta.values
        .filter((metaValue) => this.isStatusUsed(metaValue.value))
        .map((metaValue) => this.metaValueToMenuOption(metaValue, filter)),
    };
  }

  private metaValueToMenuOption(
    value: MetaDataValue,
    filter: Filter
  ): MenuOption<StatusDisplayItem> {
    return {
      id: value.value,
      label: value.display,
      value: filter?.locationStatuses.includes(value.value),
      dataModel: {
        status: value.value,
        displayName: value.display,
      },
    };
  }

  private _usedStatusGroups: StatusMetaData[];

  public get usedStatusGroups(): StatusMetaData[] {
    if (!this._usedStatusGroups) {
      this._usedStatusGroups = this._deploymentContext.statusMetadata.filter(
        (meta) =>
          meta.values.some((metaValue) => this.isStatusUsed(metaValue.value))
      );
    }
    return this._usedStatusGroups;
  }

  // FUTURE - Is it worth the effort to cache this?
  private isStatusUsed(status: string): boolean {
    return this._searchService.comprehensiveSummary.companies.some(
      (company) =>
        company.statuses.includes(status) ||
        company.locations.some((loc) => loc.locationStatuses.includes(status))
    );
  }

  private generateSectorOptions(
    filter: Filter,
    summary: Summary,
    sector: string,
    uniqueLabel: string = '-primnary'
  ) {
    let sectorValue = false;
    if (filter && !filter.isShowAllSectors()) {
      sectorValue = !!filter.primarySectors.find(
        (primarySector) => primarySector === sector
      );
    }
    const sectorOption: HierarchicalMenuOption<SectorDisplayItem> = {
      id: sector,
      label: sector,
      value: sectorValue,
      dataModel: { sectorId: sector, displayName: sector },
      children: [],
    };
    const subSectors = summary.subSectorsBySector(sector);
    subSectors.forEach((subSector) => {
      let subSectorValue = false;
      if (!filter?.isShowAllSubSectors()) {
        // NOTE: The sectorValue check here is to work around the duplicate "Lung Cancer Initiative" subSector in Pharma/Med. Devices
        subSectorValue =
          sectorValue &&
          !!filter.primarySubSectors.find(
            (primarySubSector) => primarySubSector === subSector
          );
      }
      sectorOption.children.push({
        id: `${sector}:${subSector}${uniqueLabel}`, // Unique id for DOM attribute
        label: subSector,
        value: subSectorValue,
        dataModel: {
          sectorId: subSector,
          parentSectorId: sector,
          displayName: subSector,
        },
        parent: sectorOption,
      });
      // When a parent sector has children, and not all of those children are checked, we should leave the parent unchecked
      sectorOption.value = sectorOption.value && subSectorValue;
    });
    return sectorOption;
  }
}
