import { Component, Input } from '@angular/core';

import { ReviewEditsService } from '../../services/review-edits.service';

// model imports
import { Conference, Deal, Funding } from 'company-finder-common';

@Component({
  selector: 'review-conference-deal-funding',
  templateUrl: './review-conference-deal-funding.component.html',
  styleUrls: ['./review-conference-deal-funding.component.scss'],
})
export class ReviewConferenceDealFundingComponent {
  // public properties
  @Input()
  public item: Conference | Deal | Funding;

  public constructor(protected _reviewEditsService: ReviewEditsService) {}

  // public methods
  public showReviewModal(): void {
    this._reviewEditsService.showReviewModalSubject.next(this.item);
  }

  public get showFlag(): boolean {
    const item = this.item as any;
    return !this._reviewEditsService.isModelReviewed(
      item.fundingId ?? item.dealId ?? item.conferenceId
    );
  }
}
