import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceBase } from '../../../_common/services/_service.base';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import { HealthStatus } from 'company-finder-common';

@Injectable()
export class HealthCheckService extends ServiceBase {
  constructor(
    protected _httpClient: HttpClient,
    protected _context: DeploymentContext
  ) {
    super(_httpClient, _context, '/');
  }

  public async doServerHealthCheck(): Promise<HealthStatus> {
    const result = await this._httpClient
      .get<HealthStatus>(this._apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public get appVersion(): string {
    return this._context.appVersion;
  }
}
