import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

// model imports
import {
  Company,
  urlIdentifierForCompany,
  ReviewNotification,
} from 'company-finder-common';
import {
  ReviewConfirmationStatus,
  SelfUpdateMode,
  SubmittedUpdate,
} from '../../company-update.interface';

// service imports
import { ReviewEditsService } from '../../services/review-edits.service';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { UserService } from '../../../../_common/services/user/user.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'company-update-confirmation',
  templateUrl: './company-update-confirmation.component.html',
  styleUrls: ['./company-update-confirmation.component.scss'],
})
export class CompanyUpdateConfirmationComponent
  extends ComponentBase
  implements OnInit, OnChanges
{
  // public properties
  @Input()
  public company: Company;
  public nextReview: Subject<void> = new Subject<void>();
  public returnToProfileSubject: Subject<void> = new Subject<void>();
  @Input()
  public selfUpdateMode: SelfUpdateMode;
  public SelfUpdateModes = SelfUpdateMode;
  public statuses = ReviewConfirmationStatus;
  @Input()
  public submittedUpdate: SubmittedUpdate;

  private reviews: ReviewNotification[] = [];

  public constructor(
    dc: DeploymentContext,
    private _reviewEditsService: ReviewEditsService,
    private _router: Router,
    private companyService: CompanyService,
    private userService: UserService
  ) {
    super(dc);
  }

  // public getters
  public get isEditMode(): boolean {
    return this.selfUpdateMode === SelfUpdateMode.Edit;
  }

  public get isReviewMode(): boolean {
    return this.selfUpdateMode === SelfUpdateMode.Review;
  }

  public get reviewsAvailable(): boolean {
    return this.reviews.length > 1;
  }

  public get hasApprovedAddDeleteEditUpdates(): boolean {
    if (!this.submittedUpdate?.addDeleteEditUpdates) {
      return false;
    }

    const found = this.submittedUpdate.addDeleteEditUpdates.find(
      (addDeleteEditUpdate) => this.isModelApproved(addDeleteEditUpdate.modelId)
    );
    return !!found;
  }

  public get hasApprovedDiversityUpdates(): boolean {
    if (!this.submittedUpdate?.diversityUpdates) {
      return false;
    }
    return this._reviewEditsService.isPropertyApproved('firstTimeEntrepreneur');
  }

  public get hasUpdates(): boolean {
    return (
      this.submittedUpdate.simpleUpdates?.length > 0 ||
      this.submittedUpdate.diversityUpdates?.length > 0 ||
      this.submittedUpdate.addDeleteUpdates?.length > 0 ||
      this.submittedUpdate.addDeleteEditUpdates?.length > 0
    );
  }

  public get location(): string {
    return (
      this.company.locations?.sort((a, b) => a.name.localeCompare(b.name))[0]
        ?.name ?? ''
    );
  }

  public get numberApproved(): number {
    return this._reviewEditsService.editsApproved;
  }

  public get numberDeclined(): number {
    return this._reviewEditsService.editsDeclined;
  }

  public get showAddDeleteEditUpdates(): boolean {
    return (
      this.submittedUpdate.addDeleteEditUpdates?.length > 0 &&
      (this.isEditMode ||
        (this.isReviewMode && this.hasApprovedAddDeleteEditUpdates))
    );
  }

  public get showDiversityUpdates(): boolean {
    return (
      this.submittedUpdate.diversityUpdates?.length &&
      (this.isEditMode ||
        (this.isReviewMode && this.hasApprovedDiversityUpdates))
    );
  }

  public get showUpdateList(): boolean {
    return (
      (this.isReviewMode && this.status !== ReviewConfirmationStatus.None) ||
      (this.isEditMode && this.hasUpdates)
    );
  }

  public get status(): ReviewConfirmationStatus {
    if (this._reviewEditsService.editsApproved === 0) {
      return ReviewConfirmationStatus.None;
    } else if (
      this._reviewEditsService.editsApproved ===
      this._reviewEditsService.totalEditsReviewed
    ) {
      return ReviewConfirmationStatus.All;
    } else {
      return ReviewConfirmationStatus.Partial;
    }
  }

  // public methods
  // initializes reviews -- vars used for button creation
  public async ngOnInit(): Promise<void> {
    await this.updateReviewsIfNeeded();
  }

  public async ngOnChanges(): Promise<void> {
    await this.updateReviewsIfNeeded();
  }

  public isPropertyApproved(propertyName: string): boolean {
    return this._reviewEditsService.isPropertyApproved(propertyName);
  }

  public isModelApproved(modelId: string): boolean {
    return this._reviewEditsService.isModelApproved(modelId);
  }

  public async returnToExplore(): Promise<void> {
    this._router.navigate(['/']);
  }

  public async reviewNextUpdate(): Promise<void> {
    // update reviews list after previous review is submitted
    this.reviews = await this.companyService.reviews(
      this.userService.companiesForReview
    );

    if (this.reviews.length > 0) {
      // sort the reviews by date
      this.reviews.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      // Clear the ReviewEditsService for the next guy.
      this._reviewEditsService.clear();

      // Share with Followers is maintained by the CompanyUpdateComponent, which istens for this to clear it.
      this.nextReview.next();

      // navigate to next review on the queue
      this._router.navigate([
        'company',
        'update',
        urlIdentifierForCompany(this.reviews[0].urlName),
      ]);
    }
  }

  public async returnToProfile(): Promise<void> {
    this.returnToProfileSubject.next();
  }

  public showModelUpdate(modelId: string): boolean {
    return (
      this.isEditMode || (this.isReviewMode && this.isModelApproved(modelId))
    );
  }

  public showPropertyUpdate(propertyName: string): boolean {
    return (
      this.isEditMode ||
      (this.isReviewMode && this.isPropertyApproved(propertyName))
    );
  }

  private async updateReviewsIfNeeded(): Promise<void> {
    if (this.isReviewMode) {
      this.reviews = await this.companyService.reviews(
        this.userService.companiesForReview
      );
    }
  }
}
