import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Company, Filter } from 'company-finder-common';
import _ from 'lodash';
import { SearchService } from '../../_common/services/search/search.service';
import { DeploymentContext } from '../utilities/deployment-context/deployment-context';
import { Summary } from '../utilities/summary/summary';

export interface SearchResolverResult {
  companies: Company[];
  totalCompanies: number;
  tagCounts: any;
  summary: Summary
}

@Injectable()
export class SearchResultsResolver
  implements
    Resolve<SearchResolverResult>
{
  constructor(
    private _searchService: SearchService,
    private _deploymentContext: DeploymentContext
  ) {}

  public async resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<SearchResolverResult> {
    const searchServiceFilter = this._searchService.filter;

    // ADJQ-187: This was a quick approach to deep linking to the set of companies for a location, per Chris Dubois' request
    // ADJQ-777: The location/ & filter/ routes are now deprecated, and their use discouraged, but we keep them around
    //           for backwards compatibility with any existing links in various JnJ material that may be in use.
    const location: string = route.params['location'];
    if (location) {
      searchServiceFilter.locations = location.split('+').map((l) => l.trim());
    }
    // Similar to the rationale for ADJQ-187, but could potentially support multiple filters
    const filter: string = route.params['filter'];
    if (filter) {
      searchServiceFilter[filter.trim()] = true;
    }

    if (!location && !filter) {
      if (route.queryParams['text']) {
        const predicate: string = decodeURIComponent(route.queryParams['text']);
        this._searchService.currentSearchPredicate = predicate;
      }

      const partialFilter = {};
      Object.keys(route.queryParams)
        .filter((qp) => qp !== 'text')
        .map((queryParam) => {
          const queryParamValue = route.queryParams[queryParam];
          if (Array.isArray(searchServiceFilter[queryParam])) {
            const queryParamValueAsArray = decodeURIComponent(
              queryParamValue
            ).split(this._searchService.ARRAY_QUERY_PARAM_DELIMITER);
            partialFilter[queryParam] = queryParamValueAsArray;
          } else {
            if (queryParamValue === 'false') {
              partialFilter[queryParam] = false;
            } else if (queryParamValue === 'true') {
              partialFilter[queryParam] = true;
            } else {
              partialFilter[queryParam] = decodeURIComponent(queryParamValue);
            }
          }
        });
      // Merge the query string's filter args in with the current filter
      _.merge(searchServiceFilter, partialFilter);

      // Remove unsupported search terms explicitly added but which are not allowed:
      this.removeDisabledOptions(searchServiceFilter);
    }

    const result = await this._searchService.getCompanies(0, this._searchService.initialFetchSize);
    const tagCounts = await this._searchService.getTagCounts();
    const summary = await this._searchService.getComprehensiveSummary();
  
    return {
      companies: result.companies,
      totalCompanies: result.totalCompaniesCount,
      tagCounts: tagCounts,
      summary
    };
  }

  public removeDisabledOptions(searchServiceFilter: Filter) {
    const featureSwitches = this._deploymentContext.featureSwitches;
      if (!featureSwitches.enableBlueKnight) {
        delete searchServiceFilter.isBlueKnight;
      }
      if (!featureSwitches.enableQfc) {
        delete searchServiceFilter.isQFCWinner;
      }
      if (!featureSwitches.enableSectors) {
        delete searchServiceFilter.isCrossSector;
        searchServiceFilter.primarySectors = [];
        searchServiceFilter.primarySubSectors = [];
        searchServiceFilter.secondarySectors = [];
        searchServiceFilter.secondarySubSectors = [];
      }
      if (!featureSwitches.enableJPAL) {
        delete searchServiceFilter.isMyJPALCompaniesOnly;
      }
      if (!featureSwitches.enableFollow) {
        delete searchServiceFilter.isFollowedCompaniesOnly;
      }
  }
}
