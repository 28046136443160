import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { ComponentBase } from '../../../../../../_common/components/_component.base';

// model imports
import { Summary } from '../../../../../../_common/utilities/summary/summary';

// utility/service imports
import { SearchService } from '../../../../../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../../../../../_common/services/web-analytics/web.analytics';
import {
  NgxD3Service,
  HierarchyNode,
  D3,
} from '../../../../../../_common/services/d3';
import { DeploymentContext } from '../../../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.scss'],
})
export class TagCloudComponent
  extends ComponentBase
  implements AfterViewInit, OnChanges
{
  // public properties
  public readonly svgHeight = 468;
  public readonly svgWidth = 920;
  public readonly maxBubbles = 30;
  public svg: any;
  public d3: D3;
  public svgId = 'tag-cloud-svg';
  @Input()
  public summary: Summary;
  public transformedData: any;

  // private properties
  private _baseNameFontSize = 20;
  private _baseValueFontSize = 16;
  private _baseCircleColor = 'rgb(177, 155, 235)'; // accent-04
  private _nameLabelColor = '$black';

  public constructor(
    dc: DeploymentContext,
    private _d3Service: NgxD3Service,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
    // initialize d3 & dom element
    this.d3 = this._d3Service.getD3();
  }

  // public getters
  public get hasData(): boolean {
    return this.transformedData && this.transformedData.length > 0;
  }

  // public methods
  public ngAfterViewInit(): void {
    if (this.summary) {
      this.update();
    }
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.summary && this.summary) {
      this.update();
    }
  }

  public renderSvg(data: any, height: number, width: number): void {
    this.svg = this.d3
      .select(`#${this.svgId}`)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('height', height)
      .attr('width', '100%')
      .attr('text-anchor', 'middle')
      .append('g');

    this.render(data, width, height);
  }

  // private methods
  private getFontSize(radius: number, type: 'name' | 'value'): string {
    let size = this._baseNameFontSize;
    // These radius breakpoints and font-size values are all just eyeball determinations.
    if (radius > 75) {
      size = type === 'name' ? this._baseNameFontSize : this._baseValueFontSize;
    } else if (radius > 50) {
      size =
        type === 'name'
          ? this._baseNameFontSize - 2
          : this._baseValueFontSize - 2;
    } else if (radius > 40) {
      size =
        type === 'name'
          ? this._baseNameFontSize - 6
          : this._baseValueFontSize - 4;
    } else if (radius > 25) {
      size =
        type === 'name'
          ? this._baseNameFontSize - 8
          : this._baseValueFontSize - 6;
    } else if (radius === 0) {
      size = 0;
    } else {
      size =
        type === 'name'
          ? this._baseNameFontSize - 10
          : this._baseValueFontSize - 8;
    }
    return `${size}px`;
  }

  private async handleClick(data: any): Promise<void> {
    const filter = this._searchService.filter;
    const newTagAsArray = [data.data.name];
    filter.tags = filter.tags
      ? [...filter.tags, ...newTagAsArray]
      : newTagAsArray;

    this._searchService.drilldownSubject.next(filter);

    this._webAnalyticsService.trackEvent('tagcloud-drilldown', {
      label: data.data.name,
      value: data.data.value,
    });
  }

  private render(data: any, width: number, height: number): void {
    const root = this.d3.hierarchy({ children: data }).sum((d: any) => d.value);

    this.d3
      .pack()
      .size([width - 2, height - 2])
      .padding(3)(root);

    const leaf = this.svg
      .selectAll('g')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x + 1},${d.y + 1})`);

    leaf.append('title').text((d) => `${d.data.name}: ${d.value}`);

    this.renderCircles(leaf, root);
    this.renderLabels(leaf);
  }

  private renderCircles(leaf: any, root: HierarchyNode<any>): void {
    leaf
      .append('circle')
      .attr('id', (d) => (d.leafUid = uuidv4()))
      .attr('r', (d) => d.r || 0)
      .attr('fill-opacity', (d) => Math.min(1, d.value / root.value + 0.4))
      .attr('fill', (_d) => this._baseCircleColor)
      .attr('cursor', 'pointer')
      .on('click', (d) => {
        this.d3.event.stopPropagation();
        this.handleClick(d);
      });
    leaf
      .append('clipPath')
      .attr('id', (d) => (d.clipUid = uuidv4()))
      .append('use')
      .attr('xlink:href', (d) => `#${d.leafUid}`);
  }

  private renderLabels(leaf: any): void {
    // FUTURE: Labels get cut off when they're too long and/or their circle is too small. Explore
    //         our options with regard to eliding the text, or otherwise handling this issue.
    leaf
      .append('text')
      .attr('clip-path', (d) => `url(#${d.clipUid})`)
      .attr('fill', this._nameLabelColor)
      .attr('font-size', (d) => this.getFontSize(d.r || 0, 'name'))
      .attr('font-weight', 'bold')
      .attr('pointer-events', 'none')
      .selectAll('tspan')
      .data((d) => {
        // If the current data set has no tag values, then d.data.name will be null
        // We could have thought more about whether we could have avoided entering this function in that case,
        // or whether this is a more logical return value, but returning empty string emperically stabilized it.
        if (!d.data.name) {
          return '';
        }
        const words = d.data.name.replace(/\n/g, ' ').split(' ');
        // Triangulated (given current data) on a scaling of "5.65" as an approximation for the pixel width of a character.
        // Because we aren't rendering as a fixed width font and with stair-stepped font sizes, this is by no means perfect.
        // Formula: diameter / (~5.65 pixels per character scaled by 1x-2x depending on the highest radius font breakpoint of 75)
        // This was quicker to implement than some of the other possible approaches that might be better, such as along the lines of:
        //   https://bl.ocks.org/davelandry/a39f0c3fc52804ee859a
        const approxAvailableCharacterWidth =
          (d.r * 2) / (5.65 * (Math.min(d.r / 75, 1) + 1));

        const ellidedWords = [];
        // populates ellidedWords and returns true if it actually elided
        const elideNthLine = (line: number): boolean => {
          if (words.length >= line) {
            ellidedWords.push(words[line - 1]);
            if (ellidedWords[line - 1].length > approxAvailableCharacterWidth) {
              ellidedWords[line - 1] =
                ellidedWords[line - 1].substring(
                  0,
                  approxAvailableCharacterWidth - 3
                ) + '...';
              return true;
            }
          }
          return false;
        };

        // Stop rendering words as soon as one had to be elided
        // Render at most the first 3 words, and make the 3rd word purely "..." if there are 4+ words.
        if (!elideNthLine(1)) {
          if (!elideNthLine(2)) {
            if (!elideNthLine(3)) {
              if (words.length > 3) {
                ellidedWords[2] = '...';
              }
            }
          }
        }
        return ellidedWords;
      })
      .enter()
      .append('tspan')
      .attr('x', 0)
      .attr('y', (d, i, nodes) => `${i - nodes.length / 2 + 0.8}em`)
      .text((d) => d);
  }

  private transformData(summary: Summary): any {
    const data = [];
    // FUTURE: Push this down into the Summary object?
    summary.companies.forEach((company) => {
      if (company.tags && company.tags.length > 0) {
        company.tags.map((tag) => {
          // Tag comparisons should be case-insensitive.
          const existingItem = data.find(
            (item) => item.name.toLowerCase() === tag.toLowerCase()
          );
          if (existingItem) {
            existingItem.value = existingItem.value + 1;
          } else {
            data.push({
              name: tag,
              value: 1,
            });
          }
        });
      }
    });

    // Sort the array descending by tag count, and return the first maxBubbles elements, at most.
    // FUTURE: Consider special handling for ties?
    return data.sort((a, b) => b.value - a.value).slice(0, this.maxBubbles);
  }

  private update(): void {
    // Remove any previously rendered svg elements
    if (undefined !== this.svg) {
      this.svg.remove();
    }

    this.transformedData = this.transformData(this.summary);
    // FUTURE: Note that this relies heavily on the app using a fixed layout.
    //         If we need a responsive layout later, this will require attention.
    this.renderSvg(this.transformedData, this.svgHeight, this.svgWidth);
  }
}
