import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import * as moment from 'moment-timezone';

import {
  Company,
  UpdateNotification,
  UpdateStatus,
} from 'company-finder-common';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends ComponentBase implements OnInit {
  // public properties
  public retractionSubject: Subject<void> = new Subject<void>();
  public updateNotification: UpdateNotification;

  // private properties
  private company: Company;

  public constructor(
    dc: DeploymentContext,
    private _companyService: CompanyService,
    private _route: ActivatedRoute
  ) {
    super(dc);
  }

  // public getters
  public get hasNotification(): boolean {
    return !!this.updateNotification;
  }

  public get hasNotificationComments(): boolean {
    return this.updateNotification && !!this.updateNotification.comments;
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this.company = this._route.snapshot.data.company;
    await this.getNotification();
  }

  public async getNotification(): Promise<void> {
    this.updateNotification = await this._companyService.notification(
      this.company.opportunityIdPrimary
    );
  }

  public isSaved(): boolean {
    return this.updateNotification?.status === UpdateStatus.Saved;
  }

  public isPending(): boolean {
    return this.updateNotification?.status === UpdateStatus.Pending;
  }

  public isApproved(): boolean {
    return this.updateNotification?.status === UpdateStatus.Approved;
  }

  public isDeclined(): boolean {
    return this.updateNotification?.status === UpdateStatus.Declined;
  }

  public formatDate(): string {
    return moment.utc(this.updateNotification.date).local().format('MMM DD');
  }

  public async retract(): Promise<void> {
    await this._companyService.deletePendingUpdateByOpportunityId(
      this.company.opportunityIdPrimary
    );
    await this.getNotification();
    this.retractionSubject.next();
  }
}
