import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '../../_common/components/_component.base';

// model imports
import { TagWithCount } from 'company-finder-common';

// service imports
import { Router, NavigationStart } from '@angular/router';
import { SearchService } from '../../_common/services/search/search.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { AuthnService } from '../../_common/services/authn/authn.service';
import Swiper from 'swiper';
import { Summary } from '../../_common/utilities/summary/summary';

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent
  extends ComponentBase
  implements OnInit, AfterViewInit
{
  // public properties
  public comprehensiveSummary: Summary;
  @Input()
  public summary: Summary;
  @Input()
  public tagCounts: TagWithCount[];

  public constructor(
    router: Router,
    private searchService: SearchService,
    dc: DeploymentContext,
    private _authnService: AuthnService
  ) {
    super(dc);
    router.events.subscribe((event: NavigationStart) => {
      // Only clear the filter when returning to the main/overview page
      if (event.navigationTrigger === 'popstate' && event.url === '/') {
        searchService.filter.clear();
      }
    });
  }

  ngAfterViewInit(): void {
    const swiper = document.querySelector('.middle-swiper') as {
      swiper?: Swiper;
    };
    // The DOM may have changed (specifically removing tiles)
    // This could cause issues, especially around pagination,
    // so get the swiper to update.
    swiper?.swiper?.update();
  }

  async ngOnInit(): Promise<void> {
    this.comprehensiveSummary =
      await this.searchService.getComprehensiveSummary();
  }

  public get isInternal(): boolean {
    return this._authnService.isInternal;
  }

  public get showDeals(): boolean {
    return (
      this.isInternal &&
      this.featureSwitches.enableSectors &&
      this.featureSwitches.enableDealsWidget
    );
  }

  public get showNewCompanies(): boolean {
    return (
      this.featureSwitches.enableShowNewCompanies &&
      (this._authnService.isExternalUser ||
        (!this._deploymentContext.featureSwitches.enablePaywall &&
          !this._authnService.isAuthenticatedUser))
    );
  }
}
