import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import { IframeResizerService } from '../../services/iframe/iframe.resizer.service';
import { iframeResizer } from '@iframe-resizer/child';

/**
 * This component presents a blank modal with a full-screen overlay covering the content behind.
 * It abstracts away the details of sizing and positioning the modal within the viewport, which
 * is more complicated when the app is hosted in an iframe.
 */
@Component({
  selector: 'app-modal-overlay',
  templateUrl: './modal-overlay.component.html',
  styleUrls: ['./modal-overlay.component.scss'],
})
export class ModalOverlayComponent implements OnDestroy, OnInit {
  @Input()
  public borderRadius = 4;
  @Input()
  public maxHeight = 680;
  @Input()
  public heightPercent = 80;
  @Input()
  public width = 720;
  @Input()
  public showModal: boolean;
  @ViewChild('theModal')
  private theModal: ElementRef;
  private iframeSubscription: Subscription;
  private parentProps: iframeResizer.ParentProps;

  public constructor(
    private context: DeploymentContext,
    private iframeService: IframeResizerService
  ) { }

  private get offsetTop(): number {
    return this.parentProps.viewport.pageTop + this.parentProps.iframe.top;
  }

  private get viewportHeight(): number {
    return this.parentProps.viewport.height;
  }

  public get modalHeight() {
    return this.theModal?.nativeElement.offsetHeight ?? 0;
  }

  public get top(): string {

    if (!this.context.hosted() || this.modalHeight > this.parentProps.viewport.height) {
      // Either we are not iframed and don't need a top or 
      // the modal does not fit in the viewport at any position,
      // which we can't really resolve cleanly
      return undefined;
    }

    const calcTop = (this.viewportHeight - this.offsetTop - this.modalHeight) / 2 + this.offsetTop;
    let maxTop = Math.max(calcTop, this.offsetTop); // ensure the top is on the screen

    while (maxTop + this.modalHeight > this.parentProps.viewport.height) {
      // This means the bottom will be below the viewport in this position, but 
      // positions exist for which it is not, so move it up
      maxTop--;
    }

    return `${maxTop}px`;
  }

  public get parameterizedStyles(): Partial<CSSStyleDeclaration> {
    return {
      position: 'absolute',
      maxHeight: `${this.maxHeight}px`,
      height: `${this.heightPercent}%`,
      width: `${Math.min(this.width, 0.9 * screen.width)}px`,
      borderRadius: `${this.borderRadius}px`,
      top: this.top
    };
  }

  public ngOnInit(): void {
    this.iframeSubscription = this.iframeService.subscribeIfIframed((props) =>
      this.handleIframeEvent(props)
    )
  };

  public ngOnDestroy(): void {
    this.iframeSubscription?.unsubscribe();
  }

  private handleIframeEvent(windowProperties: iframeResizer.ParentProps) {
    this.parentProps = windowProperties;
  }
}
