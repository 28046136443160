import { Injectable } from '@angular/core';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import {
  Company,
  Conference,
  Deal,
  Funding,
  ICompanyUpdate,
  IConferenceUpdate,
  IDealUpdate,
  IFundingUpdate,
  IUpdateField,
  LocalizedTextIds,
  ProcessedStatus,
  UpdateStatus,
} from 'company-finder-common';
import _ from 'lodash';
import {
  AddDeleteEditUpdate,
  EditedContent,
  ReviewableUpdate,
  ReviewableUpdateField,
  SelfUpdateMode,
  SubmittedUpdate,
  TagReviewModification,
  TagReviewModificationType,
} from '../company-update.interface';
import {
  compareCompanies,
  Diff,
  isUpdateAChange,
  TAG_SEPARATOR,
} from '../utils';
import {
  ApplicationContext,
  NewConferenceDealOrFundingPrefix,
} from '../../../_common/utilities/application-context/application-context';
import { CompanyService } from '../../../_common/services/company/company.service';
import { ReviewEditsService } from './review-edits.service';
import {
  ensureMappingForBooleans,
  formatSubmissionContent,
  getBooleanAsNumber,
} from '../utils/data-format-utils';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { TitleAndMetadata } from '../../../_common/utilities/title-and-metadata/title-and-metadata';
import { AuthnService } from '../../../_common/services/authn/authn.service';
import { LogService } from '../../../_common/services/log/log.service';

@Injectable()
export class CompanyUpdateService {
  private _company: Company;
  private _companyWithPending: Company;

  public get company(): Company {
    return this._company;
  }
  public set company(value: Company) {
    this._company = value;
  }

  public get companyWithPending(): Company {
    return this._companyWithPending;
  }
  public set companyWithPending(value: Company) {
    this._companyWithPending = value;
  }

  public companyClone: Company;
  public companyWithMostRecentEdit: Company;
  public companyBeforeAnyChanges: Company;
  public isEditingProperty: { [propName: string]: boolean } = {};
  public isEditingReviewComments = false;
  public updateForReview: ReviewableUpdate;
  public selfUpdateMode: SelfUpdateMode;
  public haveConferencesAttendedChanged = false;
  public haveAnticipatedConferencesChanged = false;
  public haveDealsChanged = false;
  public haveFundingsChanged = false;
  public readonly progressUpdateString = 'progressUpdate';
  public pendingUpdate: ICompanyUpdate;
  public progressUpdate: string;
  public progressUpdateDate: Date;
  public reviewComments: string;
  public submittedUpdate: SubmittedUpdate = {
    simpleUpdates: [],
    diversityUpdates: [],
    addDeleteUpdates: [],
    addDeleteEditUpdates: [],
  };
  public tagReviews: TagReviewModification[] = [];
  public updateSubmitted = false;
  public reviewSubmitted = false;
  public isShareWithFollowers: boolean = undefined;

  public conferenceToModify: Conference = new Conference();
  public dealToModify: Deal = new Deal();
  public fundingToModify: Funding = new Funding();

  constructor(
    private _deploymentContext: DeploymentContext,
    private _companyService: CompanyService,
    private _reviewEditsService: ReviewEditsService,
    private _authnService: AuthnService,
    private titleAndMetadata: TitleAndMetadata,
    private logService: LogService
  ) {}

  public initializeCompany(company: Company): void {
    this.updateForReview = {
      updateFields: [],
      conferenceUpdates: [],
      dealUpdates: [],
      fundingUpdates: [],
    };
    // Grab the company data.
    this.company = _.merge(new Company(), company);
    this.titleAndMetadata.setPageTitle(this.company.name);
    // Clone the stored company data to diff against later.
    this.companyClone = this.cloneCompany(this.company);

    this.companyBeforeAnyChanges = _.merge({}, this.company);
    this.companyWithPending = _.merge({}, this.company);
    this.companyWithMostRecentEdit = _.merge({}, this.company);
  }

  public async initializeUpdateData(
    initializeCompanyBeforeUpdates: boolean = true
  ): Promise<void> {
    this.haveConferencesAttendedChanged = false;
    this.haveAnticipatedConferencesChanged = false;
    this.haveDealsChanged = false;
    this.haveFundingsChanged = false;

    await this.applyApprovedUnprocessedUpdateData(
      initializeCompanyBeforeUpdates
    );
    await this.applySavedOrPendingUpdateData();

    this.companyWithPending = this.cloneCompany(this.company);
    this.companyWithMostRecentEdit = this.cloneCompany(this.company);
  }

  public async saveCompany(doSubmit: boolean = true): Promise<ICompanyUpdate> {
    // If there's a progressUpdate value, make sure the company gets it.
    if (this.progressUpdate) {
      this.company.progressUpdate = this.progressUpdate.trim();
    }

    const companyUpdate = this.getCompanyUpdateForCurrentSession();
    ensureMappingForBooleans(companyUpdate);
    // Don't submit any empty progressUpdates
    this.ensureProgressUpdate(companyUpdate);
    this.checkUpdatesForUI(companyUpdate);

    // If just doing a save and not submitting, use Saved status instead of Pending
    companyUpdate.status = doSubmit ? UpdateStatus.Pending : UpdateStatus.Saved;

    const savedCompanyUpdate = await this._companyService.updateCompany(
      companyUpdate
    );

    // pending update maybe should be renamed to savedOrPendingUpdate,
    // but need to verify that we don't need to distinguish these.
    // If we do, we can always use a method which looks at .status to see if it is Saved or Pending.
    this.pendingUpdate = savedCompanyUpdate;

    this.companyClone = this.cloneCompany(this.company);
    this.companyWithPending = _.merge({}, this.company);
    this.companyWithMostRecentEdit = _.merge({}, this.company);

    return savedCompanyUpdate;
  }

  public async updateCompany(): Promise<void> {
    await this.saveCompany(true);

    this.titleAndMetadata.setPageTitle(this.company.name);

    this.updateSubmitted = true;
    this._deploymentContext.ensureScrolledToTop();
  }

  public async submitReview(): Promise<void> {
    // Manage and review changes to the tags
    this.tagReviews.map((tagReview) => {
      if (tagReview.value) {
        if (tagReview.modificationType === TagReviewModificationType.Preserve) {
          this.company.tags.push(tagReview.tag);
        }
        if (tagReview.modificationType === TagReviewModificationType.Exclude) {
          _.remove(this.company.tags, (aTag) => aTag === tagReview.tag);
        }
      }
    });

    // Compose the update
    const companyUpdate = this.getCompanyUpdateForCurrentSession();

    ensureMappingForBooleans(companyUpdate);
    // Don't submit any empty progressUpdates
    this.ensureProgressUpdate(companyUpdate);

    // This check makes sure the confirmation screen gets any edits the reviewer applied.
    this.checkUpdatesForUI(companyUpdate);
    companyUpdate.approverEmailAddress = this._authnService.userId;

    // FUTURE: Consider pushing the comments & isNoteworthy up into the main company update form?
    //         Note that they would only be available to reviewers, not editors.
    companyUpdate.comments = this.reviewComments;
    companyUpdate.isNoteworthy = this.isShareWithFollowers;
    await this._reviewEditsService.submitReview(companyUpdate);
    this.reviewSubmitted = true;
  }

  public getCompanyUpdateForCurrentSession(): ICompanyUpdate {
    if (this.isUpdateFieldSet(this.progressUpdateString)) {
      // We're working on a pending update, so allow an empty progressUpdate to clear the pending progressUpdate.
      if (
        this.progressUpdate !== this.companyWithMostRecentEdit.progressUpdate
      ) {
        this.companyWithMostRecentEdit.progressUpdate =
          this.progressUpdate?.trim();
      }
    } else {
      // No pending update, so don't allow an empty progressUpdate because they aren't clearing anything.
      if (
        this.progressUpdate &&
        this.progressUpdate !== this.companyWithPending.progressUpdate
      ) {
        this.companyWithMostRecentEdit.progressUpdate =
          this.progressUpdate?.trim();
      }
    }

    return this.buildCompanyUpdate(
      this.companyWithMostRecentEdit,
      this.companyWithPending
    );
  }

  // We don't want empty progressUpdates submitted, but we do want to allow the user to remove
  // the update in a pending update without having to retract the entire submission. To accomplish
  // this we let all updates get queued up, but then prune the progressUpdate if it's empty, so we
  // don't have to keep track of the original, a pending one, and the cleared one separately.
  // Management of the various company caches are more complex than we want to deal with right now,
  // so it's easier just to do it immediately prior to the submission.
  private ensureProgressUpdate(companyUpdate: ICompanyUpdate): void {
    const progressUpdateUpdateField = companyUpdate.updateFields.find(
      (updateField) => updateField.name === this.progressUpdateString
    );
    if (progressUpdateUpdateField && !progressUpdateUpdateField.newValue) {
      _.remove(
        companyUpdate.updateFields,
        (updateField) => updateField.name === progressUpdateUpdateField.name
      );
      this.updateForReview.updateFields[this.progressUpdateString] = undefined;
    }
  }

  public async applyApprovedUnprocessedUpdateData(
    initializeCompanyBeforeUpdates: boolean = true
  ): Promise<void> {
    // FUTURE - This should likely be also looking for updates that have been approved and processed
    // since the last ETL process was started since those will not be reflected here and
    // the reverse ETL process runs immediately on approval.
    // The current logic will show all approved updates if the ETL is not running/disabled, but not
    // updates that have sucessfully completed the reverse ETL but have not been round-tripped yet.

    // Get any approved company updates that haven't had their reverse ETL processing started
    const approvedUnprocessedUpdates =
      await this._companyService.getApprovedUnprocessedUpdatesByOpportunityId(
        this.company.opportunityIdPrimary
      );
    // These company updates come back oldest to newest, and should be applied that way.
    // More recent updates will override older ones.
    if (approvedUnprocessedUpdates) {
      approvedUnprocessedUpdates.forEach((approvedUnprocessedUpdate) => {
        this.applyUpdate(approvedUnprocessedUpdate, this.company);
      });
      // Update the companyClone, since later diffs/updates should not include these approved "pre-processed" updates.
      this.companyClone = this.cloneCompany(this.company);

      // When a user submits the update we want the "original value" to still be the original value
      // when they first loaded the page to avoid confusion
      if (initializeCompanyBeforeUpdates) {
        // Update the companyBeforeAnyChanges because we're treating approved updates as if they've already been processed.
        this.companyBeforeAnyChanges = this.cloneCompany(this.company);
      }
    }
  }

  public async applySavedOrPendingUpdateData(): Promise<void> {
    this.pendingUpdate =
      await this._companyService.getSavedOrPendingUpdateByOpportunityId(
        this.company.opportunityIdPrimary
      );
    if (this.pendingUpdate) {
      // If there is a pending update, apply the update to the base company
      this.applyUpdate(this.pendingUpdate, this.company);

      this.checkUpdatesForUI(this.pendingUpdate);

      this.haveConferencesAttendedChanged =
        !!this.pendingUpdate.conferenceUpdates?.find(
          (conferenceUpdate) => conferenceUpdate.isAttendedConference
        );
      this.haveAnticipatedConferencesChanged =
        !!this.pendingUpdate.conferenceUpdates?.find(
          (conferenceUpdate) => !conferenceUpdate.isAttendedConference
        );
      this.haveDealsChanged = this.pendingUpdate.dealUpdates?.length > 0;
      this.haveFundingsChanged = this.pendingUpdate.fundingUpdates?.length > 0;

      // Initialize progressUpdate, because it's managed a little differently than the other company properties.
      if (this.isUpdateFieldSet(this.progressUpdateString)) {
        this.progressUpdate =
          this.updateForReview.updateFields[this.progressUpdateString].newValue;
        const progressUpdateUpdateField = this.pendingUpdate.updateFields.find(
          (item) => item.name === this.progressUpdateString
        );
        if (progressUpdateUpdateField) {
          const date = new Date(progressUpdateUpdateField.updatedDate);
          this.progressUpdateDate = (date as any).toLocaleString('en-US', {
            dateStyle: 'medium',
          });
        }
      }
    } else {
      this.progressUpdate = undefined;
      this.updateForReview = {
        updateFields: [],
        conferenceUpdates: [],
        dealUpdates: [],
        fundingUpdates: [],
      };
    }
  }

  public checkUpdatesForUI(update: ICompanyUpdate): void {
    this.submittedUpdate = {
      simpleUpdates: [],
      diversityUpdates: [],
      addDeleteUpdates: [],
      addDeleteEditUpdates: [],
    };
    function newUpdateField(
      updateField: IUpdateField,
      displayName: string = null
    ): ReviewableUpdateField {
      return {
        displayName: displayName || updateField.name,
        isSet: isUpdateAChange(updateField),
        newValue: updateField.newValue,
        oldValue: updateField.oldValue,
      };
    }
    if (update?.updateFields) {
      update.updateFields.map((updateField) => {
        // Handle new values that might be booleans, zeroes, and things like that which might "hide" the update
        this.updateForReview.updateFields[updateField.name] = newUpdateField(
          updateField,
          this.displayNameForProperty(updateField.name)
        );
        if (updateField.name === 'tags') {
          // Restore the separated values into string[]
          const newValueArray = updateField.newValue.split(TAG_SEPARATOR);
          const oldValueArray = updateField.oldValue.split(TAG_SEPARATOR);
          const added = newValueArray.filter(
            (item) => !oldValueArray.includes(item)
          );
          const deleted = oldValueArray.filter(
            (item) => !newValueArray.includes(item)
          );
          if (added.length || deleted.length) {
            this.submittedUpdate.addDeleteUpdates.push({
              added: added,
              deleted: deleted,
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
            });
          }
        } else {
          const isDei = this.isDeiProperty(updateField.name);
          const isFirstTimeEntrepreneur = this.isFirstTimeEntrepreneurProperty(
            updateField.name
          );
          let title: string;
          if (updateField.name === this.progressUpdateString) {
            // NOTE: GVio confirmed that Brittany said that progressUpdate's title should always be as hardcoded below.
            title = this._deploymentContext.Loc(
              LocalizedTextIds.CompanyUpdateServiceNewUpdate
            );
          }
          if (isFirstTimeEntrepreneur) {
            const val = parseInt(updateField.newValue, 10);
            const booleanValue = isNaN(val)
              ? updateField.newValue.toString() === 'true'
                ? true
                : false
              : !!val;
            this.submittedUpdate.diversityUpdates.push({
              booleanValue: booleanValue,
              content: updateField.name,
              isLogo: false,
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
              title: title,
            });
          } else if (isDei) {
            const content = updateField.newValue
              // removes any country prefix that precedes a " - ", accounting for ; separated values
              // NOTE: This needs to be kept in sync with jnj-information.component.ts applyDeiCountryPrefixes
              .replace(/[^;]* - /gm, '')
              .replace(/;/gm, '; '); // use a regex to ensure all occurences are replaced
            // FUTURE: At least one dei option has a different display string than value, and this will show the value to the user.
            //  Consider a reverse lookup or some other approach. Wasn't worth the effort at the time since the values are similar enough.
            this.submittedUpdate.simpleUpdates.push({
              content: content,
              isLogo: updateField.name === 'logoBase64',
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
              title: title,
            });
          } else {
            this.submittedUpdate.simpleUpdates.push({
              content: updateField.newValue,
              isLogo: updateField.name === 'logoBase64',
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
              title: title,
            });
          }
        }
      });
    }
    if (update?.conferenceUpdates) {
      const addDeleteEditUpdate = {
        added: [],
        deleted: [],
        edited: [],
        displayName: this._deploymentContext.Loc(LocalizedTextIds.Conferences),
      } as AddDeleteEditUpdate;
      // Rather than repeat all this logic, combine the two conference arrays. The company update
      // doesn't care which array a given conference belongs to, just what its conferenceId is.
      const allConferences = [
        ...(this.companyClone.conferencesAttended || []),
        ...(this.companyClone.anticipatedConferences || []),
      ];
      update.conferenceUpdates.map((conferenceUpdate) => {
        if (!this.updateForReview.conferenceUpdates[conferenceUpdate.modelId]) {
          this.updateForReview.conferenceUpdates[conferenceUpdate.modelId] = {
            updateFields: [],
          };
        }
        let editedConferenceUpdate;
        if (conferenceUpdate.isDeletedConference) {
          const deletedConference = allConferences.find(
            (conference) => conference.conferenceId === conferenceUpdate.modelId
          );
          if (deletedConference) {
            // Existing conferences would be in the companyClone, new ones in the user's current session would not,
            // and don't need to be included in the addDeleteEditUpdate.
            addDeleteEditUpdate.deleted.push(deletedConference.conferenceName);
          }
        } else {
          conferenceUpdate.updateFields.map((updateField) => {
            this.updateForReview.conferenceUpdates[
              conferenceUpdate.modelId
            ].updateFields[updateField.name] = newUpdateField(updateField);
            // Short-circuit 'added,' because we only need to display the conferenceName, similar to delete above.
            if (
              conferenceUpdate.isNewConference &&
              updateField.name === 'conferenceName'
            ) {
              addDeleteEditUpdate.added.push(updateField.newValue);
            }
            // 'Edited' conferences, however, have to show what changed.
            if (
              !conferenceUpdate.isNewConference &&
              !conferenceUpdate.isDeletedConference
            ) {
              if (!editedConferenceUpdate) {
                editedConferenceUpdate = {
                  updates: [],
                } as EditedContent;
              }
              const conference = allConferences.find(
                (c) => c.conferenceId === conferenceUpdate.modelId
              );
              // This is an edited conference
              if (updateField.name === 'conferenceName') {
                editedConferenceUpdate.title = updateField.newValue;
              } else {
                editedConferenceUpdate.title = conference.conferenceName || '';
              }
              editedConferenceUpdate.updates.push({
                content: updateField.newValue,
                displayName: this.displayNameForProperty(updateField.name),
              });
            }
          });
        }
        if (editedConferenceUpdate) {
          addDeleteEditUpdate.edited.push(editedConferenceUpdate);
        }
        addDeleteEditUpdate.modelId = conferenceUpdate.modelId;
      });
      if (
        addDeleteEditUpdate.added.length ||
        addDeleteEditUpdate.deleted.length ||
        addDeleteEditUpdate.edited.length
      ) {
        this.submittedUpdate.addDeleteEditUpdates.push(addDeleteEditUpdate);
      }
    }
    if (update?.dealUpdates) {
      const addDeleteEditUpdate = {
        added: [],
        deleted: [],
        edited: [],
        displayName: this._deploymentContext.Loc(
          LocalizedTextIds.CompanyDetailsDeals
        ),
      } as AddDeleteEditUpdate;
      update.dealUpdates.map((dealUpdate) => {
        if (!this.updateForReview.dealUpdates[dealUpdate.modelId]) {
          this.updateForReview.dealUpdates[dealUpdate.modelId] = {
            updateFields: [],
          };
        }
        let editedDealUpdate;
        if (dealUpdate.isDeletedDeal) {
          const deletedDeal = this.companyClone.deals.find(
            (deal) => deal.dealId === dealUpdate.modelId
          );
          if (deletedDeal) {
            // Existing deals would be in the companyClone, new ones in the user's current session would not,
            // and don't need to be included in the addDeleteEditUpdate.
            addDeleteEditUpdate.deleted.push(deletedDeal.dealParty);
          }
        } else {
          dealUpdate.updateFields.map((updateField) => {
            this.updateForReview.dealUpdates[dealUpdate.modelId].updateFields[
              updateField.name
            ] = newUpdateField(updateField);
            // Short-circuit 'added,' because we only need to display the dealParty, similar to delete above.
            if (dealUpdate.isNewDeal && updateField.name === 'dealParty') {
              addDeleteEditUpdate.added.push(updateField.newValue);
            }
            // 'Edited' deals, however, have to show what changed.
            if (!dealUpdate.isNewDeal && !dealUpdate.isDeletedDeal) {
              if (!editedDealUpdate) {
                editedDealUpdate = {
                  updates: [],
                } as EditedContent;
              }
              const deal = this.company.deals.find(
                (d) => d.dealId === dealUpdate.modelId
              );
              // This is an edited deal
              if (updateField.name === 'dealParty') {
                editedDealUpdate.title = updateField.newValue;
              } else {
                editedDealUpdate.title = deal.dealParty || '';
              }
              const content = formatSubmissionContent(
                updateField.name,
                updateField.newValue,
                deal.amountCurrency
              );
              editedDealUpdate.updates.push({
                content: content,
                displayName: this.displayNameForProperty(updateField.name),
              });
            }
          });
        }
        if (editedDealUpdate) {
          addDeleteEditUpdate.edited.push(editedDealUpdate);
        }
        addDeleteEditUpdate.modelId = dealUpdate.modelId;
      });
      if (
        addDeleteEditUpdate.added.length ||
        addDeleteEditUpdate.deleted.length ||
        addDeleteEditUpdate.edited.length
      ) {
        this.submittedUpdate.addDeleteEditUpdates.push(addDeleteEditUpdate);
      }
    }
    if (update?.fundingUpdates) {
      const addDeleteEditUpdate = {
        added: [],
        deleted: [],
        edited: [],
        displayName: this._deploymentContext.Loc(
          LocalizedTextIds.CompanyDetailsFunding
        ),
      } as AddDeleteEditUpdate;
      update.fundingUpdates.map((fundingUpdate) => {
        if (!this.updateForReview.fundingUpdates[fundingUpdate.modelId]) {
          this.updateForReview.fundingUpdates[fundingUpdate.modelId] = {
            updateFields: [],
          };
        }
        let editedFundingUpdate;
        if (fundingUpdate.isDeletedFunding) {
          const deletedFunding = this.companyClone.funding.find(
            (f) => f.fundingId === fundingUpdate.modelId
          );
          if (deletedFunding) {
            // Existing funding would be in the companyClone, new ones in the user's current session would not,
            // and don't need to be included in the addDeleteEditUpdate.
            addDeleteEditUpdate.deleted.push(deletedFunding.fundingParty);
          }
        } else {
          fundingUpdate.updateFields.map((updateField) => {
            this.updateForReview.fundingUpdates[
              fundingUpdate.modelId
            ].updateFields[updateField.name] = newUpdateField(updateField);
            // Short-circuit 'added,' because we only need to display the fundingParty, similar to delete above.
            if (
              fundingUpdate.isNewFunding &&
              updateField.name === 'fundingParty'
            ) {
              addDeleteEditUpdate.added.push(updateField.newValue);
            }
            // 'Edited' fundings, however, have to show what changed.
            if (
              !fundingUpdate.isNewFunding &&
              !fundingUpdate.isDeletedFunding
            ) {
              if (!editedFundingUpdate) {
                editedFundingUpdate = {
                  updates: [],
                } as EditedContent;
              }
              const funding = this.company.funding.find(
                (f) => f.fundingId === fundingUpdate.modelId
              );
              // This is an edited funding
              if (updateField.name === 'fundingParty') {
                editedFundingUpdate.title = updateField.newValue;
              } else {
                editedFundingUpdate.title = funding.fundingParty || '';
              }
              const content = formatSubmissionContent(
                updateField.name,
                updateField.newValue,
                funding.raisedCurrency
              );
              editedFundingUpdate.updates.push({
                content: content,
                displayName: this.displayNameForProperty(updateField.name),
              });
            }
          });
        }
        if (editedFundingUpdate) {
          addDeleteEditUpdate.edited.push(editedFundingUpdate);
        }
        addDeleteEditUpdate.modelId = fundingUpdate.modelId;
      });
      if (
        addDeleteEditUpdate.added.length ||
        addDeleteEditUpdate.deleted.length ||
        addDeleteEditUpdate.edited.length
      ) {
        this.submittedUpdate.addDeleteEditUpdates.push(addDeleteEditUpdate);
      }
    }
  }

  public composeCompanyUpdate(diffs: Diff): ICompanyUpdate {
    if (!diffs) {
      return null;
    }
    const keys = Object.keys(diffs);

    let companyUpdateFields: IUpdateField[] = [];
    let conferenceUpdates: IConferenceUpdate[];
    let dealUpdates: IDealUpdate[];
    let fundingUpdates: IFundingUpdate[];
    // An approver may not have made any further edits, but we need a CompanyUpdate to which
    // we can apply the approverEmailAddress when the approver eventually approves the update.
    // We can't bail if we have no diff keys, as we were doing prior to ADJQ-892, but we can
    // optimize away the update composition if we have no diff keys.
    if (keys?.length > 0) {
      companyUpdateFields = this.composeUpdateFields(
        keys,
        this.company,
        this.companyBeforeAnyChanges
      );
      conferenceUpdates = diffs.hasConferenceChanges ? [] : undefined;
      if (diffs.conferencesAttended || diffs.anticipatedConferences) {
        this.getConferenceUpdates(diffs, conferenceUpdates);
      }
      dealUpdates = diffs.deals ? [] : undefined;
      if (diffs.deals) {
        this.getDealOrFundingUpdates(
          diffs,
          'deals',
          'dealId',
          'dealParty',
          dealUpdates
        );
      }
      fundingUpdates = diffs.funding ? [] : undefined;
      if (diffs.funding) {
        this.getDealOrFundingUpdates(
          diffs,
          'funding',
          'fundingId',
          'fundingParty',
          fundingUpdates
        );
      }
    }

    const companyUpdate: ICompanyUpdate = {
      id: undefined,
      createdDate: undefined,
      updatedDate: undefined,
      modelId: this.company.opportunityIdPrimary,
      approverEmailAddress: undefined,
      comments: undefined,
      isNoteworthy: false,
      processed: ProcessedStatus.NotStarted,
      status: UpdateStatus.Pending,
      updateFields: companyUpdateFields,
      conferenceUpdates: conferenceUpdates,
      dealUpdates: dealUpdates,
      fundingUpdates: fundingUpdates,
    };
    return companyUpdate;
  }

  public composeUpdateFields(
    keys: string[],
    updated: Company | Conference | Deal | Funding,
    original: Company | Conference | Deal | Funding
  ): IUpdateField[] {
    return keys.reduce((result, k) => {
      // NOTE: We used to prevent empty progressUpdates, but we needed to change this.
      // * We need to support a way for a user to back out a progressUpdate in their pending company update
      // * We are now doing a better job of managing changes to progressUpdate in the UI
      // * We extract an empty progressUpdate after we've gone through the various machinations, but before we submit the company update
      if (
        k !== 'conferencesAttended' &&
        k !== 'anticipatedConferences' &&
        k !== 'deals' &&
        k !== 'funding'
      ) {
        const keyForValue =
          k === 'companyContactTitle' ? 'companyContact.title' : k;
        const newValue =
          keyForValue !== 'tags'
            ? this.getValueByKey(updated, keyForValue)
            : updated[keyForValue].sort().join(TAG_SEPARATOR);
        let oldValue;
        if (original) {
          oldValue =
            keyForValue !== 'tags'
              ? this.getValueByKey(original, keyForValue)
              : original[keyForValue].sort().join(TAG_SEPARATOR);
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention
        let backend_destination =
          updated instanceof Conference ? 'blueKnight' : 'jForce';
        if (updated instanceof Company) {
          const isBk = Company.isBlueKnightProperty(k);
          if (isBk) {
            backend_destination = 'blueKnight';
          }
        }
        result.push({
          emailAddress: this._authnService.userId,
          name: k,
          nav_table_name: 'company_update',
          newValue: newValue,
          oldValue: oldValue,
          processed: ProcessedStatus.NotStarted,
          status: UpdateStatus.Pending,
          // FUTURE: backend_destination is really for the benefit of the ETL team, and probably belongs
          // as close to the persistence layer as possible, but it felt potentially confusing & error
          // prone to separate it from its UpdateField brethren. At some point it might make sense to
          // push a lot of the company update composition to the API, which would mitigate these concerns,
          // albeit at the price of a fairly significant refactor, and possible loss of information on the
          // client which might be currently benefiting from client-side company update composition.
          backend_destination: backend_destination,
        });
      }
      return result;
    }, []);
  }

  public getCurrentUpdate(): ICompanyUpdate {
    return this.buildCompanyUpdate(this.company, this.companyClone);
  }

  public isCurrentUpdateApproved(
    notificationsComponent: NotificationsComponent
  ): boolean {
    return (
      notificationsComponent &&
      notificationsComponent.hasNotification &&
      notificationsComponent.isApproved()
    );
  }

  public isNewConferenceDealOrFunding(
    obj: Conference | Deal | Funding,
    typeId: string
  ): boolean {
    return (
      !obj[typeId] || obj[typeId].startsWith(NewConferenceDealOrFundingPrefix)
    );
  }

  public buildCompanyUpdate(
    companyA: Company,
    companyB: Company
  ): ICompanyUpdate {
    const diff = compareCompanies(companyA, companyB);
    const thisUpdate = this.composeCompanyUpdate(diff);
    return this.pendingUpdate && thisUpdate
      ? this.mergeCompanyUpdates(this.pendingUpdate, thisUpdate)
      : thisUpdate;
  }

  public applyTagReviewModification(tagReview: TagReviewModification): void {
    const existingTagReview = this.tagReviews.find(
      (aTagReview) => aTagReview.tag === tagReview.tag
    );
    if (!existingTagReview) {
      this.tagReviews.push(tagReview);
    } else {
      existingTagReview.value = tagReview.value;
    }
  }

  public async applyUpdateRetracted(): Promise<void> {
    this.initializeCompany(_.merge({}, this.companyBeforeAnyChanges));
    // The update was just retracted, so there's no pendingUpdate for initializeUpdateData(),
    // but it does reset some things for us.
    await this.initializeUpdateData();
  }

  private applyUpdate(update: ICompanyUpdate, company: Company): void {
    if (!update) {
      return;
    }

    if (update.updateFields) {
      update.updateFields.map((updateField) => {
        if (updateField.status !== UpdateStatus.Declined) {
          if (updateField.name === 'tags') {
            company[updateField.name] =
              updateField.newValue.split(TAG_SEPARATOR);
          } else {
            if (this.isBooleanProperty(updateField.name)) {
              company[updateField.name] = getBooleanAsNumber(
                updateField.newValue
              );
            } else if (this.isCompanyContactTitle(updateField.name)) {
              if (company.companyContact) {
                company.companyContact.title = updateField.newValue;
              }
            } else {
              company[updateField.name] = updateField.newValue;
            }
          }
        }
      });
    }

    if (update.conferenceUpdates) {
      company.anticipatedConferences = company.anticipatedConferences ?? [];
      company.conferencesAttended = company.conferencesAttended ?? [];

      update.conferenceUpdates.map((conferenceUpdate) => {
        const conference = conferenceUpdate.isAttendedConference
          ? company.conferencesAttended.find(
              (c) => c.conferenceId === conferenceUpdate.modelId
            )
          : company.anticipatedConferences.find(
              (c) => c.conferenceId === conferenceUpdate.modelId
            );
        if (conference) {
          conferenceUpdate.updateFields.map((updateField) => {
            // Conferences only have string fields, unlike deals & funding
            conference[updateField.name] = updateField.newValue;
          });
        } else {
          if (!this.isConferenceDealOrFundingUpdateDeclined(conferenceUpdate)) {
            const newConference = this.buildConferenceDealOrFundingFromUpdate(
              new Conference(),
              conferenceUpdate
            ) as Conference;
            newConference.conferenceId = conferenceUpdate.modelId;
            if (conferenceUpdate.isAttendedConference) {
              this.company.conferencesAttended.push(newConference);
            } else {
              this.company.anticipatedConferences.push(newConference);
            }
          }
        }
      });
    }

    if (update.dealUpdates) {
      company.deals = company.deals ?? [];
      update.dealUpdates.map((dealUpdate) => {
        const deal = company.deals.find((d) => d.dealId === dealUpdate.modelId);
        if (deal) {
          dealUpdate.updateFields.map((updateField) => {
            if (this.isBooleanProperty(updateField.name)) {
              deal[updateField.name] = getBooleanAsNumber(updateField.newValue);
            } else {
              deal[updateField.name] = updateField.newValue;
            }
          });
        } else {
          if (!this.isConferenceDealOrFundingUpdateDeclined(dealUpdate)) {
            const newDeal = this.buildConferenceDealOrFundingFromUpdate(
              new Deal(),
              dealUpdate
            ) as Deal;
            newDeal.dealId = dealUpdate.modelId;
            this.company.deals.push(newDeal);
          }
        }
      });
    }

    if (update.fundingUpdates) {
      company.funding = company.funding ?? [];
      update.fundingUpdates.map((fundingUpdate) => {
        const funding = company.funding.find(
          (f) => f.fundingId === fundingUpdate.modelId
        );
        if (funding) {
          fundingUpdate.updateFields.map((updateField) => {
            if (this.isBooleanProperty(updateField.name)) {
              funding[updateField.name] = getBooleanAsNumber(
                updateField.newValue
              );
            } else {
              funding[updateField.name] = updateField.newValue;
            }
          });
        } else {
          if (!this.isConferenceDealOrFundingUpdateDeclined(fundingUpdate)) {
            const newFunding = this.buildConferenceDealOrFundingFromUpdate(
              new Funding(),
              fundingUpdate
            ) as Funding;
            newFunding.fundingId = fundingUpdate.modelId;
            this.company.funding.push(newFunding);
          }
        }
      });
    }
  }

  public isConferenceDealOrFundingUpdateDeclined(
    update: IConferenceUpdate | IDealUpdate | IFundingUpdate
  ): boolean {
    // Conference, deal & funding updates are approved or declined in their entirety,
    // so if one field is declined, the whole update is declined.
    return (
      update?.updateFields?.length &&
      update.updateFields[0].status === UpdateStatus.Declined
    );
  }

  public isCompanyContactTitle(propertyName: string): boolean {
    return propertyName === 'companyContactTitle';
  }

  private buildConferenceDealOrFundingFromUpdate(
    obj: Conference | Deal | Funding,
    update: IConferenceUpdate | IDealUpdate | IFundingUpdate
  ): Conference | Deal | Funding {
    update.updateFields.map((updateField) => {
      if (this.isBooleanProperty(updateField.name)) {
        obj[updateField.name] = getBooleanAsNumber(updateField.newValue);
      } else {
        obj[updateField.name] = updateField.newValue;
      }
    });
    return obj;
  }

  public getConferenceUpdates(
    diffs: Diff,
    conferenceUpdates: IConferenceUpdate[]
  ): void {
    const allConferences = [
      ...(this.company.conferencesAttended || []),
      ...(this.company.anticipatedConferences || []),
    ];
    const allConferenceUpdateDiffs = [
      ...(diffs.conferencesAttended || []),
      ...(diffs.anticipatedConferences || []),
    ];
    allConferenceUpdateDiffs.forEach((diff) => {
      const conferenceKeys = Object.keys(diff);
      // The modelId will be the same for all edited diffs in this deal or funding.
      const modelId = diff[conferenceKeys[0]].modelId;
      // If there is no modelId, as would be the case for new conferences, fall back on conferenceName.
      let updatedConference = allConferences.find((conference) => {
        if (modelId) {
          return conference.conferenceId === modelId;
        } else {
          return (
            conference.conferenceName === diff['conferenceName'].newValue &&
            !modelId
          );
        }
      });
      updatedConference = updatedConference
        ? _.merge(new Conference(), updatedConference)
        : updatedConference;
      const conferenceKindKey = updatedConference.isAttendedConference
        ? 'conferencesAttended'
        : 'anticipatedConferences';
      const originalConference = this.companyBeforeAnyChanges[
        conferenceKindKey
      ].find((conference) => {
        if (modelId) {
          return conference.conferenceId === modelId;
        } else {
          return (
            conference.conferenceName === diff['conferenceName'].newValue &&
            !modelId
          );
        }
      });

      let conferenceUpdateFields;
      if (updatedConference) {
        // The conference wasn't deleted
        conferenceUpdateFields = this.composeUpdateFields(
          conferenceKeys,
          updatedConference,
          originalConference
        );
        conferenceUpdateFields.forEach((conferenceUpdateField) => {
          conferenceUpdateField.nav_table_name = 'conference_update';
        });
      }
      const conferenceUpdate = {
        id: undefined,
        isAttendedConference: updatedConference.isAttendedConference,
        createdDate: undefined,
        updatedDate: undefined,
        companyUpdate: undefined,
        modelId: modelId,
        updateFields: conferenceUpdateFields || [],
      };
      if (updatedConference) {
        (conferenceUpdate as IConferenceUpdate).isNewConference =
          this.isNewConferenceDealOrFunding(updatedConference, 'conferenceId');
        (conferenceUpdate as IConferenceUpdate).isDeletedConference =
          updatedConference.isDeleted;
      } else {
        (conferenceUpdate as IConferenceUpdate).isDeletedConference = true;
      }
      (conferenceUpdates as IConferenceUpdate[]).push(
        conferenceUpdate as IConferenceUpdate
      );
    });
  }

  public getDealOrFundingUpdates(
    diffs: Diff,
    type: string,
    idType: string,
    partyType: string,
    dealOrFundingUpdates: IDealUpdate[] | IFundingUpdate[]
  ): void {
    diffs[type].forEach((diff) => {
      const dealOrFundingKeys = Object.keys(diff);
      // The modelId will be the same for all edited diffs in this deal or funding.
      const modelId = diff[dealOrFundingKeys[0]].modelId;
      // If there is no modelId, as would be the case for new deals or funding, fall back on dealParty.
      const updatedDealOrFunding = this.company[type].find((dealorFunding) => {
        if (modelId) {
          return dealorFunding[idType] === modelId;
        } else {
          return (
            dealorFunding[partyType] === diff[partyType].newValue && !modelId
          );
        }
      });
      const originalDealOrFunding = this.companyBeforeAnyChanges[type]?.find(
        (dealorFunding) => {
          if (modelId) {
            return dealorFunding[idType] === modelId;
          } else {
            return (
              dealorFunding[partyType] === diff[partyType].newValue && !modelId
            );
          }
        }
      );

      let dealOrFundingUpdateFields;
      if (updatedDealOrFunding) {
        // The deal or funding wasn't deleted
        dealOrFundingUpdateFields = this.composeUpdateFields(
          dealOrFundingKeys,
          updatedDealOrFunding,
          originalDealOrFunding
        );
        dealOrFundingUpdateFields.forEach((dealOrFundingUpdateField) => {
          dealOrFundingUpdateField.nav_table_name =
            idType === 'dealId' ? 'deal_update' : 'funding_update';
        });
      }
      const dealOrFundingUpdate = {
        id: undefined,
        createdDate: undefined,
        updatedDate: undefined,
        companyUpdate: undefined,
        modelId: modelId,
        updateFields: dealOrFundingUpdateFields || [],
      };
      if (type === 'deals') {
        if (updatedDealOrFunding) {
          (dealOrFundingUpdate as IDealUpdate).isNewDeal =
            this.isNewConferenceDealOrFunding(updatedDealOrFunding, 'dealId');
          (dealOrFundingUpdate as IDealUpdate).isDeletedDeal =
            updatedDealOrFunding.isDeleted;
        } else {
          (dealOrFundingUpdate as IDealUpdate).isDeletedDeal = true;
        }
        (dealOrFundingUpdates as IDealUpdate[]).push(
          dealOrFundingUpdate as IDealUpdate
        );
      } else if (type === 'funding') {
        if (updatedDealOrFunding) {
          (dealOrFundingUpdate as IFundingUpdate).isNewFunding =
            this.isNewConferenceDealOrFunding(
              updatedDealOrFunding,
              'fundingId'
            );
          (dealOrFundingUpdate as IFundingUpdate).isDeletedFunding =
            updatedDealOrFunding.isDeleted;
        } else {
          (dealOrFundingUpdate as IFundingUpdate).isDeletedFunding = true;
        }
        (dealOrFundingUpdates as IFundingUpdate[]).push(
          dealOrFundingUpdate as IFundingUpdate
        );
      }
    });
  }

  private displayNameForProperty(propertyName: string): string {
    if (this.isFirstTimeEntrepreneurProperty(propertyName)) {
      return this._deploymentContext.Loc(
        LocalizedTextIds.CommunityAndDiversityTitle
      );
    } else {
      return this._deploymentContext.LocWithPrefix(propertyName, 'UpdateField');
    }
  }

  public isUpdateFieldSet(propertyName: string): boolean {
    return this.updateForReview.updateFields[propertyName]?.isSet;
  }

  public isProgressUpdateSet(): boolean {
    return this.isUpdateFieldSet(this.progressUpdateString);
  }

  public cloneCompany(company: Company): Company {
    return _.cloneDeep(company);
  }

  public revertItemEdit(propertyName: string): void {
    if (this.isCompanyContactTitle(propertyName)) {
      if (this.companyWithPending.companyContact) {
        if (this.company.companyContact) {
          this.company.companyContact.title = _.cloneDeep(
            this.companyWithPending.companyContact.title
          );
        }
        if (this.companyWithMostRecentEdit.companyContact) {
          this.companyWithMostRecentEdit.companyContact.title = _.cloneDeep(
            this.companyWithPending.companyContact.title
          );
        }
      }
    } else {
      this.company[propertyName] = _.cloneDeep(
        this.companyWithPending[propertyName]
      );
      this.companyWithMostRecentEdit[propertyName] = _.cloneDeep(
        this.companyWithPending[propertyName]
      );
      if (propertyName === this.progressUpdateString) {
        this.progressUpdate = this.companyWithPending.progressUpdate;
      }
    }
    this.isEditingProperty[propertyName] = false;
    this._reviewEditsService.currentEditItemProperty = null;
    this.updateForReview.updateFields[propertyName] = undefined;
  }

  public revertMultipleEdits(propertyNames: string[], parent: string): void {
    propertyNames.forEach((propertyName) => {
      this.company[propertyName] = this.companyWithPending[propertyName];
      this.companyWithMostRecentEdit[propertyName] =
        this.companyWithPending[propertyName];
    });
    this.isEditingProperty[parent] = false;
    this._reviewEditsService.currentEditItemProperty = null;
  }

  public async revertChanges(): Promise<void> {
    // Reset the company
    this.company = _.merge({}, this.companyClone);
    // Restore pending update.
    await this.initializeUpdateData(false);
  }

  private getValueByKey(obj: any, keyStr: string): any {
    const dotIndex = keyStr.indexOf('.');
    if (dotIndex > 0) {
      const key = keyStr.slice(0, dotIndex);
      return this.getValueByKey(obj[key], keyStr.slice(dotIndex + 1));
    } else {
      if (this.isBooleanProperty(keyStr)) {
        return obj[keyStr];
      }
      return obj[keyStr] || '';
    }
  }

  public isBooleanProperty(propertyName: string): boolean {
    return (
      this.isFirstTimeEntrepreneurProperty(propertyName) ||
      propertyName === 'isAttendedConference' ||
      propertyName === 'isConfidential' ||
      propertyName === 'isDeleted' ||
      propertyName === 'isJnjDeal'
    );
  }

  public isFirstTimeEntrepreneurProperty(propertyName: string): boolean {
    return (
      propertyName === 'firstTimeEntrepreneur' ||
      propertyName === 'minorityLed' ||
      propertyName === 'womenLed'
    );
  }

  public isDeiProperty(propertyName: string): boolean {
    return (
      propertyName === 'leadershipDiversity' ||
      propertyName === 'boardAdvisorDiversity'
    );
  }

  public wouldBeEmptyCompanyUpdate(companyUpdate: ICompanyUpdate): boolean {
    // Would the CompanyUpdate have no more updates of the specified type if we delete that type of update?
    const wouldHaveNoUpdatesOfType =
      this.wouldHaveNoUpdatesFor(companyUpdate.conferenceUpdates) &&
      this.wouldHaveNoUpdatesFor(companyUpdate.dealUpdates) &&
      this.wouldHaveNoUpdatesFor(companyUpdate.fundingUpdates);

    return (
      (!companyUpdate.updateFields ||
        companyUpdate.updateFields.length === 0) &&
      wouldHaveNoUpdatesOfType
    );
  }

  private mergeCompanyUpdates(
    target: ICompanyUpdate,
    source: ICompanyUpdate
  ): ICompanyUpdate {
    // FUTURE: Is there a more streamlined way to merge these updates?
    if (target) {
      target = this.sortUpdates(target);
    }

    if (source) {
      source = this.sortUpdates(source);
    } else {
      return target;
    }

    if (target && source) {
      // Merge each item in each array
      this.mergeUpdateFields(target.updateFields, source.updateFields);
    }

    if (source.conferenceUpdates) {
      source.conferenceUpdates.forEach((scu: IConferenceUpdate) => {
        if (!target.conferenceUpdates) {
          this.logService.logOnServer(
            `During merge, target.conferenceUpdates was null or undefined`,
            'warn'
          );
          target.conferenceUpdates = [];
        }
        const targetConferenceUpdate = target.conferenceUpdates.find(
          (tcu) => tcu.modelId === scu.modelId
        );
        if (targetConferenceUpdate) {
          this.mergeUpdateFields(
            targetConferenceUpdate.updateFields,
            scu.updateFields
          );
          if (scu.isNewConference !== undefined) {
            targetConferenceUpdate.isNewConference = scu.isNewConference;
          }
          if (scu.isDeletedConference !== undefined) {
            targetConferenceUpdate.isDeletedConference =
              scu.isDeletedConference;
          }
        } else {
          target.conferenceUpdates.push(scu);
        }
      });
    }

    if (source.dealUpdates) {
      source.dealUpdates.forEach((sdu: IDealUpdate) => {
        if (!target.dealUpdates) {
          this.logService.logOnServer(
            `During merge, target.dealUpdates was null or undefined`,
            'warn'
          );
          target.dealUpdates = [];
        }
        const targetDealUpdate = target.dealUpdates.find(
          (tdu) => tdu.modelId === sdu.modelId
        );
        if (targetDealUpdate) {
          this.mergeUpdateFields(
            targetDealUpdate.updateFields,
            sdu.updateFields
          );
          if (sdu.isNewDeal !== undefined) {
            targetDealUpdate.isNewDeal = sdu.isNewDeal;
          }
          if (sdu.isDeletedDeal !== undefined) {
            targetDealUpdate.isDeletedDeal = sdu.isDeletedDeal;
          }
        } else {
          target.dealUpdates.push(sdu);
        }
      });
    }

    if (source.fundingUpdates) {
      source.fundingUpdates.forEach((sfu: IFundingUpdate) => {
        if (!target.fundingUpdates) {
          this.logService.logOnServer(
            `During merge, target.fundingUpdates was null or undefined`,
            'warn'
          );
          target.fundingUpdates = [];
        }
        const targetFundingUpdate = target.fundingUpdates.find(
          (tfu) => tfu.modelId === sfu.modelId
        );
        if (targetFundingUpdate) {
          this.mergeUpdateFields(
            targetFundingUpdate.updateFields,
            sfu.updateFields
          );
          if (sfu.isNewFunding !== undefined) {
            targetFundingUpdate.isNewFunding = sfu.isNewFunding;
          }
          if (sfu.isDeletedFunding !== undefined) {
            targetFundingUpdate.isDeletedFunding = sfu.isDeletedFunding;
          }
        } else {
          target.fundingUpdates.push(sfu);
        }
      });
    }

    return target;
  }

  private mergeUpdateFields(
    target: IUpdateField[],
    source: IUpdateField[]
  ): void {
    source.forEach((suf: IUpdateField) => {
      let targetUpdateField = target.find((tuf) => tuf.name === suf.name);
      if (targetUpdateField) {
        // ADJQ-1190: This feels like a hacky way to avoid the approver ID stepping over
        //  the emailAddress, other than when the approver actually changed a field value.
        let originalEmailAddress = null;
        if (suf.newValue === targetUpdateField.newValue) {
          originalEmailAddress = targetUpdateField.emailAddress;
        }
        targetUpdateField = _.merge(targetUpdateField, suf);

        if (originalEmailAddress) {
          targetUpdateField.emailAddress = originalEmailAddress;
        }
      } else {
        target.push(suf);
      }
    });
  }

  private sortUpdates(update: ICompanyUpdate): ICompanyUpdate {
    if (update.updateFields) {
      update.updateFields = update.updateFields.sort(
        (uf1: any, uf2: any) => uf1.name - uf2.name
      );
    }

    if (update.conferenceUpdates) {
      update.conferenceUpdates = update.conferenceUpdates.sort(
        (cu1: any, cu2: any) => cu1.id - cu2.id
      );
      update.conferenceUpdates.forEach((cu) => {
        cu.updateFields = cu.updateFields.sort(
          (uf1: any, uf2: any) => uf1.name - uf2.name
        );
      });
    }

    if (update.dealUpdates) {
      update.dealUpdates = update.dealUpdates.sort(
        (du1: any, du2: any) => du1.id - du2.id
      );
      update.dealUpdates.forEach((du) => {
        du.updateFields = du.updateFields.sort(
          (uf1: any, uf2: any) => uf1.name - uf2.name
        );
      });
    }

    if (update.fundingUpdates) {
      update.fundingUpdates = update.fundingUpdates.sort(
        (fu1: any, fu2: any) => fu1.id - fu2.id
      );
      update.fundingUpdates.forEach((fu) => {
        fu.updateFields = fu.updateFields.sort(
          (uf1: any, uf2: any) => uf1.name - uf2.name
        );
      });
    }

    return update;
  }

  private wouldHaveNoUpdatesFor(
    updates: IConferenceUpdate[] | IDealUpdate[] | IFundingUpdate[] | undefined
  ): boolean {
    // Will the CompanyUpdate have no more updates of the specified type if we delete that type of update?
    return !updates || updates.length === 1; // deleting one will leave zero
  }
}
