import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Company, ICompanyUpdate } from 'company-finder-common';
import {
  displayMilestone,
  MilestoneValue,
} from '../blue-knight/blue-knight.util';

export function requireIfBlueKnightCompany(company: Company): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (company.isBlueKnight) {
      return isEmptyInputValue(control.value) ? { required: true } : null;
    } else {
      return null;
    }
  };
}

export function requireIfRendered(
  company: Company,
  milestoneValue: MilestoneValue,
  propName: string,
  pendingUpdate: ICompanyUpdate
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const rendered =
      company.isBlueKnight &&
      displayMilestone(company, milestoneValue, propName, pendingUpdate);
    if (rendered) {
      return isEmptyInputValue(control.value) ? { required: true } : null;
    } else {
      return null;
    }
  };
}

export class RequireMoreThanJustSpaces {
  static required(control: AbstractControl): ValidationErrors | null {
    return isEmptyInputValue(control.value) ? { required: true } : null;
  }
}

export class MoneyRequiresCurrency {
  static isNullOrEmpty(str: string): boolean {
    return !str || str.length === 0;
  }

  // If the valueField has a value, a currency is required.
  static required(
    fields: {
      valueFields: string[];
      currencyField: string;
    },
    disabled: boolean = false
  ): ValidatorFn {
    return (fg: FormGroup) => {
      if (disabled) {
        return null;
      }

      const currency = fg.get(fields.currencyField).value;

      let noneHaveValue = true;

      fields.valueFields.forEach((valueField) => {
        const raised = fg.get(valueField).value;
        noneHaveValue = noneHaveValue && this.isNullOrEmpty(raised);
      });

      return noneHaveValue || !this.isNullOrEmpty(currency)
        ? null
        : { currencyRequired: true };
    };
  }
}

export function isEmptyInputValue(value: [] | string): boolean {
  // This is borrowed from Angular, but tweaked for our specific string-based use case, trimming the value.
  // https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
  // NOTE: The `value == null` below instead of === appears to have been intentional, to match null and undefined.
  return (
    value == null ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}
